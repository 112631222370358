// @flow

import React from 'react';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useLingui } from '../app/lingui';
import { smoothScrollTopToElement } from '../jsutils/browser';

export function PasswordTextField(props: *) {
  const [showPassword, setShowPassword] = React.useState(false);
  const i18n = useLingui();
  const toggleLabel = i18n.t`Toggle password visibility`;
  return (
    <TextField
      margin="normal"
      onFocus={({ target }) => {
        const top = target.getBoundingClientRect().top;
        if (top < 150) {
          smoothScrollTopToElement(target);
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={toggleLabel}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              <Tooltip enterDelay={500} title={toggleLabel}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </Tooltip>
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? 'text' : 'password'}
      InputLabelProps={{ shrink: true }}
      {...props}
    />
  );
}
