// @flow

import { Container } from '@whys/app/lib/state';
import { fetchJSON, getJSON } from '@whys/fetch/lib/json';
import { create as createResourceCache } from '@whys/fetch/lib/resources/ResourceCache';
import type { ResourceCache } from '@whys/fetch';

import type { FetchEnvType, AppCacheType } from '../types/app';
import type { LayoutVariantEnum, SessionType, InitialState } from '../appState/types';

import { defaultMenuStatus, defaultListLayout, defaultSessionState } from '../app/config';

const resources = {
  updateSession: {
    url: '/api/utils/session/',
    method: 'PATCH',
  },
  readSession: {
    url: '/api/utils/session/',
  },
};

type LocalProps = {|
  fetchEnv: FetchEnvType,
  appCache: AppCacheType,
  initialState: InitialState,
|};

// NoteReview(simon): do we need the state?
type LocalState = {|
  ...SessionType,
  didLoad: boolean,
|};

export class PreferenceContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  sessionCache: ResourceCache<SessionType>;

  constructor(props: LocalProps) {
    super();

    const { fetchEnv, appCache, initialState } = props;

    this.sessionCache = createResourceCache({
      fetchEnv,
      initialValue: initialState.session,
      cache: appCache.getOrCreateCache('PreferenceContainer.sessionCache'),
      url: resources.readSession.url,
      mapData: (data) => {
        return {
          listLayout: data.listLayout || defaultListLayout,
          menuStatus: data.menuStatus || defaultMenuStatus,
        };
      },
      getEmptyResource: () => defaultSessionState,
    });

    this.state = { ...defaultSessionState, didLoad: false };
    this.props = props;
  }

  async fetchListLayoutVariant(): Promise<LayoutVariantEnum> {
    const { fetchEnv } = this.props;
    const result = await getJSON(resources.readSession.url, fetchEnv);
    if (result.status === 'ok') {
      return result.data.listLayout || defaultListLayout;
    }
    return defaultListLayout;
  }

  async loadListLayoutVariant(): Promise<void> {
    const listLayout = await this.fetchListLayoutVariant();
    await this.setState({ listLayout });
  }

  selectListLayoutVariant(): LayoutVariantEnum {
    return this.state.listLayout;
  }

  async getListLayoutVariant(): Promise<LayoutVariantEnum> {
    const { listLayout, didLoad } = this.state;
    if (didLoad) {
      return listLayout;
    }
    const listLayoutFetched = await this.fetchListLayoutVariant();
    this.setState({ listLayout: listLayoutFetched, didLoad: true });
    return listLayoutFetched;
  }

  // lazySelectListLayout(): LazyResource<LayoutVariantEnum> {
  //   return {
  //     resource: this.state.listLayout,
  //     loaded: true,
  //     load: () => {},
  //   };
  // }

  async alterListLayoutVariant(listLayout: LayoutVariantEnum): Promise<boolean> {
    const { fetchEnv } = this.props;

    await fetchJSON({ ...resources.updateSession, env: fetchEnv, data: { listLayout } });

    await this.setState({ listLayout });

    // NotePrototype...
    return true;
  }

  async updateSession(data: $Shape<SessionType> | SessionType = {}) {
    const { fetchEnv } = this.props;
    await fetchJSON({
      url: '/api/utils/session/',
      method: 'PATCH',
      data,
      env: fetchEnv,
    });
  }

  async fetchSession(): Promise<SessionType> {
    return this.sessionCache.getResource();
  }
}
