// @flow

import * as React from 'react';
import { useState } from 'react';

import FavoriteOutline from '@material-ui/icons/FavoriteBorder';
import FavoritedIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
// UI
import Tooltip from '../appUI/Tooltip';

import './FavoriteButton.css';
import { useLingui } from '../app/lingui';

export const LocalButton = withStyles({
  root: {
    backgroundColor: 'transparent',
    zIndex: 10,

    '&&:hover': {
      backgroundColor: 'rgba(0, 170, 188, 0.1)',
      zIndex: 10,
    },
  },
})((props) => {
  return <IconButton color="primary" {...props} />;
});

type LocalProps = {|
  isFavorite: boolean,
  onFavoriteChange: (isFavorite: boolean) => Promise<boolean>,
  large?: boolean,
|};

function FavoriteButton(props: LocalProps) {
  const [fav, setFav] = useState(props.isFavorite);
  const i18n = useLingui();

  const onFavToggle = async () => {
    setFav(!fav);
    props.onFavoriteChange(!fav);
  };

  const { large = false } = props;
  return (
    <LocalButton
      className={`${fav ? 'FavoriteButton--filled' : 'FavoriteButton--unfav'}`}
      onClick={onFavToggle}
    >
      <Tooltip
        title={fav ? i18n.t`Remove from favorites` : i18n.t`Favorite`}
        placement="bottom-start"
      >
        {fav ? (
          <FavoritedIcon fontSize={large ? 'large' : 'default'} color="primary" />
        ) : (
          <FavoriteOutline fontSize={large ? 'large' : 'default'} color="primary" />
        )}
      </Tooltip>
    </LocalButton>
  );
}

export default FavoriteButton;
