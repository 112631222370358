// @flow

import * as React from 'react';

type loadStateType = 'initial' | 'short' | 'long';
type loaderType = (loadStateType) => boolean;

type LocalProps = {|
  renderLoader: (loaderType) => React.Node,

  // with defaults
  shortDelay: number,
  longDelay: number,
  initialLoadState: loadStateType,
|};

type LocalState = {|
  loadState: loadStateType,
|};

class LoadingInline extends React.Component<LocalProps, LocalState> {
  static defaultProps = {
    initialLoadState: 'initial',
    shortDelay: 350,
    longDelay: 800,
  };

  delayTimeout: *;
  isPast: *;

  constructor(props: LocalProps) {
    super(props);

    this.isPast = this.isPast.bind(this);

    this.state = { loadState: props.initialLoadState };
  }

  componentDidMount() {
    const { shortDelay, initialLoadState } = this.props;

    const setLongDelay = () => {
      const delayMs = this.props.longDelay - this.props.shortDelay;
      this.delayTimeout = setTimeout(() => {
        this.setState({ loadState: 'long' });
      }, delayMs);
    };

    const setShortDelay = () => {
      this.setState({ loadState: 'short' }, setLongDelay);
    };

    if (initialLoadState === 'initial') {
      this.delayTimeout = setTimeout(setShortDelay, shortDelay);
    } else if (initialLoadState === 'short') {
      const { longDelay } = this.props;
      this.delayTimeout = setTimeout(setLongDelay, longDelay);
    }
  }

  componentWillUnmount() {
    if (this.delayTimeout) {
      clearTimeout(this.delayTimeout);
    }
  }

  isPast(delayType: loadStateType): boolean {
    const { loadState } = this.state;
    if (loadState === 'initial') {
      return false;
    }
    return loadState === delayType;
  }

  render() {
    return this.props.renderLoader(this.isPast);
  }
}

export default LoadingInline;
