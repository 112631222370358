// @flow

import * as React from 'react';
// import styled from '@emotion/styled';
// import { styledTheme } from '../app/theme';
import Grid from '@material-ui/core/Grid/Grid';
import { ProductCardParams } from './ProductCardParams';
import type { ProductItem } from '../appState/types';
import { ThemedText, VerticalSpace, HorizontalSpace } from '../appUI';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useMediaQuery } from '../hooks/responsive';
import { themeMUI, useGlobalContainer } from '../app/state';
import { ProductLabels, DiscountCircle } from './ProductLabels';
import { Block, FlexRow, FlexColumn } from '../baseUI';
import FavoriteButton from './FavoriteButton';
import WatchButton from './WatchButton';
import { ProductPrice, ProductSaleInfo, ImageLink, ProductTitleLink } from './product';
import { AddToCartLine } from '../cart/addToCart';
import ProductAvailability from './ProductAvailability';
import { TextLink } from '../app/link';
import { Trans } from '@lingui/react';
import { useHistory } from '../app/router';
import RudorferGallery from './RudorferGallery';
import { getImagePlaceholder } from './getImagePlaceholder';
import styled from '@emotion/styled';

type LocalProps = {|
  product: ProductItem,
  isLogged: boolean,

  isFavorite: boolean,
  onFavoriteChange: (isFavorite: boolean) => Promise<boolean>,
|};

const PopupWrapper = styled.div`
  & .image-gallery-slides {
    height: auto !important;
    width: auto !important;
  }
`;

function ProductPopover(props: LocalProps) {
  const { product, isLogged } = props;
  const { images, code, params, price, productUrl } = product;
  const isSmall = useMediaQuery(themeMUI.breakpoints.down('sm'));
  const history = useHistory();
  const imgPlaceholder = getImagePlaceholder();
  const globalContainer = useGlobalContainer();

  const renderImage = () => {
    if (images.length > 0) {
      return (
        <PopupWrapper>
          <RudorferGallery
            items={images}
            enableFullscreen={false}
            onClick={() => history.push(productUrl)}
          />
        </PopupWrapper>
      );
    } else {
      return <ImageLink title={code} to={productUrl} src={imgPlaceholder} />;
    }
  };

  return (
    <Block padding={20} maxWidth={isSmall ? 400 : 700}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Block position="relative">
            {globalContainer.isSpecificSite('rudorfer.dealer') || (
              <ProductLabels isSmall={isSmall} labels_test={product.labels_test} />
            )}
            {product.discount && isLogged ? <DiscountCircle discount={product.discount} /> : null}
            {renderImage()}
          </Block>
        </Grid>
        <Grid item xs={12} md={6}>
          <FlexRow alignItems="center" justifyContent="space-between">
            <FlexRow alignItems="center" flexWrap="wrap">
              <ProductTitleLink title={code} productUrl={productUrl} />
              <HorizontalSpace />
              {isLogged && (
                <FavoriteButton
                  isFavorite={props.isFavorite}
                  onFavoriteChange={props.onFavoriteChange}
                />
              )}
            </FlexRow>
            <TextLink golden to={productUrl}>
              <Trans>Go to detail</Trans>
            </TextLink>
          </FlexRow>
          <VerticalSpace />
          <ThemedText size="bigger">{product.name}</ThemedText>
          <VerticalSpace />
          <ProductCardParams showDimensions={!product.shortDescriptionHtml} params={params} />
          <VerticalSpace size="large" />
          {isLogged && (
            <React.Fragment>
              <ProductSaleInfo availableNum={product.availableNum} type={product.params.saleType} />
              <VerticalSpace size="large" />
              <FlexColumn alignItems="flex-start">
                <ProductPrice
                  params={product.params}
                  price={price}
                  originalPrice={product.originalPrice}
                />
              </FlexColumn>
              <VerticalSpace size="large" />
              <AddToCartLine product={product} />
              <VerticalSpace />
              <ProductAvailability
                amount={product.availableNum}
                splitBy={product.params.thresholdDisplayStock}
              />
              <WatchButton productId={product.id} allowed={product.allowWatchdog} />
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </Block>
  );
}

export default ProductPopover;
