// @flow

import React, { useState, useEffect } from 'react';

import styled from '@emotion/styled';
import { Waypoint } from 'react-waypoint';

const StyledImg = styled.img`
  object-fit: contain;
  max-width: 100%;
  height: ${(props) => props.height || 'auto'};
  max-height: 100%;
  min-height: ${(props) => props.minHeight};
  margin: auto;

  @keyframes fadeOut {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  animation: ${(props) => (props.isLoaded ? '300ms fadeOut' : '')};
`;

type LocalProps = {|
  // normal img attributes
  src: string,

  height?: number | string,
  alt?: string,
  loading?: 'lazy' | 'auto' | 'eager',

  // custom
  minHeight?: number | string,
  initiallyLoaded?: boolean,
  fallbackSrc?: string,
|};

function tryLoadImage(src: string, opts: { onImgLoad: *, onImgError: * }) {
  const { onImgLoad, onImgError } = opts;
  const imgEl = document.createElement('img');
  imgEl.addEventListener('load', onImgLoad);
  imgEl.addEventListener('error', onImgError);
  imgEl.src = src;

  return () => {
    imgEl.removeEventListener('load', onImgLoad);
    imgEl.removeEventListener('error', onImgError);
  };
}

export function LazyImage(props: LocalProps) {
  // props
  const { initiallyLoaded, height, minHeight, fallbackSrc, ...imgProps } = props;
  // state
  const [isLoaded, setLoaded] = useState(!!initiallyLoaded);
  const [hasError, setHasError] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const actualSrc = imgProps.src;

  let __isMounted = false;

  const onVisible = () => {
    if (isVisible) {
      return;
    }
    setVisible(true);
  };

  useEffect(() => {
    if (!imgProps.src || !isVisible || isLoaded) {
      return;
    }

    const onImgLoad = () => {
      setLoaded(true);
    };

    const onImgError = () => {
      setLoaded(true);
      setHasError(true);
    };

    tryLoadImage(imgProps.src, { onImgLoad, onImgError });
  }, [isVisible, isLoaded, imgProps.src]);

  const renderWithWaypoint = (element) => {
    return (
      <React.Fragment>
        <Waypoint onEnter={onVisible} bottomOffset="-600px" />
        {element}
        <noscript>
          <StyledImg height={height} minHeight={minHeight} {...actualImgProps} src={actualSrc} />
        </noscript>
      </React.Fragment>
    );
  };

  useEffect(() => {
    __isMounted = true;
    return () => {
      __isMounted = false;
    };
  }, []);

  let actualImgProps = {
    ...imgProps,
    isLoaded,
    minHeight,
    height,
  };

  if (!isLoaded) {
    actualImgProps.src = fallbackSrc;
  }

  const imgRendered = <StyledImg height={height} minHeight={minHeight} {...actualImgProps} />;
  if (isLoaded) {
    return imgRendered;
  }

  return renderWithWaypoint(imgRendered);
}
