// @flow

import * as React from 'react';
import { I18nProvider } from '@lingui/react';
import { ThemeProvider } from 'emotion-theming';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import mediaQuery from 'css-mediaquery';
import { Provider as UnstatedProvider } from '@whys/app/lib/state';
import NoSsr from '@material-ui/core/NoSsr';
import { createAppRoot } from '@whys/app/lib/pages';

import { LinguiProvider as OurLinguiProvider } from '../app/lingui';
import { OurReactRouterProvider } from '../app/router';
import type { SentryLogger, DjReactStateType, ThemeType } from '../types/app';
import {
  createLoginContainer,
  createI18nObject,
  createMenuContainer,
  createPreferenceContainer,
  createProductContainer,
  createCategoryProductsContainer,
  createCategoryContainer,
  createCartContainer,
  createLanguageContainer,
  createPageContainer,
  createSentryLogger,
  createWatchdogContainer,
  createHomepageContainer,
  createContactContainer,
  createFavoritesContainer,
  createInitialContainer,
  createGlobalContainer,
  createSearchContainer,
  createProfileContainer,
  themeMUI,
  createWebAnalyticsContainer,
} from '../app/state';
import {
  useTheme,
  AppThemeProvider,
  rudorferTheme,
  __internalThemeDoNotUse,
  jadtoolsTheme,
  alfaboxTheme,
} from '../app/theme';
import createRegisterContainer from '../state/createRegisterContainer';

import AppRootLayout from '../AppRoot/AppRootLayout';
import { GlobalApp } from '../AppRoot/GlobalApp';
import { OurMuiThemeProvider } from '../AppRoot/OurMuiThemeContext';
import AppErrorBoundary from '../AppRoot/AppErrorBoundary';
import { SystemNotifications } from '../notifications/SystemNotifications';
import type { I18nType } from '../types/npm';

// import styled from '@emotion/styled';

type LocalProps = {|
  children: React.Node,
  i18n: I18nType,
  djreactState: DjReactStateType,
  logger: SentryLogger,
  // any: we don't care about types here
  theme: ThemeType,
  inject: Array<any>,
|};

function SSRMode(props) {
  const enableSSR = Boolean(process.env.ENABLE_SSR);
  if (enableSSR) {
    return props.children;
  }
  return <NoSsr>{props.children}</NoSsr>;
}

const ssrMatchMedia = (device: 'desktop' | 'bot' | 'mobile' | 'tablet') => (query: string) => {
  const width = {
    desktop: 1280,
    bot: 1280,
    mobile: 400,
    tablet: 768,
  }[device];
  return {
    matches: mediaQuery.match(query, {
      width: `${width}px`,
      type: 'screen',
    }),
  };
};

function AppRoot(props: LocalProps) {
  const { children, i18n, logger, inject, djreactState } = props;
  const theme = props.theme;

  const { device } = djreactState;
  const themeMUIDynamic = {
    ...createMuiTheme({
      palette: {
        primary: { main: theme.color('primary') },
        secondary: { main: theme.color('secondary') },
      },
    }),
    props: { MuiUseMediaQuery: { ssrMatchMedia: ssrMatchMedia(device) } },
  };

  return (
    <AppThemeProvider value={theme}>
      <ThemeProvider theme={theme}>
        <OurMuiThemeProvider value={themeMUIDynamic}>
          <MuiThemeProvider theme={themeMUIDynamic}>
            <OurLinguiProvider value={i18n}>
              <OurReactRouterProvider>
                <I18nProvider i18n={i18n}>
                  <UnstatedProvider inject={inject}>
                    <SSRMode>
                      <AppRootLayout>
                        <GlobalApp>
                          <AppErrorBoundary logger={logger}>{children}</AppErrorBoundary>
                          <SystemNotifications />
                        </GlobalApp>
                      </AppRootLayout>
                    </SSRMode>
                  </UnstatedProvider>
                </I18nProvider>
              </OurReactRouterProvider>
            </OurLinguiProvider>
          </MuiThemeProvider>
        </OurMuiThemeProvider>
      </ThemeProvider>
    </AppThemeProvider>
  );
}

export default createAppRoot<LocalProps>({
  getInitialProps: async (ctx) => {
    const { appContainer, djreactState } = ctx;

    // Note: we use this containers/states here => must resolve into variables
    const menuContainer = await appContainer.resolve(createMenuContainer);
    const initialContainer = await appContainer.resolve(createInitialContainer);
    const globalContainer = await appContainer.resolve(createGlobalContainer);
    const cartContainer = await appContainer.resolve(createCartContainer);

    const inject = [
      ...(await Promise.all([
        // global anonymous
        appContainer.resolve(createLoginContainer),
        appContainer.resolve(createPreferenceContainer),
        appContainer.resolve(createProductContainer),
        appContainer.resolve(createLanguageContainer),
        appContainer.resolve(createWatchdogContainer),
        appContainer.resolve(createFavoritesContainer),
        appContainer.resolve(createSearchContainer),
        appContainer.resolve(createPageContainer),

        // can be local
        appContainer.resolve(createHomepageContainer),
        appContainer.resolve(createContactContainer),
        appContainer.resolve(createCategoryContainer),
        appContainer.resolve(createCategoryProductsContainer),
        appContainer.resolve(createProfileContainer),
        appContainer.resolve(createRegisterContainer),

        appContainer.resolve(createWebAnalyticsContainer),
      ])),

      // global + named
      menuContainer,
      initialContainer,
      globalContainer,
      cartContainer,
    ];

    // see RuleMenuStatusInSession: we have to prefer stored menu state if available
    // We also need to await loading this state to avoid "layout jumps" (this will override the
    // default state which is not reflected)
    await menuContainer.loadMenuStatus();
    if (djreactState.env === 'production') {
      await menuContainer.loadMenuItems();
    } else {
      // load but do not wait because its slow on non-production server
      menuContainer.loadMenuItems();
    }

    // load initial state (should be already available through @whys/app)
    await initialContainer.getInitialState();

    // necessary to render information on every page
    await globalContainer.loadStaticPageUrls();
    await globalContainer.loadSystemPageUrls();
    await globalContainer.loadNotifications();

    //
    // On client only
    //

    // NotePrototype(simon): should be resolved within "initial data spec" passed to @whys/app
    // for data that are loaded on client only (never while SSR and/or cache) but should be ready
    // during app init time
    if (typeof window !== 'undefined') {
      // load but do not wait (not critical data)
      cartContainer.loadCartStats();
    }

    const i18n = await appContainer.resolve(createI18nObject);
    const logger = await appContainer.resolve(createSentryLogger);

    let theme = rudorferTheme;
    if (
      globalContainer.isSpecificProject('jadtools') ||
      globalContainer.isSpecificProject('axima')
    ) {
      theme = jadtoolsTheme;
    }
    if (globalContainer.isSpecificProject('alfabox')) {
      theme = alfaboxTheme;
    }

    return {
      theme,
      inject,
      i18n,
      logger,
      djreactState,
    };
  },
  Component: AppRoot,
});
