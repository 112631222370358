// @flow

import { createContext, useContext } from 'react';

const ThemeContext = createContext<Object>({});

export function useMuiTheme() {
  return useContext(ThemeContext);
}

export const OurMuiThemeProvider = ThemeContext.Provider;
