// @flow

import React from 'react';
import { css as createClassNameFromProps, cx } from 'emotion';

export function Box({
  component = 'div',
  className,
  css,
  props,
  style,
  children,
  ...styleProps
}: *) {
  return React.createElement(
    component,
    {
      className: cx([createClassNameFromProps([css, styleProps]), className]),
      style,
      ...props,
    },
    children
  );
}

export function Inline(props: *) {
  return <Box display="inline" {...props} />;
}
Inline.displayName = 'baseUI.Inline';

export function InlineBlock(props: *) {
  return <Box display="inline-block" {...props} />;
}
InlineBlock.displayName = 'baseUI.InlineBlock';

export function Block(props: *) {
  return <Box display="block" {...props} />;
}
Block.displayName = 'baseUI.Block';

export function Flex(props: *) {
  return <Box display="flex" {...props} />;
}
Flex.displayName = 'baseUI.Flex';

export function FlexRow(props: *) {
  return <Box display="flex" {...props} />;
}
FlexRow.displayName = 'baseUI.FlexRow';

export function FlexColumn(props: *) {
  return <Box display="flex" flexDirection="column" {...props} />;
}
FlexColumn.displayName = 'baseUI.FlexColumn';

export function Grid(props: *) {
  // NotePrototype(simon): prefixes?
  return <Box display="grid" {...props} />;
}
Grid.displayName = 'baseUI.Grid';
