// @flow

import * as React from 'react';
import slugify from 'slugify';

import { coerceNum } from '../jsutils/valutils';
import type { FilterInfoPack } from '../appState/types';

export function mapFiltersFromQueryParams(
  filterInfo: FilterInfoPack
): {|
  intervalValues: { [string]: string },
  filterValues: { [string]: $FlowFixMe },
  activeFilterIds: Set<string>,
|} {
  const { activeFilters, filters } = filterInfo;
  const { attributeIds, namedFilters } = activeFilters;

  let allActiveFilterIds = new Set(attributeIds);

  const intervalValues = (() => {
    const intervalFilters = filters.filter((f) => f.filterType === 'interval');
    let res = {};
    for (const f of intervalFilters) {
      const filterName = f.paramName;
      const filterNameMin = `${filterName}_min`;
      const filterNameMax = `${filterName}_max`;

      const initialMin = namedFilters[filterNameMin];
      const initialMax = namedFilters[filterNameMax];

      if (initialMin !== undefined && initialMax !== undefined) {
        res[filterNameMin] = coerceNum(initialMin, 0);
        res[filterNameMax] = coerceNum(initialMax, 0);
        allActiveFilterIds.add(f.id);
      }
    }
    return res;
  })();

  return {
    intervalValues,
    filterValues: (() => {
      let res = {};
      let resCopy = {};
      for (const attrId of attributeIds) {
        const filterId = (() => {
          const foundFilter = filters.find((f) => {
            if (f.filterType === 'bool') {
              return f.boolId === attrId;
            }
            if (f.filterType === 'select' || f.filterType === 'multiselect') {
              return f.options.find((opt) => opt.value === attrId);
            }
            if (f.filterType === 'img-gallery') {
              return f.imgGalleryOptions.find((opt) => opt.value === attrId);
            }
            return false;
          });
          if (foundFilter) {
            return foundFilter.id;
          }
          return '';
        })();
        if (!filterId) {
          continue;
        }
        res[filterId] = [...(res[filterId] || []), attrId];
        let label = '';
        filters.forEach((f) => {
          if (f.options)
            f.options.forEach((opt) => {
              if (opt.value === attrId) {
                label = opt.label;
              }
            });
          else if (f.boolId === attrId) label = f.name;
        });
        if (label.length > 0) {
          resCopy[slugify(filterId, { lower: true, remove: /[*+~.()'"!:@]/g })] = [
            ...(resCopy[slugify(filterId, { lower: true, remove: /[*+~.()'"!:@]/g })] || []),
            slugify(label, { lower: true, remove: /[*+~.()'"!:@]/g }),
          ];
        }
        res = { ...res, filterParams: resCopy };
        allActiveFilterIds.add(filterId);
      }
      return res;
    })(),
    activeFilterIds: allActiveFilterIds,
  };
}
