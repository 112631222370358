// @flow

import parseLinkHeader from 'parse-link-header';
import idx from 'idx';

export type PaginationStatus = {|
  next: string,
  last: string,
|};

export function getPaginationState(response: Response): PaginationStatus {
  const link = response.headers.get('link') || '';
  return readPagination(link);
}

export function getNextUrl(response: Response) {
  const state = getPaginationState(response);
  return state.next;
}

// Note: exported only for tests
export function readPagination(link: string): PaginationStatus {
  const parsed = parseLinkHeader(link);
  if (!parsed) {
    return { next: '', last: '' };
  }

  return {
    next: idx(parsed, (_) => _.next.url) || '',
    last: idx(parsed, (_) => _.last.url) || '',
  };
}
