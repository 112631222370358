// @flow

import React from 'react';
import { SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';

const CategoryVideoHolder = styled.div`
  width: 100%;
  overflow: hidden;
  aspect-ratio: 16/9;
  pointer-events: none;
  ${({ style }) => style};
`;

const CategoryVideoIframe = styled.iframe`
  width: 300%;
  height: 100%;
  margin-left: -100%;
  ${({ style }) => style};
`;

function buildYouTubeIframeURL(videoID: string): string {
  const baseURL = 'https://www.youtube.com/embed/';
  const params: URLSearchParams = new URLSearchParams({
    'controls': '0',
    'modestbranding': '0',
    'rel': '0',
    'fs': '0',
    'autohide': '1',
    'autoplay': '1',
    'showinfo': '0',
    'mute': '1',
    'loop': '1',
    'color': 'white',
    'playsinline': '1',
    'enablejsapi': '1',
    'playlist': videoID
  });

  return `${baseURL}${videoID}?${params.toString()}`;
}

function getYouTubeVideoID(url: string): string | null {
  const urlObj = new URL(url);

  const videoIDFromSearch = urlObj.searchParams.get("v");
  if (videoIDFromSearch) {
    return videoIDFromSearch;
  }

  if (urlObj.hostname === "youtu.be") {
    return urlObj.pathname.substring(1);
  }

  return null;
}

type CategoryVideoProps = {|
  video: string,
  // use css from @emotion/core to style the component
  style?: SerializedStyles,
  iframeStyle?: SerializedStyles,
|};

function CategoryVideo(props: CategoryVideoProps) {
  const { video } = props;

  const videoId = getYouTubeVideoID(video);

  if(!videoId) return null;

  const videoUrlWithParams = buildYouTubeIframeURL(videoId);

  return (
    <CategoryVideoHolder style={props.style}>
      <CategoryVideoIframe style={props.iframeStyle} width="560" height="315"
        src={videoUrlWithParams}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
        allowFullScreen>
      </CategoryVideoIframe>
    </CategoryVideoHolder>
  )
}

export default CategoryVideo;