// @flow

import { Container } from '@whys/app/lib/state';
import { fetchJSON } from '@whys/fetch/lib/json';

import type { FetchEnvType } from '../types/app';
import { objectToQuery } from '../whyshop/utils/querystring';
import { create as createResourceCache } from '@whys/fetch/lib/resources/ResourceCache';

const resources = {
  sendForm: { url: '/api/forms/send/', method: 'POST' },
  getCompanyInfo: {
    url: (country: string, query: string) => `/api/forms/company-autofill/${country}/?${query}`,
  },
  getCompanyInfoVat: {
    url: (country: string, vat: string) => `/api/forms/company-autofill/${country}/${vat}/`,
  },
  getAddressInfo: {
    url: (query: string) => `/api/forms/address-autofill/?q=${query}`,
  },
  validatePhone: {
    url: (num: string) => `/api/forms/validation/phone-number/?num=${num}`,
  },
  getRegions: {
    url: (state: string) => `/api/forms/regions/?country_code=${state}`,
  },
  validateID: {
    url: (query: string) => `/api/forms/validation/company-identification/?${query}`,
  },
};
type AsyncResult = Promise<boolean>;

type Address = {
  street_address: string,
  city: string,
  country: string,
  zip_code: string,
  description: string,
  disabled: boolean,
  hide: boolean,
};

type LocalProps = {|
  fetchEnv: FetchEnvType,
|};

type LocalState = {|
  error: string,
  warning: Object,
  dialCode: string,
  regions: {
    [key: string]: Array<Object>,
  },
|};

export class ContactContainer extends Container<LocalState> {
  props: LocalProps;
  state: LocalState;

  constructor(props: LocalProps) {
    super();

    this.props = props;
    this.state = {
      regions: {},
      error: '',
      // * have to make it an object so each field has it's own warning
      warning: {},
      dialCode: '',
    };
  }

  async sendForm(data: *): AsyncResult {
    const { fetchEnv } = this.props;
    const result = await fetchJSON<void, void, void>({
      env: fetchEnv,
      ...resources.sendForm,
      data,
    });
    return result.status === 'ok';
  }

  // ! in the time of writing this functionality is only for czech companies
  async getCompanyInfoViaVat(vat: string, country: string = 'CZ') {
    const { fetchEnv } = this.props;
    const url = resources.getCompanyInfoVat.url(country, vat);

    const result = await fetchJSON<void, void, void>({
      env: fetchEnv,
      url,
      method: 'GET',
    });

    if (result.status !== 'ok') return null;
    return result.data;
  }

  async getCompanyInfo(queryObj: Object, country: string) {
    if (!queryObj) return null;

    const { fetchEnv } = this.props;
    const query = objectToQuery(queryObj);
    const url = resources.getCompanyInfo.url(country, query);

    const result = await fetchJSON<void, void, void>({
      env: fetchEnv,
      url,
      method: 'GET',
    });

    if (result.status !== 'ok') return null;
    return result.data;
  }

  mapAddresses(addresses: Array<*>): Array<Address> {
    return addresses.map((addr) => {
      return {
        street_address: addr.street_address || '',
        city: addr.city || '',
        country: addr.country_code || '',
        zip_code: addr.zip_code || '',
        description: addr.description || '',
        region: addr.region || '',
        disabled: !Boolean(addr.complete),
        hide: !Boolean(addr.street_address) && !Boolean(addr.description),
      };
    });
  }

  async getAddressInfo(address: string) {
    const { fetchEnv } = this.props;
    const query = encodeURI(address);
    const url = resources.getAddressInfo.url(query);

    // $FlowFixMe
    const result = await fetchJSON<void, void, void>({
      env: fetchEnv,
      url,
      method: 'GET',
    });

    if (result.status !== 'ok') {
      let detail = '';
      if (result.data && result.data.detail) {
        detail = result.data.detail || '';
      }
      // $FlowFixMe
      this.setState({ error: detail });
      return null;
    }
    const addrs = result.data.addresses;
    return addrs ? this.mapAddresses(addrs) : null;
  }

  async validatePhone(phone: string, type: string = '') {
    const { fetchEnv } = this.props;
    const result = await fetchJSON<void, void, void>({
      env: fetchEnv,
      url: resources.validatePhone.url(encodeURIComponent(phone)),
      method: 'GET',
    });

    let detail = '';
    if (result.status !== 'ok') {
      if (result.data && result.data.detail) {
        detail = result.data.detail || '';
      }
      // $FlowFixMe
      await this.setState({ error: detail });
      return false;
    } else {
      if (result.data && result.data.severity === 'WARN') {
        detail = result.data.detail || '';
        if (type) {
          await this.setState({ warning: { ...this.state.warning, [type]: detail } });
        }
        return true;
      }
      if (result.data && result.data.severity === 'ERROR') {
        detail = result.data.detail || '';
        await this.setState({ error: detail });
        return false;
      }
    }
    await this.setState({ error: '', warning: { ...this.state.warning, [type]: '' } });
    return true;
  }

  async validateID(queryObj: Object): AsyncResult {
    const { fetchEnv } = this.props;
    const query = objectToQuery(queryObj);
    const url = resources.validateID.url(query);
    if (!url) return true;

    // $FlowFixMe
    const result = await fetchJSON<void, void, void>({
      env: fetchEnv,
      url,
      method: 'GET',
    });

    if (result.data && result.data.severity !== 'OK') {
      let detail = result.data.detail || '';
      // $FlowFixMe
      this.setState({ error: detail });
      return false;
    }
    return true;
  }

  mapRegions = (data: string[]): Array<{ value: string, label: string }> => {
    return data.sort().map((_) => {
      return {
        value: _.toUpperCase(),
        label: _,
      };
    });
  };

  async fetchRegions(state: string, id: string): AsyncResult {
    const { fetchEnv } = this.props;
    if (!state) return false;

    // $FlowFixMe
    const result = await fetchJSON<void, void, void>({
      env: fetchEnv,
      url: resources.getRegions.url(state.toLowerCase()),
      method: 'GET',
    });

    if (result.status !== 'ok') {
      this.setState({ regions: { ...this.state.regions, [id]: null } });
      return false;
    }
    const regionsArr = this.mapRegions(result.data);
    this.setState({ regions: { ...this.state.regions, [id]: regionsArr } });
    return true;
  }

  // taken from https://stackoverflow.com/questions/18936915/dynamically-set-property-of-nested-object
  set(schema: Object, path: string, value: string) {
    var pList = path.split('.');
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }

    schema[pList[len - 1]] = value;
  }

  // * main bulk of error are captured by form
  // * the problem happens when user inputs number, but then removes it
  // * at that moment form saves prefix, which we don't want to be sent
  async validateRegisterData(data: Object) {
    const newData = { ...data };
    const numbers = [];
    if (data.billing_address && data.billing_address.phone_number) {
      numbers.push({
        key: 'billing_address.phone_number',
        value: data.billing_address.phone_number,
      });
    }
    if (data.billing_address && data.billing_address.mobile_number) {
      numbers.push({
        key: 'billing_address.mobile_number',
        value: data.billing_address.mobile_number,
      });
    }
    if (data.shipping_address && data.shipping_address.phone_number) {
      numbers.push({
        key: 'shipping_address.phone_number',
        value: data.shipping_address.phone_number,
      });
    }
    if (data.shipping_address && data.shipping_address.mobile_number) {
      numbers.push({
        key: 'shipping_address.mobile_number',
        value: data.shipping_address.mobile_number,
      });
    }
    if (data.profile && data.profile.phone_number) {
      numbers.push({ key: 'profile.phone_number', value: data.profile.phone_number });
    }
    if (data.profile && data.profile.mobile_number) {
      numbers.push({ key: 'profile.mobile_number', value: data.profile.mobile_number });
    }
    if (data.phone_number) {
      numbers.push({ key: 'phone_number', value: data.phone_number });
    }
    if (data.mobile_number) {
      numbers.push({ key: 'mobile_number', value: data.mobile_number });
    }

    await Promise.all(
      numbers.map(async (number) => {
        let result = await this.validatePhone(number.value);
        if (!result) this.set(newData, number.key, '');
      })
    );
    return newData;
  }

  setRegions(regions: ?Array<Object>, id: string) {
    this.setState({ regions: { ...this.state.regions, [id]: regions } });
  }

  getRegions(id: string) {
    return this.state.regions[id];
  }

  setDialCode(dialCode: string) {
    this.setState({ dialCode });
  }

  getDialCode() {
    return this.state.dialCode;
  }

  getErrorMsg() {
    return this.state.error;
  }

  getWarningMsg(type: string) {
    return this.state.warning[type] || '';
  }

  removeWarningMsg(type: string) {
    const warning = { ...this.state.warning };
    delete warning[type];
    this.setState({ warning });
  }
}
