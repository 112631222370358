// @flow

import { Container } from '@whys/app/lib/state';
import { getJSON } from '@whys/fetch/lib/json';

import type { FetchEnvType } from '../types';

const resources = {
  catalogUrl: '/api/content/external-catalog/',
};

type LocalProps = {|
  fetchEnv: FetchEnvType,
|};

type LocalState = {
  catalogUrl: string,
};

export class ExternalCatalogContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  constructor(props: LocalProps) {
    super();
    this.props = props;
    this.state = {
      catalogUrl: '',
    };
  }

  loadCatalogUrl = async () => {
    const result = await getJSON(resources.catalogUrl, this.props.fetchEnv);
    if (result.status === 'ok') {
      const newUrl = result.data.catalog_url || '';
      this.setState({ catalogUrl: newUrl });
    }
  };

  getCatalogUrl = (): string => {
    return this.state.catalogUrl;
  };
}
