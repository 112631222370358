// @flow

import type {
  OrderOverviewItem,
  InvoiceOverviewItem,
  UsersPayload,
  UserModel,
  WorkerRoles,
  WorkerRolesPayload,
} from '../../appState/types';
import idx from 'idx';

export const resources = {
  businesses: { url: '/api/business/' },
  orders: { url: '/api/order/' },
  invoices: { url: '/api/order/invoice/' },
  billingAddress: { url: '/api/profile/address-billing/' },
  dashboard: { url: '/api/profile/dashboard/' },
  changePassword: { url: '/api/auth/password/change/', method: 'POST' },
  createShippingAddress: { url: `/api/business/address-shipping/`, method: 'POST' },
  updateProfile: { url: '/api/profile/', method: 'PUT' },
  deleteBusiness: (id: string) => ({
    url: `/api/business/address-shipping/${id}/`,
    method: 'DELETE',
  }),
  editBusiness: (id: string) => ({
    url: `/api/business/address-shipping/${id}/`,
    method: 'PUT',
  }),
  userData: { url: '/api/auth/user/' },
  users: { url: '/api/business/worker-roles/' },
  countries: { url: '/api/shop/options/allowed-countries/' },
  roles: { url: '/api/profile/roles/' },
  inviteUser: { url: '/api/business/invite-user/', method: 'POST' },
  deleteUser: (businessId: string, userId: string) => ({
    url: `/api/business/${businessId}/user/${userId}/`,
    method: 'DELETE',
  }),
  editUser: (userId: string) => ({
    url: `/api/business/worker-roles/${userId}/`,
    method: 'PUT',
  }),
};

function mapSeverity(value) {
  const mapping = {
    PART_OK: 'warn',
    WARN: 'error',
    OK: 'ok',
    INACTIVE: 'storno',
    INFO: 'info',
    '': 'unknown',
  };
  return mapping[value || ''] || 'unknown';
}

export function mapOrder(order: *): OrderOverviewItem {
  return {
    id: order.id,
    orderNumber: order.erp_order_id || order.id,
    acceptDate: order.time_created,
    sendDate: order.delivery_date,
    statusTitle: idx(order.info, (_) => _.order_status.short_name) || '',
    statusTitleDetail: idx(order.info, (_) => _.order_status.name) || '',
    statusType: mapSeverity(idx(order.info, (_) => _.order_status.severity_type)),
    price: order.prices.total_discount_price_gross,
    invoiceId: order.invoices.length ? order.invoices[0].variable_symbol : null,
    shippingAddress: order.shipping_address,
    items: order.items,
  };
}

export function mapInvoice(invoice: *): InvoiceOverviewItem {
  return {
    id: invoice.id,
    invoiceNumber: invoice.variable_symbol,
    createdDate: invoice.issued_dt,
    dueDate: invoice.maturity_dt,
    priceVAT: invoice.prices.total_discount_price_gross,
    leftToPay: invoice.price_to_pay_gross,
    statusType: invoice.status.severity_type,
    statusTitle: invoice.status.short_name,
    shippingAddress: invoice.shipping_address,
    orderNumber: invoice.erp_order_id,
    paymentMethod: '',
  };
}

export function mapUsers(users: Array<UsersPayload>): Array<UserModel> {
  return users.map(({ user, worker_roles, ...other }) => ({
    ...other,
    email: user.email,
    workerRoles: mapWorkerRolesToObject(worker_roles),
  }));
}
export function mapWorkerRolesToArray(workerRoles: WorkerRoles): WorkerRolesPayload {
  return Object.keys(workerRoles).map((key) => ({
    business_id: key,
    role_id: workerRoles[key],
  }));
}

export function mapWorkerRolesToObject(workerRoles: WorkerRolesPayload): WorkerRoles {
  const result = {};
  workerRoles.forEach((item) => {
    result[String(item.business_id)] = String(item.role_id);
  });
  return result;
}
