// @flow

import idx from 'idx';
import type { BreadCrumbs } from '../../appState/types';

// type FrontendID = string;

//
// Models
//

export type PageInfo = {|
  // "id"

  // this is deliberately duplicate
  id: string,

  slug: string,
  title: string,
  description: string,
  keywords: string,
  model: 'staticpage' | 'systempage' | 'category' | 'variant' | 'article',
  modelID: string,
  breadcrumbs: BreadCrumbs,
|};

type BackendID = number;

//
// API
//

export type PageInfoPayload = {|
  // url: null,
  slug: string,
  // lang: 'cs',
  title: string,
  description: string,
  keywords: string,
  url_canonical: string,
  object_id: BackendID,
  content_type: {
    // _name: 'kategorie',
    // app_label: 'product',
    model: 'category' | 'systempage' | 'staticpage' | 'variant' | 'article',
  },
  breadcrumbs: BreadCrumbs,
|};

//
// Mapping
//

export function mapPageInfo(payload: PageInfoPayload): PageInfo {
  const mapModelNames = (val) =>
    ({
      category: 'category',
      variant: 'variant',
      systempage: 'systempage',
      staticpage: 'staticpage',
      article: 'article',
    }[val]);

  const slug = payload.slug;
  return {
    id: slug,
    slug,
    title: payload.title,
    description: payload.description,
    keywords: payload.keywords,
    urlCanonical: payload.url_canonical || '',
    model: mapModelNames(idx(payload, (_) => _.content_type.model) || 'systempage'),
    modelID: String(payload.object_id),
    breadcrumbs: payload.breadcrumbs,
  };
}
