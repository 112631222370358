// @flow

import { Container } from '@whys/app/lib/state';
import { fetchJSON, getJSON } from '@whys/fetch/lib/json';

import type { FetchEnvType } from '../types/app';
import { getFirstApiError } from '../forms/api';
import type { NormalizedApiErrors } from '../appState/types';
import type { ApiFieldConfig, FieldConfig } from '../forms';
import type { FieldNames as ConsentFieldNames } from '../formFieldsets/ConsentFieldset';
import type { FieldNames as PersonalFieldNames } from '../formFieldsets/PersonalFieldset';
import type { FieldNames as BusinessFieldNames } from '../formFieldsets/BusinessFieldset';
import idx from 'idx';

const resources = {
  register: { url: '/api/auth/registration/', method: 'POST' },
  registerLite: { url: '/api/auth/registration-lite/', method: 'POST' },
  registrationOptions: { url: '/api/auth/registration/options/' },
  registrationLiteOptions: { url: '/api/auth/registration-lite/options/' },
};
type AsyncResult = Promise<{| errors: ?Object, hasError: boolean |}>;
type AsyncBinaryResult = Promise<boolean>;
export type RegisterResult =
  | {| status: 'error', errors: NormalizedApiErrors |}
  | {| status: 'ok' |}
  | {| status: 'failed' |};

export type RegistrationLiteOptions = {|
  contentHtml: string,
  fields: { [PersonalFieldNames | ConsentFieldNames]: FieldConfig },
|};

type RegistrationLiteOptionsPayload = {|
  content_html: string,
  fields: Array<{ field_name: PersonalFieldNames | ConsentFieldNames, ...ApiFieldConfig }>,
|};

export type RegistrationOptions = {|
  contentHtml: string,
  personalFields: { [PersonalFieldNames | ConsentFieldNames]: FieldConfig },
  addressFields: { [BusinessFieldNames]: FieldConfig },
|};

type RegistrationOptionsPayload = {|
  content_html: string,
  personal_fields: Array<{ field_name: PersonalFieldNames | ConsentFieldNames, ...ApiFieldConfig }>,
  address_fields: Array<{ field_name: BusinessFieldNames, ...ApiFieldConfig }>,
|};

type LocalProps = {|
  fetchEnv: FetchEnvType,
|};

type LocalState = {|
  errors: Object,
  registrationOptions: RegistrationOptions,
  registrationLiteOptions: RegistrationLiteOptions,
  _id: string,
|};

const mapLiteOptions = ({ content_html: contentHtml, fields }: RegistrationLiteOptionsPayload) => {
  const mappedFields = {};
  fields.forEach(({ field_name, required }) => (mappedFields[field_name] = { required }));

  return {
    contentHtml,
    fields: mappedFields,
  };
};

const mapOptions = ({
  content_html: contentHtml,
  address_fields,
  personal_fields,
}: RegistrationOptionsPayload) => {
  const personalFields = {};
  const addressFields = {};
  address_fields.forEach(({ field_name, required }) => (addressFields[field_name] = { required }));
  personal_fields.forEach(
    ({ field_name, required }) => (personalFields[field_name] = { required })
  );

  return {
    contentHtml,
    addressFields,
    personalFields,
  };
};

export class RegisterContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  constructor(props: LocalProps) {
    super();
    this.props = props;
    this.state = {
      errors: {},
      registrationOptions: { contentHtml: '', personalFields: {}, addressFields: {} },
      registrationLiteOptions: { contentHtml: '', fields: {} },
      _id: '',
    };
  }

  getUserID = () => this.state._id;

  async _doRegister(url: string, data: Object): Promise<RegisterResult> {
    const { fetchEnv } = this.props;
    // $FlowFixMe
    const result = await fetchJSON<void, void, void>({
      env: fetchEnv,
      url,
      method: 'POST',
      data,
    });
    if (result.status === 'ok') {
      const { data } = result;
      this.setState({ _id: String(idx(data, (_) => _.user.id) || '') });
      return { status: 'ok' };
    }
    if (result.status === 'error') {
      if (result.response.status === 500) {
        return { status: 'failed' };
      }
      const errKeys = Object.keys(result.data);
      const firstErrKey = errKeys[0];
      return {
        errors: {
          submitError: getFirstApiError(result.data),
          fieldErrors: [],
          displayError: { [firstErrKey]: getFirstApiError(result.data) },
        },
        status: 'error',
      };
    }
    return { status: 'failed' };
  }

  async registerFull(data: Object): Promise<RegisterResult> {
    return this._doRegister(resources.register.url, data);
  }

  async registerLite(data: Object): Promise<RegisterResult> {
    return this._doRegister(resources.registerLite.url, data);
  }

  async loadRegistrationOptions(): AsyncBinaryResult {
    const result = await getJSON(resources.registrationOptions.url, this.props.fetchEnv);
    if (result.status === 'ok') {
      this.setState({ registrationOptions: mapOptions(result.data) });
      return true;
    }
    return false;
  }

  async loadRegistrationLiteOptions(): AsyncBinaryResult {
    const result = await getJSON(resources.registrationLiteOptions.url, this.props.fetchEnv);
    if (result.status === 'ok') {
      this.setState({ registrationLiteOptions: mapLiteOptions(result.data) });
      return true;
    }
    return false;
  }

  selectRegistrationOptions(): RegistrationOptions {
    return this.state.registrationOptions;
  }
  selectRegistrationLiteOptions(): RegistrationLiteOptions {
    return this.state.registrationLiteOptions;
  }
}
