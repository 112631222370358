// @flow

import type { I18nType } from '../types/npm';

import isEmail from 'validator/lib/isEmail';

const coerceString = (val) => {
  if (val === undefined || val === null) {
    return '';
  }
  return String(val);
};

type ValidateResult = string | boolean | void;

type Validate = (data: any) => ValidateResult | Promise<ValidateResult>;

// This is normalized result, so we can use it safely in our code
export type InputPreset = {|
  // NotePrototype: make it required
  label?: string,
  required?: {| value: boolean, message: string |} | boolean | string,

  options?: Array<{| label: string, value: string |}>,
  disabled?: boolean,
  ignore?: boolean,

  pattern?: {| value: RegExp, message: string |},
  maxLength?: number | {| value: number, message: string |},
  minLength?: number | {| value: number, message: string |},
  min?: {| value: number, message: string |},
  max?: {| value: number, message: string |},
  validate?: Validate | { [string]: Validate },
  show?: boolean,
|};

type InputPresetOptions = $Shape<InputPreset>;

function createPreset(i18n, options: InputPresetOptions): InputPreset {
  const { required, maxLength, minLength, ...pass } = options;
  return {
    ...pass,
    required: (() => {
      const requiredMixed = options.required;
      if (requiredMixed === true) {
        return { value: true, message: i18n.t`This input is required.` };
      }
      if (typeof requiredMixed === 'string') {
        return { value: true, message: requiredMixed };
      }
      if (typeof requiredMixed === 'object') {
        return requiredMixed;
      }
      return false;
    })(),
    maxLength:
      typeof maxLength === 'number'
        ? { value: maxLength, message: i18n.t`Max length is ${maxLength}.` }
        : maxLength,
    minLength:
      typeof minLength === 'number'
        ? { value: minLength, message: i18n.t`Min length is ${minLength}.` }
        : minLength,
  };
}

function definePreset(
  resolveStatic: (i18n: I18nType) => InputPresetOptions
): (i18n: I18nType, override?: InputPresetOptions) => InputPreset {
  return (i18n: I18nType, dynamicOpts?: InputPresetOptions) => {
    const staticOpts = resolveStatic(i18n);
    return createPreset(i18n, { ...staticOpts, ...dynamicOpts });
  };
}

export const company_name = definePreset((i18n) => ({
  label: i18n.t`Business title`,
  maxLength: 64,
}));

export const first_name = definePreset((i18n) => ({ label: i18n.t`First name`, maxLength: 64 }));

export const last_name = definePreset((i18n) => ({ label: i18n.t`Last name`, maxLength: 64 }));

export const street_address = definePreset((i18n) => ({ label: i18n.t`Street` }));

export const zip_code = definePreset((i18n) => ({ label: i18n.t`Postal code`, maxLength: 8 }));

export const city = definePreset((i18n) => ({ label: i18n.t`City` }));

export const country = definePreset((i18n) => ({ label: i18n.t`Country` }));

export const identification_number = definePreset((i18n) => ({ label: i18n.t`Business ID` }));

export const vat_number = definePreset((i18n) => ({ label: i18n.t`Tax ID` }));

export const international_vat_id = definePreset((i18n) => ({
  label: i18n.t`International vat number`,
}));

export const phone_number = definePreset((i18n) => ({
  label: i18n.t`Phone number`,
  minLength: {
    value: 8,
    message: i18n.t`Phone number must contain at least 1 number.`,
  },
}));

export const mobile_number = definePreset((i18n) => ({
  label: i18n.t`Mobile number`,
  minLength: {
    value: 8,
    message: i18n.t`Mobile number must contain at least 1 number.`,
  },
}));

export const fax_number = definePreset((i18n) => ({ label: i18n.t`Fax number` }));

export const shipping_note = definePreset((i18n) => ({ label: i18n.t`Delivery note` }));

export const region = definePreset((i18n) => ({ label: i18n.t`Region` }));

export const send_catalog = definePreset((i18n) => ({}));

export const customer_id = definePreset((i18n) => ({
  label: i18n.t`Customer ID`,
  pattern: {
    value: /^\d{8}$/,
    message: i18n.t`Enter 8 digits.`,
  },
  minLength: {
    value: 8,
    message: i18n.t`The Customer ID must contain 8 numbers.`,
  },
}));

export const password = definePreset((i18n) => ({
  label: i18n.t`Password`,
  pattern: {
    value: /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]/,
    message: i18n.t`Password must contain letters and numbers.`,
  },
  minLength: {
    value: 8,
    message: i18n.t`The password must contain at least 8 characters.`,
  },
}));

export const email = definePreset((i18n) => ({
  label: i18n.t`E-mail`,
  validate: (value: ?string) => {
    if (!value) {
      return true;
    }
    return isEmail(coerceString(value)) || i18n.t`The email address is not valid.`;
  },
}));

export const custom_field = definePreset((i18n) => ({ label: '' }));
