// @flow

import * as React from 'react';
import * as baseUI from '../baseUI';
import { Block, FlexRow } from '../baseUI';
import type { MenuItem } from '../appState/types';
import { withStyles } from '@material-ui/core';
import styled from '@emotion/styled';
import { TextLink } from '../app/link';

const Item = styled.div`
  font-size: 0.9em;
  max-width: 150px;
`;

const Grid = withStyles((theme) => ({
  root: {
    display: 'grid',
    gap: '2em 1em',

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(1, minmax(150px, 1fr))',
    },
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(2, minmax(150px, 1fr))',
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: 'repeat(3, minmax(150px, 1fr))',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, minmax(150px, 1fr))',
    },
  },
}))((props) => <div className={props.classes.root} {...props} />);

const Image = styled.img`
  max-width: 45px;
  height: auto;
  margin-right: 10px;
`;

const Title = styled.div`
  margin-bottom: 30px;
  font-size: 17px;
  font-weight: bold;
`;

const StyledMenuItem = styled.div`
  &:hover {
    background-color: #f6f6f8;
    border-radius: 5px;
  }
`;

function MenuItemPopover(props: {| items: Array<MenuItem>, title?: string, categoryUrl: string |}) {
  return (
    <baseUI.Block backgroundColor="white" padding={30}>
      {props.title && (
        <TextLink to={props.categoryUrl}>
          <Title>{props.title}</Title>
        </TextLink>
      )}
      <Grid>
        {props.items.map((item) => {
          const imgSrc = item.image.original;
          return (
            <TextLink key={item.id} to={item.categoryUrl}>
              <StyledMenuItem>
                <FlexRow
                  alignItems="center"
                  backgroundColor={window.location.pathname === item.categoryUrl ? '#f6f6f8' : ''}
                  style={{ borderRadius: '5px', padding: '5px 15px' }}
                >
                  {imgSrc && (
                    <Block>
                      <Image src={imgSrc} alt={item.title} />
                    </Block>
                  )}
                  <Item>{item.title}</Item>
                </FlexRow>
              </StyledMenuItem>
            </TextLink>
          );
        })}
      </Grid>
    </baseUI.Block>
  );
}

export default MenuItemPopover;
