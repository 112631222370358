// @flow

import React, { useState } from 'react';
// import idx from 'idx';
import { smoothScrollTopToElement } from '../jsutils/browser';
// import type { RHForm } from '../forms';
import { useFormContext } from '../forms';
import { Controller } from 'react-hook-form';
import type { InputPreset } from '../forms/preset';
import { PasswordTextField } from '../formInputs/PasswordTextField';
import { ValidatedTextField } from '../formInputs/ValidatedTextField';
import { toInputProps } from '../forms/config';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/Radio/Radio';
import { TextField } from '@material-ui/core';
import { SelectField } from './SelectField';

const onFocus = ({ target }) => {
  // Handle if input is hidden within sticky header
  // Before form is submitted, its validated on browsers focuses invalid inputs (e.g. required or maxlength)
  const top = target.getBoundingClientRect().top;
  if (top < 150) {
    smoothScrollTopToElement(target);
  }
};

export function RHFValidatedTextField(props: { config: InputPreset, name: string }) {
  const formMethods = useFormContext();
  const { name, config, ...passProps } = props;
  const { formState, errors, register } = formMethods;
  const error = errors[name];
  const inputProps = toInputProps(config);
  return (
    <ValidatedTextField
      name={name}
      error={!!error}
      helperText={error && error.message}
      showError={!!error}
      showSuccess={!error && !!formState.touched[name]}
      label={config.label}
      required={inputProps.required}
      inputRef={register(config)}
      inputProps={inputProps}
      onFocus={onFocus}
      {...passProps}
    />
  );
}

export function RHFPasswordTextField(props: { config: InputPreset, name: string }) {
  const formMethods = useFormContext();
  const { name, config, ...passProps } = props;
  const { errors, register } = formMethods;
  const error = errors[name];
  const inputProps = toInputProps(config);
  return (
    <PasswordTextField
      name={name}
      error={!!error}
      helperText={error && error.message}
      label={config.label}
      required={inputProps.required}
      inputRef={register(config)}
      inputProps={inputProps}
      onFocus={onFocus}
      {...passProps}
    />
  );
}

export const TextSelectField = (props: *) => {
  const { name, label, fieldConf, options, onChange, registerConf } = props;

  const formMethods = useFormContext();
  const { errors, register, getValues, watch } = formMethods;
  const error = errors[name];

  const watchName = watch(name);

  const vals = getValues();

  register({ name }, registerConf);

  const { required, disabled } = toInputProps(fieldConf);

  return (
    <>
      {!options || options.length === 0 ? (
        <TextField
          inputRef={register(registerConf)}
          name={name}
          label={label}
          required={required}
          disabled={disabled}
          fullWidth
          error={!!error}
          helperText={error ? error.message : ' '}
          defaultValue={vals[name] || ''}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => onChange(e.target.value)}
        />
      ) : (
        <SelectField
          onChange={onChange}
          label={label}
          error={!!error}
          helperText={error ? error.message : ' '}
          options={options}
          required={required}
          id={`select-field-${name}`}
          name={name}
          defaultValue={vals[name] || ''}
          disabled={disabled}
        />
      )}
    </>
  );
};

export function RHFRadioField(props: {|
  config: InputPreset,
  name: string,
  options: Array<{| value: string, label: string |}>,
  defaultValue?: string,
|}) {
  const formMethods = useFormContext();
  const { name, config, defaultValue } = props;
  const { errors } = formMethods;
  const error = errors[name];
  const inputProps = toInputProps(config);

  return (
    <FormControl component="fieldset">
      <FormLabel>
        {config.label} {inputProps.required ? '*' : ''}
      </FormLabel>
      <Controller
        control={formMethods.control}
        rules={config}
        name={name}
        defaultValue={defaultValue}
        as={
          <RadioGroup name={name} row>
            {(props.options || []).map((opt) => {
              return (
                <FormControlLabel
                  key={opt.value}
                  value={opt.value}
                  control={<Radio name={name} required={inputProps.required} color="primary" />}
                  onFocus={onFocus}
                  label={opt.label}
                  data-cy="salutation-checkbox"
                />
              );
            })}
          </RadioGroup>
        }
      />
      {error ? <FormHelperText error>{error && error.message}</FormHelperText> : null}
    </FormControl>
  );
}
// {errors.title && <ThemedText color="errorText">{errors.title.message}</ThemedText>}
