// @flow

import * as React from 'react';

import { Block } from '../baseUI';
import { useTheme } from '../app/theme';

export function EmptyPageTitle(props: {| children: React.Node |}) {
  const theme = useTheme();
  return (
    <Block fontSize={22} color={theme.color('primary')}>
      {props.children}
    </Block>
  );
}

export function PageTitle(props: {| children: React.Node |}) {
  const theme = useTheme();
  return <h1>{props.children}</h1>;
}
