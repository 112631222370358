// @flow

import { createContext, useContext } from 'react';

import type { I18nType } from '../types/npm';

const LinguiContext = createContext<?I18nType>();

export const LinguiProvider = LinguiContext.Provider;

// Lingui as of now has no hook API, so we have to create own context.
// It'll work without the context (see https://github.com/lingui/js-lingui/issues/390#issuecomment-438173038)
// but its not released yet.

export function useLingui(): I18nType {
  const value = useContext(LinguiContext);
  if (!value) {
    throw new Error('Please use LinguiProvider.');
  }
  return value;
}
