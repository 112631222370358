export const localStorageAPI = {
  getValue: (key: string): ?Object => {
    if (!global.localStorage) {
      return null;
    }
    const stored = global.localStorage.getItem(key);
    if (!stored) {
      return null;
    }
    try {
      return JSON.parse(stored);
    } catch (e) {
      // console.error(e);
    }
    return null;
  },
  setValue: (key: string, value: any) => {
    if (!global.localStorage) {
      return;
    }
    let strValue = '';
    try {
      strValue = JSON.stringify(value);
    } catch (e) {
      // console.error(e);
    }
    global.localStorage.setItem(key, strValue);
  },
};
