// @flow

import { useState } from 'react';
import { useDebounce } from 'use-debounce';

import { useCache } from '../app/use';

export function useSearchInput(): {|
  inputValue: string,
  searchTerm: string,
  setInputValue: (string) => void,
|} {
  //
  // This uses app cache to store the input value, because we have 2 headers, one of them
  // and the input must be in sync.
  //

  const cache = useCache('searchInput');
  const cachedInputValue = cache.getValue('tmpSearch', '');

  const [renderCount, setNewRender] = useState(0);
  const [searchTerm] = useDebounce(cachedInputValue, 150);

  const setInputValue = (value) => {
    cache.setValue('tmpSearch', value);
    setNewRender(renderCount + 1);
  };

  return { inputValue: cachedInputValue, searchTerm, setInputValue };
}
