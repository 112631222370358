// @flow

import React, { useState } from 'react';
import styled from '@emotion/styled';
import Button from '@material-ui/core/Button';
import { Trans } from '@lingui/react';
import { TextLink } from '../app/link';

import { styledTheme, useTheme } from '../app/theme';
import * as baseUI from '../baseUI';
import { useGlobalContainer, useIsBrowser } from '../app/use';
import type { NotificationType, Notification } from '../appState/types';
import { ThemedText } from '../appUI';
import { useWebAnalyticsContainer } from '../app/state';

function CookieNotification({ onAccept, privacyPolicyUrl, websiteName }) {
  return (
    <NotificationWrapper>
      <Trans>
        The {websiteName} website uses cookies. By clicking the Accept button or by continuing to
        use the site, the user consents to the use of cookies and other similar technologies that
        serve to provide a better website experience and display more relevant ads on the site. The
        consent may be revoked at any time. If you wish to find out more, read our{' '}
        <TextLink golden to={privacyPolicyUrl}>
          Privacy Policy
        </TextLink>
        .
      </Trans>
      <Button onClick={onAccept} style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
        <Trans>Accept</Trans>
      </Button>
    </NotificationWrapper>
  );
}

export const NotificationBar = styled.div`
  width: 100%;
  display: flex;
  background-color: ${styledTheme.color('greyBg')};
  box-sizing: border-box;
  border-bottom: 1px solid ${styledTheme.color('primaryBg')};
`;

const NotificationWrapper = styled.div`
  padding: 8px;
`;

type LocalProps = {|
  ...Notification,
|};

export function NotificationView(props: LocalProps) {
  const theme = useTheme();
  const globalContainer = useGlobalContainer();
  const isBrowser = useIsBrowser();
  const webAnalytics = useWebAnalyticsContainer();
  const [loaded, setLoaded] = useState(false);

  const showNotification = globalContainer.showNotification(props.type);
  const cookiesAccepted = globalContainer.selectCookiesAccepted();
  const privacyPolicyUrl = globalContainer.selectStaticPageInfo('privacy-policy').url;
  const websiteName = globalContainer.selectSiteInfo().name;

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  const isType = (type: NotificationType) => {
    return props.type === type;
  };

  const getMessage = () => {
    if (isType('notification_cookies') && !cookiesAccepted && isBrowser) {
      return (
        <CookieNotification
          websiteName={websiteName}
          onAccept={() => {
            globalContainer.acceptCookies();
            globalContainer.setShowNotification('notification_cookies', false);
            webAnalytics.microCookie();
          }}
          privacyPolicyUrl={privacyPolicyUrl}
        />
      );
    }

    if (isType('notification_address')) {
      if (!props.params) return null;
      const { primary_phone_number, opening_hours } = props.params[props.type];
      return (
        <NotificationWrapper>
          <Trans>
            You've made some changes of your business address that hasn't been validated by $
            {globalContainer.getCompanyName()}. If you want to speed up the process, please call{' '}
            <strong>{primary_phone_number}</strong> (working hours: <strong>{opening_hours}</strong>
            ).
          </Trans>
        </NotificationWrapper>
      );
    }

    if (isType('notification_coupon')) {
      if (!props.params) return null;
      const { code } = props.params[props.type];
      return (
        <NotificationWrapper>
          <Trans>
            You have promo code to use. <strong>{code}</strong> Hurry up.
          </Trans>
        </NotificationWrapper>
      );
    }

    if (isType('notification_newsletter')) {
      if (!props.params) return null;
      const { discount_amount } = props.params[props.type];
      let newsletterElement;
      if (loaded) newsletterElement = document.getElementById('newsletter-form');
      return (
        <NotificationWrapper>
          <Trans>
            Sign up for our
            <span
              onClick={() => newsletterElement && newsletterElement.focus()}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              newsletter
            </span>
            . Be well informed about our sales and get discount code -{discount_amount}% for all
            products.
          </Trans>
        </NotificationWrapper>
      );
    }

    return null;
  };

  const message = getMessage();

  if (!showNotification || !message) return null;

  return (
    <NotificationBar data-nosnippet="data-nosnippet">
      <baseUI.Block width="100%" maxWidth={theme.size('maxPageWidth')}>
        <ThemedText color="primary">{message}</ThemedText>
      </baseUI.Block>
    </NotificationBar>
  );
}
