// @flow

import * as React from 'react';

import './LoadingDots.css';

type LocalProps = {||};

type LocalState = void;

class LoadingDots extends React.Component<LocalProps, LocalState> {
  render() {
    return (
      <div className="LoadingDots">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    );
  }
}

export default LoadingDots;
