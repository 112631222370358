// @flow

import * as React from 'react';

import MuiTooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import { withStyles, makeStyles } from '@material-ui/core/styles';

// import * as baseUI from '../baseUI';

type LocalProps = {|
  delayType: 'normal' | 'long',
  children: React.Element<any>,
  title: any,
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top',
  classes: *,
|};

const Tooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 12,
  },
}))((props: LocalProps) => {
  const { classes, title, children, delayType = 'normal', placement } = props;
  return (
    <MuiTooltip
      classes={classes}
      TransitionComponent={Fade}
      title={title}
      aria-label={title}
      enterDelay={(() => {
        if (delayType === 'long') {
          return 550;
        }
        return 350;
      })()}
      leaveDelay={50}
      placement={placement}
    >
      {children}
    </MuiTooltip>
  );
});

export default Tooltip;
