// @flow

import queryString from 'query-string';

export function arrayObjectToQuery(obj: Object): string {
  const q = queryString.stringify(obj.filterNames, { arrayFormat: 'comma' });
  delete obj.filterNames;
  // delete obj.items_count;
  // delete obj.page;
  const query = queryString.stringify(obj, { arrayFormat: 'comma' });
  const res = q + '&' + query;
  return res;
}

export function objectToQuery(obj: Object): string {
  const query = queryString.stringify(obj, { arrayFormat: 'comma' });
  return query;
}
