// @flow

import idx from 'idx';
import { Container } from '@whys/app/lib/state';
import { create as createResourceCache } from '@whys/fetch/lib/resources/ResourceCache';
import { create as createResourceByIdCache } from '@whys/fetch/lib/resources/ResourceByIdCache';
import type { ResourceCache, ResourceByIdCache } from '@whys/fetch';

import type { AppCacheType } from '../whyshop/types';
import type { ImageJsonType, PageAttributes } from '../whyshop/jsonTypes';
import type { FetchEnvType } from '../types/app';
import { getJSON } from '@whys/fetch/lib/json';
import type { ProductItem } from '../whyshop/models/product';
import type { ProfileModel } from '../appState/types';

const resources = {
  listArticles: { url: `/api/blog/article/` },
  articleDetail: (id: string) => ({ url: `/api/blog/article/${id}/` }),
  listCampaigns: { url: `/api/campaigns/` },
  campaignDetail: (id: string) => ({ url: `/api/campaigns/${id}/` }),
  listBestsellers: (id: string) => ({ url: `/api/search/filter/${id}/?items_count=11` }),
  listLastVisited: (profileId: number, contentType: 'variant' | 'article') => ({
    url: `/api/stats/last_visited/${profileId}/${contentType}/`,
  }),
};

export type Tag = any;

export type ArticleListPayload = {|
  id: number,
  time_created: string,
  title: string,
  description_html: string,
  main_image: ImageJsonType,
  tags: Tag[],
  page_attributes: PageAttributes,
|};

export type CampaignPayload = {|
  id: number,
  action: {
    label: string,
    url: string,
  },
  img: {
    mobile?: string,
    standard?: string,
  },
  text: {
    align: string,
    description_html: string,
    title_html: string,
  },
  type: string,
|};

export type CampaignOverviewModel = {|
  id: string,
  label: string,
  url: string,
  img: any,
  align: string,
  descriptionHtml: string,
  title: string,
  type: string,
|};

export type ArticleDetailPayload = {|
  id: number,
  time_created: string,
  title: string,
  description_html: string,
  content_html: string,
  tags: Tag[],
|};

export type ArticleOverviewModel = {|
  id: string,
  createdDate: Date,
  title: string,
  descriptionHtml: string,
  mainImage: string,
  tags: Tag[],
  url: string,
|};

export type ArticleDetailModel = {|
  id: string,
  createdDate: Date,
  title: string,
  descriptionHtml: string,
  contentHtml: string, // with HTML
  tags: Tag[],
|};

export function mapArticleOverview(i: ArticleListPayload): ArticleOverviewModel {
  return {
    id: String(i.id),
    createdDate: new Date(i.time_created),
    title: i.title || '',
    descriptionHtml: i.description_html || '',
    mainImage: idx(i, (_) => _.main_image.medium) || '',
    tags: i.tags || [],
    url: idx(i, (_) => _.page_attributes.url) || '',
  };
}
export function mapArticleDetail(data: ArticleDetailPayload): ArticleDetailModel {
  return {
    id: String(data.id),
    createdDate: new Date(data.time_created),
    title: data.title || '',
    descriptionHtml: data.description_html || '',
    contentHtml: data.content_html || '',
    tags: data.tags || [],
  };
}

export function mapCampaignOverview(i: CampaignPayload): CampaignOverviewModel {
  return {
    id: String(i.id),
    label: i.action.label || '',
    url: i.action.url || '',
    img: idx(i, (_) => _.img.standard) || '',
    align: i.text.align || '',
    descriptionHtml: i.text.description_html || '',
    title: i.text.title_html || '',
    type: i.type || '',
  };
}
export function mapCampaignDetail(data: CampaignPayload): CampaignOverviewModel {
  return {
    id: String(data.id),
    label: data.action.label || '',
    url: data.action.url || '',
    img: idx(data, (_) => _.img.standard) || '',
    align: data.text.align || '',
    descriptionHtml: data.text.description_html || '',
    title: data.text.title_html || '',
    type: data.type || '',
  };
}

type LocalState = {|
  articles: ArticleOverviewModel[],
  campaigns: CampaignPayload[],
|};

type LocalProps = {|
  fetchEnv: FetchEnvType,
  appCache: AppCacheType,
  mapProduct: (any) => ProductItem,
  profile: $Shape<ProfileModel>,
|};

export class HomepageContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  articleListCache: ResourceCache<ArticleOverviewModel[]>;
  articlesMapCache: ResourceByIdCache<ArticleDetailModel, null>;
  campaignListCache: ResourceCache<CampaignOverviewModel[]>;
  campaignsMapCache: ResourceByIdCache<CampaignOverviewModel, null>;
  // * note(Andy): any is used here... but really it is ProductItem[]
  // * createResourceByIdCache does NOT want an array and it leads to nonsensical type errors
  // * optionally we could make it into Object with id and array attributes like we did with CategoryFiltersArray type
  bestsellersCache: ResourceByIdCache<any, any>;
  lastVisitedCache: ResourceCache<ProductItem[]>;

  constructor(props: LocalProps) {
    super();

    const { fetchEnv, appCache, mapProduct } = props;

    this.articlesMapCache = createResourceByIdCache<ArticleDetailPayload, ArticleDetailModel, null>(
      {
        fetchEnv,
        cache: appCache.getOrCreateCache('HomepageContainer.articlesMap'),
        mapData: mapArticleDetail,
        getUrlForId: (id: string) => resources.articleDetail(id).url,
        getUrlForMultiIds: (ids: string[]) => '/',
        getEmptyResource: () => {
          return null;
        },
      }
    );

    this.articleListCache = createResourceCache({
      url: resources.listArticles.url,
      fetchEnv,
      cache: appCache.getOrCreateCache('HomepageContainer.articles'),
      mapData: (data) => data.map(mapArticleOverview),
      getEmptyResource: () => {
        return ([]: any);
      },
    });

    this.campaignsMapCache = createResourceByIdCache<CampaignPayload, CampaignOverviewModel, null>({
      fetchEnv,
      cache: appCache.getOrCreateCache('HomepageContainer.campaignsMap'),
      mapData: mapCampaignDetail,
      getUrlForId: (id: string) => resources.campaignDetail(id).url,
      getUrlForMultiIds: (ids: string[]) => '/',
      getEmptyResource: () => {
        return null;
      },
    });

    this.campaignListCache = createResourceCache({
      url: resources.listCampaigns.url,
      fetchEnv,
      cache: appCache.getOrCreateCache('HomepageContainer.campaigns'),
      mapData: (data) => data.map(mapCampaignOverview),
      getEmptyResource: () => {
        return ([]: any);
      },
    });

    this.bestsellersCache = createResourceByIdCache({
      fetchEnv,
      cache: appCache.getOrCreateCache('HomepageContainer.bestsellers'),
      mapData: (data) => data.map(mapProduct),
      getUrlForId: (id: string) => resources.listBestsellers(id).url,
      getUrlForMultiIds: (ids: string[]) => '/',
      getEmptyResource: () => {
        return ([]: any);
      },
    });

    this.props = props;
  }

  //
  // Async tasks
  //

  async getArticles() {
    return await this.articleListCache.getResource();
  }

  async getCampaigns() {
    return await this.campaignListCache.getResource();
  }

  // async loadArticles() {
  //   await this.getArticles();
  // }

  async loadBestsellers(id: string) {
    if (!id) return [];
    return await this.bestsellersCache.getResource(id);
  }

  async loadLastVisitedProducts() {
    const { profile } = this.props;
    if (profile.id) {
      const result = await getJSON(
        resources.listLastVisited(profile.id, 'variant').url,
        this.props.fetchEnv
      );
      if (result.status === 'ok') {
        return result.data.map(this.props.mapProduct);
      }
    }
    return [];
  }

  async getArticleDetail(id: string): Promise<?ArticleDetailModel> {
    return await this.articlesMapCache.getResource(id);
  }

  async getCampaignDetail(id: string): Promise<?CampaignOverviewModel> {
    return await this.campaignsMapCache.getResource(id);
  }

  // selectArticles(): ArticleOverviewModel[] {
  //   return this.articleListCache.getResourceOrEmpty() || [];
  // }
}
