// @flow

import { createContext, useContext } from 'react';

const colors = {
  primary: '#141B4D',
  secondary: '#696D8D',
  borderHairline: 'rgba(227, 227, 227, 1)',
  neutralText: '#1B2631',
  warnText: '#F5A623',
  okText: '#2d7d2c',
  errorText: '#d00c00',
  shadowText: '#737373',
  errorBg: '#FAE5E8',
  positiveBg: '#eaf7db',
  infoBg: '#d7f2f5',
  borderGrey: '#EEE',
  primaryBg: '#FFFFFF',
  secondaryBg: '#F5F5F5',
  greyBg: '#e5e7eb',
  secondaryDark: '',
  new: '',
  linkDefault: '#B59633',
  linkHover: '#CFB53B',
};

const jadtoolsColors = {
  ...colors,
  primary: '#2f2a2c',
  secondary: '#6b6768',
};

const alfaboxColors = {
  ...colors,
  primary: '#6b6768',
  secondary: '#fc766a',
  secondaryDark: '#ff5c4e',
  new: '#32cd32',
};

const sizes = {
  maxPageWidth: 1430,
  minPageWidth: 350,

  headerHeight: 80,
  headerMaxWidth: 1280,
};

const breakpoints = {
  'extra-small': 0,
  small: 600,
  medium: 960,
  large: 1280,
  'extra-large': 1600,
};

const fontSizes = {
  smaller: '0.9rem',
  normal: '1rem',
  bigger: '1.1rem',
  large: '1.3rem',
};

// NotePrototype(simon): only for now exported + the colors, sizes nested
const createTheme = (colors) => ({
  colors,
  sizes,
  fontSizes,
  breakpoints,

  color: (val: ColorEnum) => colors[val],
  size: (val: SizeEnum) => sizes[val],
  fontSize: (val: FontSizeEnum) => fontSizes[val],
  breakpoint: (val: BreakpointEnum) => breakpoints[val],
});

export const rudorferTheme = createTheme(colors);
export const jadtoolsTheme = createTheme(jadtoolsColors);
export const alfaboxTheme = createTheme(alfaboxColors);

export type ThemeType = typeof rudorferTheme;
export type ColorEnum = $Keys<typeof colors>;
export type SizeEnum = $Keys<typeof sizes>;
export type FontSizeEnum = $Keys<typeof fontSizes>;
export type BreakpointEnum = $Keys<typeof breakpoints>;

const ThemeContext: React$Context<ThemeType> = createContext(rudorferTheme);

export const AppThemeProvider = ThemeContext.Provider;

export function useTheme(): ThemeType {
  return useContext(ThemeContext);
}

export const styledTheme = {
  color: (val: ColorEnum) => (props: { theme: ThemeType }) => props.theme.colors[val],
  size: (val: SizeEnum) => (props: { theme: ThemeType }) => props.theme.sizes[val],
  fontSize: (val: FontSizeEnum) => (props: { theme: ThemeType }) => props.theme.fontSizes[val],
  breakpoint: (val: BreakpointEnum) => (props: { theme: ThemeType }) =>
    props.theme.breakpoints[val],
};

export function propsTheme(fn: (theme: ThemeType) => number | string) {
  return (props: { theme: ThemeType }) => fn(props.theme);
}

export const __internalThemeDoNotUse = rudorferTheme;
