// @flow

import { Container } from '@whys/app/lib/state';
import { fetchJSON } from '@whys/fetch/lib/json';

import type { FetchEnvType } from '../types';
import type { LanguageCodeEnum } from '../../types/app';

const resources = {
  changeLanguage: {
    url: '/api/utils/language/change/',
    method: 'POST',
  },
};

// type AsyncBinaryResult = Promise<boolean>;

type LocalProps = {|
  initialLanguage: LanguageCodeEnum,
  defaultLanguage: LanguageCodeEnum,
  availableLanguages: Array<{| title: string, code: LanguageCodeEnum |}>,
  fetchEnv: FetchEnvType,
|};

type LocalState = {| language: LanguageCodeEnum |};

export class LanguageContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  constructor(props: LocalProps) {
    super();
    this.state = { language: props.initialLanguage };
    this.props = props;
  }

  async changeLanguage(
    language: LanguageCodeEnum,
    opts: {| fromUrl: string |}
  ): Promise<{| redirectUrl: ?string |}> {
    const { fetchEnv } = this.props;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.changeLanguage,
      data: {
        language_code: language,
        url: opts.fromUrl,
      },
    });
    if (result.status === 'ok') {
      const { data } = result;
      return { redirectUrl: data.redirect_url };
    }
    return { redirectUrl: null };
  }

  selectActiveLanguage(): LanguageCodeEnum {
    return this.state.language;
  }

  selectActiveLocale(): string {
    // Note: some API like

    const languageToLocale: { [LanguageCodeEnum]: string } = {
      cs: 'cs-CZ',
      de: 'de-DE',
      en: 'en-GB',
      fr: 'fr-FR',
      hu: 'hu-HU',
      pl: 'pl-PL',
      ru: 'ru-RU',
      sk: 'sk-SK',
    };
    const language = this.selectActiveLanguage();
    return languageToLocale[language] || languageToLocale['en'];
  }

  selectDefaultLanguage() {
    return this.props.defaultLanguage;
  }

  selectAvailableLanguages(): Array<{| title: string, code: LanguageCodeEnum |}> {
    return this.props.availableLanguages;
  }
}
