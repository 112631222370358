// @flow

import React from 'react';

import { ChangeListLayout } from '../rudorfer/ChangeListLayout';
import { useMediaDown } from '../hooks/responsive';

import { usePreferenceContainer } from '../app/state';
import type { LayoutVariantEnum } from '../appState/types';

type LocalProps = {||};

export function ChangeListLayoutView(props: LocalProps) {
  const [layoutVariant, changeLayoutVariant] = useListLayout();
  const isSm = useMediaDown('sm');
  // do not enable change it (see below in useListLayout; on small its alwasy grid)
  if (isSm) {
    return null;
  }
  return (
    <ChangeListLayout layoutVariant={layoutVariant} changeLayoutVariant={changeLayoutVariant} />
  );
}

export function useListLayout() {
  const preferenceContainer = usePreferenceContainer();
  const isSm = useMediaDown('sm');

  // force grid (basilly just card) on mobile
  const layoutVariant = isSm ? 'grid' : preferenceContainer.selectListLayoutVariant();
  const changeLayoutVariant = (variant: LayoutVariantEnum) => {
    preferenceContainer.alterListLayoutVariant(variant);
  };
  return [layoutVariant, changeLayoutVariant];
}
