// @flow

import { useEffect, useState } from 'react';

export function useDidChange(
  value: any,
  opts?: {| expireIn?: number, active?: boolean |}
): boolean {
  //
  // A helper hook to know whether a given value did change to show in UI.
  //

  //
  // Example usage: show animation, show tooltip or some message
  //

  //
  // Options:
  // - expireIn: Its resetted to "not changed" when expireIn elapse, defaults 0.
  // - active: Let you ignore changes under some condition, defaults true.
  //

  const [lastValue, setLastValue] = useState(value);

  const expireIn = (opts && opts.expireIn) || 0;
  const optsActive = opts && opts.active;
  const active = optsActive == null ? true : optsActive;

  useEffect(() => {
    if (!active) {
      return;
    }
    const timer = setTimeout(() => {
      setLastValue(value);
    }, expireIn);
    return () => {
      clearTimeout(timer);
    };
  }, [value, active]);

  return active && value !== lastValue;
}
