// @flow

import { Trans } from '@lingui/react';
import * as React from 'react';

import LoadingDots from '../appUI/LoadingDots';
import * as baseUI from '../baseUI';

type loadStateType = 'empty' | 'dots' | 'message';
type loaderType = (loadStateType) => boolean;

type LocalProps = {|
  text?: string,

  // with defaults
  shortDelay: number,
  longDelay: number,
  initialLoadState: loadStateType,
  finalLoadState: loadStateType,
  renderLoader: (loaderType) => React.Node,
|};

type LocalState = {|
  loadState: loadStateType,
|};

const defaultRenderLoader = (isPast: loaderType) => {
  if (isPast('dots')) {
    return <LoadingDots />;
  }
  if (isPast('message')) {
    return (
      <baseUI.FlexRow>
        <Trans>Loading</Trans> <LoadingDots />
      </baseUI.FlexRow>
    );
  }
  return <baseUI.Block height={16} />;
};

class LoadingInline extends React.Component<LocalProps, LocalState> {
  static defaultProps = {
    initialLoadState: 'empty',
    finalLoadState: 'message',
    shortDelay: 250,
    longDelay: 800,
    renderLoader: defaultRenderLoader,
  };

  delayTimeout: *;
  isPast: *;

  constructor(props: LocalProps) {
    super(props);

    this.isPast = this.isPast.bind(this);

    this.state = { loadState: props.initialLoadState };
  }

  componentDidMount() {
    const { shortDelay, initialLoadState, finalLoadState } = this.props;

    const setLongDelay = () => {
      if (finalLoadState === 'dots') {
        return;
      }

      const delayMs = this.props.longDelay - this.props.shortDelay;
      this.delayTimeout = setTimeout(() => {
        this.setState({ loadState: 'message' });
      }, delayMs);
    };

    const setShortDelay = () => {
      this.setState({ loadState: 'dots' }, setLongDelay);
    };

    if (initialLoadState === 'empty' && finalLoadState !== 'empty') {
      this.delayTimeout = setTimeout(setShortDelay, shortDelay);
    } else if (initialLoadState === 'dots') {
      const { longDelay } = this.props;
      this.delayTimeout = setTimeout(setLongDelay, longDelay);
    }
  }

  componentWillUnmount() {
    if (this.delayTimeout) {
      clearTimeout(this.delayTimeout);
    }
  }

  isPast(delayType: loadStateType): boolean {
    const { loadState } = this.state;
    if (loadState === 'empty') {
      return false;
    }
    return loadState === delayType;
  }

  render() {
    return this.props.renderLoader(this.isPast);
  }
}

export default LoadingInline;
