// @flow

import { Container } from '@whys/app/lib/state';
import { create as createResourceByIdCache } from '@whys/fetch/lib/resources/ResourceByIdCache';
import type { ResourceByIdCache } from '@whys/fetch';

import type { FetchEnvType, CacheType, CategoryDetailInfo } from './types';

import { mapCategoryDetailInfo, resources, mapFilters } from './models/category';
import type { CategoryFilter, CategoryFiltersArray } from '../appState/types';

type LocalState = {};

type LocalProps = {|
  fetchEnv: FetchEnvType,
  cache: CacheType<$FlowFixMe>,
  mapLabels: (any) => any,
  mapLabelsTest: (any) => any,
|};

// function nonNillOrDef<T>(value: ?T, defaultValue: T): T {
//   return value === null || value === undefined ? defaultValue : value;
// }

function getEmptyCategoryDetailInfo() {
  return {
    id: '-empty-',
    title: '',
    descriptionHtml: '',
    subs: [],
    images: [],
    unrollFilters: false,
  };
}

function getEmptyCategoryFilters() {
  return {
    id: '-empty-',
    categoryFilters: [],
  };
}

export class CategoryContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  categoryInfosCache: ResourceByIdCache<CategoryDetailInfo, CategoryDetailInfo>;
  filtersCache: ResourceByIdCache<CategoryFiltersArray, CategoryFiltersArray>;

  constructor(props: LocalProps) {
    super();

    this.props = props;
    const { cache, fetchEnv } = props;

    this.categoryInfosCache = createResourceByIdCache({
      fetchEnv,
      cache,
      getUrlForId: (id: string) => resources.categoryInfo(id).url,
      // NotePrototype(simon): ...
      getUrlForMultiIds: (ids: string[]) => '/',
      mapData: mapCategoryDetailInfo,
      getEmptyResource: getEmptyCategoryDetailInfo,
    });

    this.filtersCache = createResourceByIdCache({
      fetchEnv,
      cache,
      getUrlForId: (id: string) => resources.filters(id).url,
      getUrlForMultiIds: (id: string[]) => '/',
      mapData: mapFilters,
      getEmptyResource: getEmptyCategoryFilters,
    });

    this.state = {};
  }

  async loadCategoryDetailInfo(categoryId: string): Promise<void> {
    await this.categoryInfosCache.getResource(categoryId);
  }

  async getCategoryDetailInfo(categoryId: string): Promise<CategoryDetailInfo> {
    return await this.categoryInfosCache.getResource(categoryId);
  }

  async getFilters(categoryId: string): Promise<Array<CategoryFilter>> {
    const getRes = await this.filtersCache.getResource(categoryId);
    const filters = getRes.categoryFilters;
    return filters;
  }

  selectCategoryDetailInfo(categoryId: string): CategoryDetailInfo {
    // return this.categoryInfosCache.selectResource(categoryId);
    return getEmptyCategoryDetailInfo();
  }
}
