// @flow

import * as React from 'react';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { withStyles } from '@material-ui/core/styles';

import { Block, FlexRow } from '../baseUI';

const MaterialTextButton = withStyles((theme) => ({
  root: {
    minWidth: 28,
    // minHeight: 22,
    fontWeight: 600,
    fontSize: 22,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))((props: *) => {
  return <Button size="small" color="primary" variant="text" {...props} />;
});

// $FlowFixMe forwardRef
const StyledInput = React.forwardRef((props, ref) => {
  const { width, disabledInput, ...inputProps } = props;
  return (
    <Block
      props={inputProps}
      component="input"
      width={width}
      textAlign="center"
      padding="3px 6px"
      border={`1px solid #e0e0e0`}
      borderRadius={5}
      boxSizing="content-box"
      height={25}
      pointerEvents={disabledInput ? 'none' : 'all'}
    />
  );
});

function getDigitsCount(value: number) {
  const len = String(value).length;
  return len > 1 ? len : 2;
}

// function easingFn(val) {
//   return val < 0.5 ? 2 * val * val : -1 + (4 - 2 * val) * val;
// }

const INITIAL_REPEAT_INT = 500;

type LocalProps = {|
  value?: number,
  defaultValue?: number,
  onChange?: (value: number) => any,
  min: number,
  max: number,

  disabled: boolean,
  disabledInput?: boolean,
  step: number,
  onInputClick?: Function,
  isAlfabox?: boolean,
|};

type LocalState = { amount: number };

class AmountInput extends React.Component<LocalProps, LocalState> {
  /*

  Limitations:

  - no step (like 1.5) => suppports only int
  - the repeat has no easing function, its linear which is not as nice as the browser default

   */
  onChange: *;
  onClick: *;
  onKeyDown: *;
  onMouseDown: *;
  onMouseUp: *;
  onInputFocus: *;

  inputRef: * = React.createRef();
  selectionStart: ?number;
  selectionEnd: ?number;
  validateProps: *;

  repeatInterval: *;
  repeatTimeouts: Array<*>;
  clickRepeatTime: number;
  repeatInitialDone: boolean;

  constructor(props: LocalProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);

    this.clickRepeatTime = INITIAL_REPEAT_INT;
    this.repeatTimeouts = [];
    this.repeatInitialDone = false;

    const { defaultValue, min } = this.props;
    let amount = min;
    if (defaultValue && this.isValidAmount(defaultValue)) {
      amount = defaultValue;
    }
    this.state = { amount };

    if (process.env.NODE_ENV !== 'production') {
      this.validateProps(props);
    }
  }

  static getDerivedStateFromProps(props: LocalProps, state: LocalState) {
    return { amount: 'value' in props ? props.value : state.amount };
  }

  static defaultProps = {
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
    disabled: false,
  };

  componentWillUnmount() {
    this.clearAllIntervals();
  }

  componentDidUpdate(prevProps: Object, prevState: Object): void {
    const inputEl = this.inputRef && this.inputRef.current;
    if (inputEl) {
      const { selectionStart, selectionEnd } = this;
      if (selectionStart || selectionStart === 0) {
        inputEl.selectionStart = selectionStart;
      }
      if (selectionEnd || selectionEnd === 0) {
        inputEl.selectionEnd = selectionEnd;
      }
    }
  }

  clearAllIntervals() {
    this.repeatInterval && clearInterval(this.repeatInterval);
  }

  isValidAmount(value: *) {
    if (isNaN(value)) {
      return false;
    }
    const { min, max } = this.props;
    if (value < min) {
      return false;
    }
    if (value > max) {
      return false;
    }
    return true;
  }

  incrAmount() {
    const amount = this.getAmount();
    const newAmount = amount + this.props.step;
    if (this.isValidAmount(newAmount)) {
      this.changeAmount(newAmount);
    }
  }

  decrAmount() {
    const amount = this.getAmount();
    const newAmount = amount - this.props.step;
    if (this.isValidAmount(newAmount)) {
      this.changeAmount(newAmount);
    }
  }

  getAmount() {
    if (this.isControlled()) {
      return this.props.value || this.props.min;
    } else {
      return this.state.amount;
    }
  }

  changeAmount(newAmount: number) {
    this.saveSelection();
    if (this.isControlled()) {
      this.notifyChanged(newAmount);
    } else {
      this.setState({ amount: newAmount }, () => {
        this.notifyChanged(newAmount);
      });
    }
  }

  isControlled() {
    return !!this.props.value;
  }

  notifyChanged(amount: number) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(amount);
    }
  }

  onInputFocus(e: SyntheticInputEvent<HTMLInputElement>) {
    const element = e.target;
    element.focus();
    element.setSelectionRange(0, element.value.length);
  }

  onKeyDown(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    const eKey = e.key;
    if (eKey === 'ArrowUp') {
      // do not change cursor position
      e.preventDefault();
      this.incrAmount();
    } else if (eKey === 'ArrowDown') {
      // do not change cursor position
      e.preventDefault();
      this.decrAmount();
    }
  }

  onClick(e: SyntheticEvent<HTMLInputElement>) {
    const { name } = e.currentTarget;
    if (name === 'increment') {
      this.incrAmount();
    } else {
      this.decrAmount();
    }
  }

  onMouseDown(e: SyntheticMouseEvent<HTMLInputElement>) {
    if (this.repeatInterval) {
      return;
    }

    const { name } = e.currentTarget;

    const doRepeat = () => {
      if (name === 'increment') {
        this.incrAmount();
      } else {
        this.decrAmount();
      }
      if (this.clickRepeatTime > 10) {
        this.clickRepeatTime = Math.floor(this.clickRepeatTime * 0.6);
      }
    };

    this.repeatInterval = setInterval(() => {
      if (this.repeatInitialDone) {
        // NotePrototype(simon): clean the timeout too?
        this.repeatTimeouts.push(setTimeout(doRepeat, this.clickRepeatTime));
      } else {
        const timer = setTimeout(() => {
          doRepeat();
          this.repeatInitialDone = true;
        }, INITIAL_REPEAT_INT);

        this.repeatTimeouts.push(timer);
      }
    }, 80);
  }

  onMouseUp(e: SyntheticMouseEvent<HTMLInputElement>) {
    if (this.repeatInterval) {
      clearInterval(this.repeatInterval);
      this.repeatInterval = undefined;
    }

    if (this.repeatTimeouts) {
      this.repeatTimeouts.forEach(clearTimeout);
      this.repeatTimeouts = [];
    }

    this.clickRepeatTime = INITIAL_REPEAT_INT;
  }

  onChange(e: *) {
    const value = e.target.value;
    // Note: this handles empty string on backspace => coerces to 0
    const amount = parseInt(value || this.props.min, 10);
    if (!this.isValidAmount(amount)) {
      const { max } = this.props;
      if (amount > max) {
        this.changeAmount(max);
        return;
      }
      return;
    }
    this.changeAmount(amount);
  }

  saveSelection() {
    const inputEl = this.inputRef && this.inputRef.current;
    if (inputEl) {
      const { selectionStart, selectionEnd } = inputEl;
      this.selectionStart = selectionStart;
      this.selectionEnd = selectionEnd;
    }
  }

  render() {
    const { disabled, disabledInput } = this.props;
    const { isAlfabox } = this.props;

    const inputValue = this.getAmount();
    const width = getDigitsCount(inputValue) * 10;
    return (
      <FlexRow alignItems="center">
        {!isAlfabox && (
          <MaterialTextButton
            disabled={disabled}
            onMouseUp={this.onMouseUp}
            onMouseOut={this.onMouseUp}
            onMouseDown={this.onMouseDown}
            onClick={this.onClick}
            name="decrement"
          >
            <RemoveIcon />
          </MaterialTextButton>
        )}
        <div
          onClick={() => {
            !disabled && disabledInput && this.props.onInputClick && this.props.onInputClick();
          }}
        >
          <StyledInput
            ref={this.inputRef}
            type="text"
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onFocus={this.onInputFocus}
            disabledInput={disabledInput}
            disabled={disabled}
            value={inputValue}
            width={width}
            style={{ border: isAlfabox && '1px solid #fc766a', padding: isAlfabox && '0 0.75rem' }}
          />
        </div>
        {!isAlfabox && (
          <MaterialTextButton
            disabled={disabled}
            onMouseUp={this.onMouseUp}
            onMouseOut={this.onMouseUp}
            onMouseDown={this.onMouseDown}
            onClick={this.onClick}
            name="increment"
          >
            <AddIcon />
          </MaterialTextButton>
        )}
      </FlexRow>
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  AmountInput.prototype.validateProps = (props) => {
    if ('value' in props) {
      if (!('onChange' in props)) {
        console.warn('Prop `value` is provided without `onChange` handler.');
      }
    }
  };
}

export default AmountInput;
