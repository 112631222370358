// @flow

import React from 'react';

import { useLoginContainer } from '../app/state';
import { loginStateLocalStorageKey } from '../app/constants';

export function GlobalApp(props: {| children: any |}) {
  const loginContainer = useLoginContainer();

  React.useEffect(() => {
    window.addEventListener('storage', (e) => {
      if (e.key === loginStateLocalStorageKey) {
        if (e.newValue) {
          loginContainer.forceLogin();
        } else {
          loginContainer.forceLogout();
        }
      }
    });
  }, []);

  return props.children;
}
