// @flow

import Cookies from 'js-cookie';

import { renderClient } from '@whys/app/lib/client';
import { createEnv } from '@whys/fetch/lib/browser-env';
// @todo: consider deleting this sentry logger. It outdated, and doesnt work anymore.
import * as sentry from '@whys/app-plugin-sentry-browser';
// local
import AppRoot from './AppRoot/AppRoot';
import { containerConfig } from './app/state';
import routes from './app/routes';
import plugins from './app/plugins';
import { sentryDSN, environment } from './env';
import { createEmitter } from './notifications/emitter';
import { getJSON } from '@whys/fetch/lib/json';
import { gtag } from './AppRoot/gtm';
import TagManager from 'react-gtm-module';
import {
  isFacebookPixelInInitialData,
  initFacebookPixel
} from './pkg.facebookPixel/hooks';

import type { AppContainerContext, AppPluginProps, AppPluginContext } from './types/app';

import './polyfill/client';
import idx from 'idx';
import setupSentry from './pkg.sentry/sentrySetup';

function createClientFetchEnv(options: { csrftoken: string }) {
  return createEnv({
    onError: (e: Error) => {
      // const { sentry } = pluginProps;
      // sentry.logger.logError(e);
      console.error(e);
    },
    timeout: 60000, // temporary: 60s
    init: {
      // (cors is default)
      mode: 'cors',
      headers: {
        'X-CSRFToken': options.csrftoken,
      },
    },
  });
}

async function createPluginContext({
  djreactState,
  appCache,
  csrftoken,
  initialData,
}): Promise<AppPluginContext> {
  if (initialData) return { gtm: { gtmId: initialData.marketing.gtm_id || '' } };
  const fetchEnv = createClientFetchEnv({ csrftoken });
  const result = await getJSON('/api/utils/initial-data/', fetchEnv);
  if (result.status === 'ok') {
    const { data } = result;
    return { gtm: { gtmId: data.marketing.gtm_id || '' } };
  }
  return {};
}

async function createContainerContext(opts): Promise<AppContainerContext> {
  const { csrftoken, djreactState, initialData, appCache } = opts;
  const pluginProps: AppPluginProps = opts.pluginProps;
  const tagManagerArgs = {
    gtmId: idx(initialData, (_) => _.marketing.gtm_id) || '',
    dataLayer: window.dataLayer || [],
  };

  if (tagManagerArgs.gtmId) TagManager.initialize(tagManagerArgs);

  if(tagManagerArgs.gtmId) {
    gtag('js', new Date());
    gtag('config', tagManagerArgs.gtmId, { 'debug_mode':true });

    gtag('consent', 'default', {
      ad_user_data: Cookies.get('cookie_consent_remarketing') ? 'granted' : 'denied',
      ad_personalization: Cookies.get('cookie_consent_remarketing') ? 'granted' : 'denied',

      ad_storage: Cookies.get('cookie_consent_remarketing') ? 'granted' : 'denied',
      analytics_storage: Cookies.get('cookie_consent_analytics') ? 'granted' : 'denied',
      personalization_storage: Cookies.get('cookie_consent_remarketing') ? 'granted' : 'denied',
      functionality_storage: Cookies.get('cookie_consent_essential') ? 'granted' : 'denied',
      security_storage: Cookies.get('cookie_consent_essential') ? 'granted' : 'denied',
    })

    tagManagerArgs.dataLayer.push({
      event: 'default_consent',

      ad_user_data: Cookies.get('cookie_consent_remarketing') ? 'granted' : 'denied',
      ad_personalization: Cookies.get('cookie_consent_remarketing') ? 'granted' : 'denied',

      ad_storage: Cookies.get('cookie_consent_remarketing') ? 'granted' : 'denied',
      analytics_storage: Cookies.get('cookie_consent_analytics') ? 'granted' : 'denied',
      personalization_storage: Cookies.get('cookie_consent_remarketing') ? 'granted' : 'denied',
      functionality_storage: Cookies.get('cookie_consent_essential') ? 'granted' : 'denied',
      security_storage: Cookies.get('cookie_consent_essential') ? 'granted' : 'denied',
    });

    if (window.clarity) {
      if (typeof window.clarity === 'function' && Cookies.get('cookie_consent_remarketing')) {
        window.clarity('consent');
      }
    }
  }

  if (tagManagerArgs.gtmId){
    tagManagerArgs.dataLayer.push({
      event: 'default_consent',

      ad_user_data: Cookies.get('cookie_consent_remarketing') ? 'granted' : 'denied',
      ad_personalization: Cookies.get('cookie_consent_remarketing') ? 'granted' : 'denied',

      ad_storage: Cookies.get('cookie_consent_remarketing') ? 'granted' : 'denied',
      analytics_storage: Cookies.get('cookie_consent_analytics') ? 'granted' : 'denied',
      personalization_storage: Cookies.get('cookie_consent_remarketing') ? 'granted' : 'denied',
      functionality_storage: Cookies.get('cookie_consent_essential') ? 'granted' : 'denied',
      security_storage: Cookies.get('cookie_consent_essential') ? 'granted' : 'denied',
    });
  }

  if(initialData && isFacebookPixelInInitialData(initialData)){
    initFacebookPixel(initialData)
  }

  let language = djreactState.language;
  // NotePrototype(simon): catalogs should be resolved as state, not here
  let languageCatalog;
  try {
    // $FlowFixMe
    languageCatalog = await import(`../locale/${language}/messages.js`);
  } catch (e) {
    console.error(e);
    // $FlowFixMe
    languageCatalog = await import(`../locale/en/messages.js`);
    language = 'en';
  }

  function onError(e) {
    pluginProps.sentry.logger.logError(e);
  }

  const eventEmitter = createEmitter();

  setupSentry();

  const fetchEnv = createEnv({
    onError,
    // NotePrototype: what? 30s? ok...
    timeout: 60000, // temporary: 60s
    init: {
      headers: {
        'X-CSRFToken': csrftoken,
      },
    },
    hooks: {
      afterResponse: [
        (response) => {
          if (response && response.status === 403) {
            // NotePrototype(simon): we can read csrftoken from document.cookie and
            // retry the request. But 1) I'm only 99 % sure its safe 2) globally-enabled
            // retry is not implemented.
            const notification = { id: '403', read: false };
            eventEmitter.emit('notifications', notification);
          }
        },
      ],
    },
  });

  const catalogs = { [language]: languageCatalog };
  return {
    catalogs,
    language,
    djreactState,
    fetchEnv,
    appCache,
    pluginProps,
    initialData,
    eventEmitter,
  };
}

// $FlowFixMe
renderClient({
  routes,
  plugins: {
    ...plugins,
    // @todo: consider deleting this sentry logger. It outdated, and doesnt work anymore.
    sentry: sentry.setup({ dsn: sentryDSN, environment }),
  },
  AppRoot,
  containerConfig,
  createContainerContext,
  createPluginContext,
});

// $FlowFixMe
if (module.hot) {
  // $FlowFixMe
  module.hot.accept();
}
