// @flow

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import { useTheme } from '../app/theme';

type LocalProps = {|
  // color?: string,
|};

export function PackageIcon(props: LocalProps) {
  const theme = useTheme();
  const color = theme.color('primary');

  return (
    <SvgIcon {...props}>
      <g fill="#FFF" stroke={color} strokeLinejoin="round" strokeMiterlimit="10">
        <polygon
          strokeLinecap="square"
          points="10.983,3.277 20.066,6.194 20.066,15.234 10.983,15.234"
        />
        <polygon
          strokeLinecap="square"
          points="3.441,7.194 10.983,3.277 10.983,19.652 3.442,17.15"
        />
        <path d="M3.437,7.485l-0.18,9 c0,0-0.107,0.791,0.685,1.084c0.792,0.291,8.833,3,8.833,3s0.918,0.459,0.959-0.209c0.041-0.666,0-9.875,0-9.875L3.437,7.485z" />
        <polygon points="3.127,7.485 13.627,10.318 12.166,15.858 1.228,12.486" />
        <path
          strokeLinecap="square"
          d="M20.023,6.235 c-0.166,0.083-6.271,4-6.271,4v10.488l6.271-5.404V6.235z"
        />
        <polygon
          strokeWidth="0.832"
          points="20.232,6.027 13.357,10.604 15.74,15.234 22.914,10.031"
        />
      </g>
    </SvgIcon>
  );
}
