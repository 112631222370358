// @flow

import * as React from 'react';
import { Trans } from '@lingui/react';
import Helmet from 'react-helmet';

import * as baseUI from '../baseUI';
import { Block } from '../baseUI';
import { VerticalSpace } from '../appUI';
import styled from '@emotion/styled';
import { useMediaQuery } from '../hooks/responsive';
import { themeMUI, useLanguageContainer } from '../app/state';
import { TextLink, ExternalLink, StaticPageLink } from '../app/link';
import * as reset from '../styled/reset';
import MailIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import MobileIcon from '@material-ui/icons/Smartphone';
import FaxIcon from '@material-ui/icons/Print';
import ClockIcon from '@material-ui/icons/Schedule';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import PinterestIcon from '@material-ui/icons/Pinterest';
// import { useLingui } from '../app/lingui';
import { useGlobalContainer } from '../app/use';
import { useTheme, styledTheme } from '../app/theme';

import Contact from './alfabox/assets/contact.svg';
import ContactSmall from './alfabox/assets/contactSmall.svg';
import useClarityTrackingCode from '../use/useClarityTrackingCode';

type LocalProps = {||};

const FooterBlock = styled(Block)`
  margin-right: 20px;
  margin-bottom: 30px;
`;

const FooterHeader = styled.h4`
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 400;
`;

const FooterUnorderedList = styled.ul`
  ${reset.UnorderedList};
`;

const FooterListItem = styled.li`
  ${reset.ListItem};
  margin-bottom: 5px;
`;

const ContactItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const ContactElement = styled.div`
  width: 100%;
  height: ${(props) => (props.isExtraSmall ? '15vh' : '10vh')};
  margin: 1.5rem 0;
  & > img {
    display: block;
    margin: auto;
    width: ${(props) => (props.isSmall ? '95%' : '80%')};
    height: 100%;
  }
`;

const CookiesButton = styled.span`
  color: ${({ golden }) =>
    golden ? styledTheme.color('linkDefault') : styledTheme.color('primary')};

  &:hover {
    cursor: pointer;
    color: ${({ golden }) =>
      golden ? styledTheme.color('linkHover') : styledTheme.color('secondary')};
  }
`;

function FormatPhone(props: {| value: string |}) {
  return <ExternalLink href={`tel:${props.value}`}>{props.value}</ExternalLink>;
}

function FormatEmail(props: {| value: string |}) {
  return <ExternalLink href={`mailto:${props.value}`}>{props.value}</ExternalLink>;
}

function FormatPhoneNumbers(props: {| value: string[] |}) {
  const { value } = props;
  if (value.length === 1) {
    return <FormatPhone value={value[0]} />;
  }
  if (value.length) {
    const finalIdx = value.length - 1;
    return (
      <span>
        {value.map((v, idx) => (
          <React.Fragment key={v}>
            <FormatPhone value={v} />
            {idx === finalIdx ? '' : ', '}
          </React.Fragment>
        ))}
      </span>
    );
  }
  return '';
}
const FooterLinkIcon = styled.a`
  margin-right: 15px;
  color: inherit;
  &:visited {
    color: inherit;
  }
  &:hover {
    color: ${styledTheme.color('secondary')};
  }
`;

type FooterProps = {|
  isExtraSmall: boolean,
|};

function RudorferFooter(props: FooterProps) {
  const globalContainer = useGlobalContainer();
  const languageContainer = useLanguageContainer();
  const { footerBlocks, contactBlock } = globalContainer.selectFooterInfo();

  const facebookLink = globalContainer.isSpecificSite('rudorfer.eu')
    ? 'https://www.facebook.com/Rudorfer-Pack-EU-Wholesale-of-jewelry-boxes-and-displays-109266048197954/'
    : globalContainer.isSpecificSite('rudorfer.cz')
    ? 'https://www.facebook.com/Rudorfer-Pack-CZ-Potřeby-pro-zlatn%C3%ADky-a-klenotn%C3%ADky-102476252220060/'
    : globalContainer.isSpecificSite('rudorfer.pl')
    ? 'https://www.facebook.com/Rudorfer-PL-Hurtownia-opakowań-i-ekspozytorów-dla-jubilerów-103632292100592'
    : globalContainer.isSpecificSite('rudorfer.sk')
    ? 'https://www.facebook.com/RudorferPackSK'
    : globalContainer.isSpecificSite('rudorfer.de') || globalContainer.isSpecificSite('rudorfer.at')
    ? 'https://www.facebook.com/Rudorfer-DE-Großhandel-für-Schmuckverpackungen-Displays-110966614690311/'
    : globalContainer.isSpecificSite('rudorfer.hu')
    ? 'https://www.facebook.com/Rudorfer-Pack-HU-Ékszerdoboz-és-installáció-102711712194224'
    : globalContainer.isSpecificSite('rudorfer.fr')
    ? 'https://www.facebook.com/Rudorfer-Pack-FR-Écrins-et-accessoires-pour-joaillerie-et-orfèvrerie-105488378582775'
    : globalContainer.isSpecificSite('rudorfer.be')
    ? languageContainer.selectActiveLanguage() === 'de'
      ? 'https://www.facebook.com/Rudorfer-DE-Großhandel-für-Schmuckverpackungen-Displays-110966614690311'
      : languageContainer.selectActiveLanguage() === 'fr'
      ? 'https://www.facebook.com/Rudorfer-Pack-FR-Écrins-et-accessoires-pour-joaillerie-et-orfèvrerie-105488378582775/'
      : 'https://www.facebook.com/Rudorfer-Pack-EU-Wholesale-of-jewelry-boxes-and-displays-109266048197954/'
    : 'https://www.facebook.com/Rudorfer-Pack-EU-Wholesale-of-jewelry-boxes-and-displays-109266048197954/';

  const instagramLink = globalContainer.isSpecificSite('rudorfer.eu')
    ? 'https://www.instagram.com/rudorferpack/'
    : globalContainer.isSpecificSite('rudorfer.cz')
    ? 'https://www.instagram.com/rudorfercz/'
    : globalContainer.isSpecificSite('rudorfer.pl')
    ? 'https://www.instagram.com/rudorferpl/'
    : globalContainer.isSpecificSite('rudorfer.sk')
    ? 'https://www.instagram.com/rudorferpacksk/'
    : globalContainer.isSpecificSite('rudorfer.de') || globalContainer.isSpecificSite('rudorfer.at')
    ? 'https://www.instagram.com/rudorferde/'
    : globalContainer.isSpecificSite('rudorfer.hu')
    ? 'https://www.instagram.com/rudorferpackhu/'
    : globalContainer.isSpecificSite('rudorfer.fr')
    ? 'https://www.instagram.com/rudorferpackfr/'
    : globalContainer.isSpecificSite('rudorfer.be')
    ? languageContainer.selectActiveLanguage() === 'de'
      ? 'https://www.instagram.com/rudorferde/'
      : languageContainer.selectActiveLanguage() === 'fr'
      ? 'https://www.instagram.com/rudorferpackfr/'
      : 'https://www.instagram.com/rudorferpack/'
    : 'https://www.instagram.com/rudorferpack/';

  const pinterestLink = globalContainer.isSpecificSite('rudorfer.eu')
    ? 'https://pinterest.com/RudorferPackEU/'
    : globalContainer.isSpecificSite('rudorfer.cz')
    ? 'https://pinterest.com/RudorferPackCZ/'
    : globalContainer.isSpecificSite('rudorfer.pl')
    ? 'https://pinterest.com/RudorferPackPL/'
    : globalContainer.isSpecificSite('rudorfer.sk')
    ? 'https://pinterest.com/RudorferPackSK/'
    : globalContainer.isSpecificSite('rudorfer.de') || globalContainer.isSpecificSite('rudorfer.at')
    ? 'https://pinterest.com/RudorferDE/'
    : globalContainer.isSpecificSite('rudorfer.hu')
    ? 'https://pinterest.com/RudorferPackHU/'
    : globalContainer.isSpecificSite('rudorfer.fr')
    ? 'https://pinterest.fr/RudorferPackFR/'
    : globalContainer.isSpecificSite('rudorfer.be')
    ? languageContainer.selectActiveLanguage() === 'de'
      ? 'https://pl.pinterest.com/RudorferDE/'
      : languageContainer.selectActiveLanguage() === 'fr'
      ? 'https://www.pinterest.fr/RudorferPackFR/'
      : 'https://pinterest.com/RudorferPackEU/'
    : 'https://pinterest.com/RudorferPackEU/';

  const renderContactInfoRudorfer = () => {
    const { namedContacts } = contactBlock;
    return (
      <FooterUnorderedList>
        <ContactItem>
          <strong>{contactBlock.contactTitle}</strong>
        </ContactItem>
        {contactBlock.contactLines.map((line, idx) => {
          return <ContactItem key={idx}>{line}</ContactItem>;
        })}
        <VerticalSpace />

        {/* Note to future devs: this is starting to be confusing, consider refactor with component registers */}
        {/* e.g. each shop (cz/hu/sk...) can have its own components - custom Footer/ProductCard etc */}
        {/* See shop-starter - already implemented */}
        {globalContainer.isSpecificSite('rudorfer.hu') ? (
          <>
            <FooterHeader>Budapesti ügyfélszolgálat</FooterHeader>
            <ContactItem>Levelezési cím: 1142 Budapest, Erzsébet királyné útja 96/b</ContactItem>
          </>
        ) : globalContainer.isSpecificSite('rudorfer.pl') ? (
          <>
            <FooterHeader>Biuro Obsługi Klienta</FooterHeader>
            <ContactItem>ul. Mostowa 4, 43-400 Cieszyn</ContactItem>
          </>
        ) : null}

        {namedContacts.workingHours ? (
          <ContactItem>
            <ClockIcon /> <Block marginLeft={10}>{namedContacts.workingHours}</Block>
          </ContactItem>
        ) : null}
        {namedContacts.email ? (
          <ContactItem>
            <MailIcon />
            <Block marginLeft={10}>
              <Trans>E-mail</Trans>:&nbsp;
              <FormatEmail value={namedContacts.email} />
            </Block>
          </ContactItem>
        ) : null}
        {namedContacts.phoneNumbers.length ? (
          <ContactItem>
            <PhoneIcon />
            <Block marginLeft={10}>
              <Trans>Phone</Trans>:&nbsp;
              <FormatPhoneNumbers value={namedContacts.phoneNumbers} />
            </Block>
          </ContactItem>
        ) : null}
        {namedContacts.mobileNumber ? (
          <ContactItem>
            <MobileIcon />
            <Block marginLeft={10}>
              <Trans>Mobile</Trans>:&nbsp;
              <FormatPhone value={namedContacts.mobileNumber} />
            </Block>
          </ContactItem>
        ) : null}
        {namedContacts.fax ? (
          <ContactItem>
            <FaxIcon />
            <Block marginLeft={10}>
              <Trans>Fax</Trans>:&nbsp;
              <FormatPhone value={namedContacts.fax} />
            </Block>
          </ContactItem>
        ) : null}
        {!globalContainer.isSpecificSite('rudorfer.dealer') && (
          <>
            <FooterLinkIcon title="Facebook" href={facebookLink}>
              <FacebookIcon />
            </FooterLinkIcon>
            <FooterLinkIcon title="Pinterest" href={pinterestLink}>
              <PinterestIcon />
            </FooterLinkIcon>
            <FooterLinkIcon title="Instagram" href={instagramLink}>
              <InstagramIcon />
            </FooterLinkIcon>
          </>
        )}
      </FooterUnorderedList>
    );
  };

  const renderLinksRudorfer = (block) => {
    return (
      <>
        {block.links.map((link, index) => (
          <FooterListItem key={index}>
            <TextLink to={link.url}>{link.title}</TextLink>
          </FooterListItem>
        ))}
        {block.identifier === 'information' && (
          <CookiesButton onClick={() => globalContainer.setOpenCookiesPopup(true)}>
            <Trans>Cookies settings</Trans>
          </CookiesButton>
        )}
      </>
    );
  };

  const renderCustomerServiceTitle = (block) => {
    if (globalContainer.isSpecificSite('rudorfer.pl') && block.identifier === 'customer-service') {
      if (languageContainer.selectActiveLanguage() === 'pl') {
        return 'Sprzedawca';
      } else {
        return block.title;
      }
    } else {
      return block.title;
    }
  };

  return footerBlocks.map((block) => (
    <FooterBlock key={block.identifier} width={props.isExtraSmall && '100%'}>
      <FooterHeader>{renderCustomerServiceTitle(block)}</FooterHeader>
      <FooterUnorderedList>
        {block.identifier === 'customer-service'
          ? renderContactInfoRudorfer()
          : renderLinksRudorfer(block)}
      </FooterUnorderedList>
    </FooterBlock>
  ));
}

const FooterInfoHeader = styled.span`
  display: block;
  font-size: ${(props) => (props.isSm ? '1.15rem' : '1.55rem')};
  margin-bottom: 1.2rem;
  & a {
    color: #000;
  }
`;

const FooterCustomerServiceCol = styled.div`
  font-size: ${(props) => (props.isSm ? '1.05rem' : '1.45rem')};
`;

const TextUppercase = styled.div`
  text-transform: uppercase;
`;

const PaddingTop = styled.div`
  padding-top: 0.5rem;
`;

function AlfaboxFooter(props: FooterProps) {
  const { isExtraSmall } = props;
  const { footerBlocks, contactBlock } = useGlobalContainer().selectFooterInfo();

  const renderContactInfoAlfabox = () => {
    return (
      <FooterUnorderedList>
        <FooterCustomerServiceCol isSm={isExtraSmall}>
          <ContactItem>{contactBlock.contactTitle}</ContactItem>
          {contactBlock.contactLines.map((line, idx) => {
            return (
              <React.Fragment key={idx}>
                <ContactItem>{line}</ContactItem>
              </React.Fragment>
            );
          })}
        </FooterCustomerServiceCol>

        <VerticalSpace />

        <FooterLinkIcon title="Pinterest" href="https://pl.pinterest.com/">
          <PinterestIcon />
        </FooterLinkIcon>
        <FooterLinkIcon title="Instagram" href="https://www.instagram.com/">
          <InstagramIcon />
        </FooterLinkIcon>
      </FooterUnorderedList>
    );
  };

  const renderLinksAlfabox = (block) => {
    return (
      <>
        <FooterListItem>
          <FooterInfoHeader isSm={isExtraSmall}>
            <TextUppercase>
              <StaticPageLink name="privacy-policy" />
            </TextUppercase>
          </FooterInfoHeader>
        </FooterListItem>
        <FooterListItem>
          <FooterInfoHeader isSm={isExtraSmall}>
            <TextUppercase>
              <StaticPageLink name="terms-and-conditions" />
            </TextUppercase>
          </FooterInfoHeader>
        </FooterListItem>
      </>
    );
  };

  return footerBlocks.map((block, i) => (
    <React.Fragment key={i}>
      {block.identifier === 'customer-service' || block.identifier === 'information' ? (
        <FooterBlock
          key={block.identifier}
          width={isExtraSmall && '100%'}
          style={{ marginRight: '0' }}
        >
          <React.Fragment>
            {block.identifier === 'customer-service' ? (
              <TextUppercase>
                <FooterHeader>
                  <Trans>{block.title}</Trans>
                </FooterHeader>
              </TextUppercase>
            ) : null}
          </React.Fragment>

          <PaddingTop>
            <FooterUnorderedList>
              {block.identifier === 'customer-service' && renderContactInfoAlfabox()}
              {block.identifier === 'information' && renderLinksAlfabox(block)}
            </FooterUnorderedList>
          </PaddingTop>
        </FooterBlock>
      ) : null}
    </React.Fragment>
  ));
}

const ResponsiveFooter = styled.div`
  display: flex;
  width: ${(props) => props.width};
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: auto;
  flex-wrap: wrap;
  color: #000;
`;

function AppFooter(props: LocalProps) {
  const isExtraSmall = useMediaQuery(themeMUI.breakpoints.down('xs'));
  const isSmall = useMediaQuery(themeMUI.breakpoints.down('sm'));
  const globalContainer = useGlobalContainer();
  const theme = useTheme();
  const clarityTrackingCode = useClarityTrackingCode()

  const incomakerSoftwaremodule = globalContainer.getSoftwaremodule('incomaker');

  return (
    <baseUI.FlexRow
      justifyContent="space-between"
      width="100%"
      flexWrap="wrap"
      color={theme.color('primary')}
    >
      {globalContainer.isSpecificProject('alfabox') ? (
        <ResponsiveFooter width="100%">
          <ContactElement bg={Contact} isSmall={isSmall} isExtraSmall={isExtraSmall}>
            {isExtraSmall ? (
              <img src={ContactSmall} alt="contact graphic" />
            ) : (
              <img src={Contact} alt="contact graphic" />
            )}
          </ContactElement>
          <AlfaboxFooter isExtraSmall={isExtraSmall} />
        </ResponsiveFooter>
      ) : (
        <RudorferFooter isExtraSmall={isExtraSmall} />
      )}

      <Helmet>
        {clarityTrackingCode}
      </Helmet>
      {globalContainer.isSoftwaremoduleActive('incomaker') && incomakerSoftwaremodule && (
        <Helmet>
          <script
            type="text/javascript"
            src={`https://dg.incomaker.com/tracking/resources/js/INlib.js?accountUuid=${incomakerSoftwaremodule.data['accountId']}&pluginUuid=${incomakerSoftwaremodule.data['pluginId']}`}
          ></script>
        </Helmet>
      )}
    </baseUI.FlexRow>
  );
}

export default AppFooter;
