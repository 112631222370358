// @flow

import { useRef, useEffect, useState } from 'react';

export function useTimeout(ms: number): boolean {
  // <= timeout elapsed

  const [ready, setReady] = useState(false);

  // NoteReview(simon): no need to depend on ms, can be static

  useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true);
    }, ms);

    return () => {
      clearTimeout(timer);
    };
  }, [ms]);

  return ready;
}

export function useIsDebounced<T>(
  val: T,
  opts: {| timeout: number, initiallyReady?: boolean |}
): boolean {
  const { timeout } = opts;
  const initiallyReady = opts.initiallyReady == null ? false : opts.initiallyReady;
  const timerRef = useRef();
  const [ready, setReady] = useState(initiallyReady);
  const [lastValue, setLastValue] = useState(val);

  useEffect(() => {
    if (val === lastValue) {
      return;
    }
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setReady(true);
      setLastValue(val);
    }, timeout);
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, [val, timerRef, lastValue]);

  return ready;
}

type TimersManager = {|
  set: ({| timeout: number, id: string |}, fn: Function) => void,
  // basically debounce
  reset: ({| timeout: number, id: string |}, fn: Function) => void,
  clearAll: () => void,
|};

export function useTimers(): TimersManager {
  let timersMapRef = useRef();
  if (!timersMapRef.current) {
    timersMapRef.current = new Map<string, TimeoutID[]>();
  }

  useEffect(() => {
    manager.clearAll();
  }, []);

  const timersMap = timersMapRef.current;
  if (!timersMap) {
    throw new Error('Error');
  }

  const manager = {
    set: (opts, fn) => {
      const { id, timeout } = opts;
      const curTimeouts = timersMap.get(id);
      if (curTimeouts) {
        timersMap.set(id, [...curTimeouts, setTimeout(fn, timeout)]);
      } else {
        timersMap.set(id, [setTimeout(fn, timeout)]);
      }
    },
    reset: (opts, fn) => {
      const { id, timeout } = opts;
      const curTimeouts = timersMap.get(id);
      if (curTimeouts) {
        curTimeouts.forEach(clearTimeout);
      }
      timersMap.set(id, [setTimeout(fn, timeout)]);
    },
    clearAll: () => {
      timersMap.forEach((value) => {
        value.forEach(clearTimeout);
      });
    },
  };

  return manager;
}
