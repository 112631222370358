// @flow

export function coerceNum(val: any, defaultVal: number): number {
  const asNumber = Number(val);
  if (Number.isNaN(asNumber)) {
    return defaultVal;
  }
  return asNumber;
}

export function coerceInt(val: any, defaultVal: number): number {
  const valAsNum = (() => {
    const asNumber = Number(val);
    if (Number.isNaN(asNumber)) {
      return defaultVal;
    }
    return asNumber;
  })();

  return Number(valAsNum.toFixed(0));
}

export function coerceStr(val: any): string {
  if (val === null || val === undefined || Number.isNaN(val)) {
    return '';
  }
  return String(val);
}

export function sortStrings(list: string[]): string[] {
  const sorted = [...list];
  sorted.sort();
  return sorted;
}

function isDateValid(d: Date) {
  // > d2
  // Invalid Date
  // > d2.getTime()
  // NaN
  // >
  if (Number.isNaN(d.getTime())) {
    return false;
  }
  return true;
}

export function parseDateOrNull(val: ?string): Date | null {
  if (!val) {
    return null;
  }
  const parsed = new Date(val);
  if (isDateValid(parsed)) {
    return parsed;
  }
  return null;
}

export function parseDate(val: string): Date {
  return new Date(val);
}

export function nonNil<T>(val: ?T, defaultValue: T): T {
  if (val == null) {
    return defaultValue;
  }
  return val;
}
