// @flow

import { RegisterContainer } from '../containers/RegisterContainer';

//
// Other file
//

import type { AppContainerType } from '../types/app';

const defaultOptions = { singleton: false };

function createResolver(fn, options = defaultOptions) {
  fn.options = { ...defaultOptions, ...options };

  return fn;
}

//
// This file
//

async function createRegisterContainer(self: AppContainerType) {
  const { fetchEnv } = self.context;
  return new RegisterContainer({ fetchEnv });
}

export default createResolver(createRegisterContainer);
