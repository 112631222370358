import React from 'react';
import ImageGallery from './ImageGallery';
import { RudorferGalleryWrapper } from './ImageGalleryStyles';

// ! keep for DEBUG
import { images } from '../exampleHelpers/factories';

type LocalProps = {|
  items: Array<ImageModel>,
  showThumbnails?: boolean,
  showNav?: boolean,
  disableArrowKeys?: boolean,
  onClick?: Function,
  thumbnailPosition?: string,
  additionalClass?: string,
  enableFullscreen?: boolean,
|};

function RudorferGallery(props: LocalProps) {
  return (
    <RudorferGalleryWrapper>
      <ImageGallery {...props} />
    </RudorferGalleryWrapper>
  );
}

export default RudorferGallery;
