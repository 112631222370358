// @flow

import * as React from 'react';
import { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl/FormControl';
// import Typography from '@material-ui/core/Typography/Typography';
// import TextField from '@material-ui/core/TextField/TextField';
// import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Slider from '@material-ui/core/Slider';
// import type { CategoryFilter, FilterTypeEnum } from '../appState/types';
import { HorizontalSpace } from '../appUI';
// import { useObjectState } from '../hooks/component';
// import { useDidMount, useOnUnmount } from '../hooks/component';
// import { useIsDebounced } from '../hooks/timers';
import * as baseUI from '../baseUI';
import { FlexRow } from '../baseUI';

import { Grid } from '../baseUI/emotion';
import CancelIcon from '@material-ui/icons/Cancel';
import { TextMultipleSelect, TextSelect } from '../materialUI/formControls';
// import { withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button/Button';
import BlockIcon from '@material-ui/icons/Block';
// import { addToSet, removeFromSet } from '../jsutils/setutils';
// import * as objmap from '../jsutils/objmap';
import styled from '@emotion/styled';
import ArrowDown from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import { __internalThemeDoNotUse } from '../app/theme';
import { Trans } from '@lingui/react';
import Switch from '@material-ui/core/Switch/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import { scrollMiddleToElement } from '../jsutils/browser';
import { FormatPriceRange } from '../eshop/formatPrice';
import { useLanguageContainer, useLoginContainer } from '../app/state';
import { coerceNum } from '../jsutils/valutils';
// import { useDidChange } from '../hooks/useDidChange';
import { useTimers } from '../hooks/timers';
import { useDidChange } from '../hooks/useDidChange';

import { mapFiltersFromQueryParams } from './mapFiltersFromQueryParams';

import type { FilterInfoPack } from '../appState/types';
import slugify from 'slugify';

const filtersElementId = 'whyshop-product-filters';

const NullLabel = () => {
  return (
    <FlexRow color="rgb(245, 166, 35)">
      <CancelIcon />
      <HorizontalSpace />
      <Trans>Cancel filter</Trans>
    </FlexRow>
  );
};

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const InputLabelProps = { shrink: true };
const FormControlProps = { fullWidth: true, margin: 'dense' };

function FilterSelect(props: React.ElementConfig<typeof TextSelect>) {
  return (
    <TextSelect
      nullLabel={<NullLabel />}
      FormControlProps={FormControlProps}
      InputLabelProps={InputLabelProps}
      {...props}
    />
  );
}

function FilterBool(props: {|
  initiallyChecked: boolean,
  onChange: (boolean) => void,
  label: string,
|}) {
  const { initiallyChecked } = props;
  const [value, setValue] = useState(initiallyChecked);

  const timers = useTimers();

  //
  // Initial value
  //
  const initialValueChanged = useDidChange(initiallyChecked);
  useEffect(() => {
    if (initialValueChanged) {
      setValue(initiallyChecked);
    }
  }, [initialValueChanged]);

  const onChange = () => {
    setValue(!value);
    timers.reset({ id: 'checkedDebounced', timeout: 200 }, () => {
      props.onChange(value);
    });
  };

  return (
    <FormControlLabel
      control={<Switch onChange={onChange} checked={value} color="primary" />}
      label={props.label}
    />
  );
}

function FilterRange(props: {|
  id: string,
  min: number,
  max: number,
  step: number,
  initialValue: [number, number],
  onChange: ([number, number]) => void,
  label: string,
  locale: string,
  format: {|
    type: 'price',
    unit: string,
    price: {|
      currency: string,
    |},
  |},
|}) {
  const { id, initialValue } = props;

  const [value, setValue] = useState(initialValue);

  const onChange = (e, value) => {
    setValue(value);
  };

  const onChangeCommitted = (e, value) => {
    props.onChange(value);
  };

  const formatted = (() => {
    const { format } = props;
    if (format.type === 'price') {
      return (
        <FormatPriceRange value={value} currency={format.price.currency} locale={props.locale} />
      );
    }
    // eslint-disable-next-line no-unused-expressions
    (format.type: empty);
    return null;
  })();

  return (
    <FormControl>
      <baseUI.Block color="#757575" props={{ id }}>
        {props.label} {formatted}
      </baseUI.Block>

      <Slider
        value={value}
        step={props.step}
        min={props.min}
        max={props.max}
        onChange={onChange}
        onChangeCommitted={onChangeCommitted}
        valueLabelDisplay="auto"
        aria-labelledby={id}
        getAriaValueText={(value, index) => (index === 0 ? `min ${value}` : `max ${value}`)}
      />
    </FormControl>
  );
}

function FilterMultipleSelect(props: React.ElementConfig<typeof TextMultipleSelect>) {
  const [value, setValue] = useState(props.value || []);
  const { onChange, ...pass } = props;

  const onChangeProxy = (value) => {
    setValue(value);
  };

  //
  // Initial value
  //
  const initialValueChanged = useDidChange(props.value);
  useEffect(() => {
    if (initialValueChanged) {
      setValue(props.value || []);
    }
  }, [initialValueChanged]);

  const onClose = () => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <TextMultipleSelect
      nullLabel={<NullLabel />}
      FormControlProps={FormControlProps}
      InputLabelProps={InputLabelProps}
      {...pass}
      onClose={onClose}
      value={value}
      onChange={onChangeProxy}
    />
  );
}

const useStyles = makeStyles({
  paper: {
    boxShadow: 'none',
    borderBottom: `2px solid ${__internalThemeDoNotUse.colors.borderGrey}`,
  },
  details: {
    flexDirection: 'column',
  },
  summary: {
    backgroundColor: __internalThemeDoNotUse.colors.secondaryBg,
    borderBottom: `1px solid ${__internalThemeDoNotUse.colors.borderGrey}`,
  },
});

// type AttributeId = string;
// type FilterId = string;

type LocalProps = {|
  filterInfo: FilterInfoPack,
  defaultExpanded: ?boolean,
|};

// type LocalState = {|
//   activeFilterIds: Set<FilterId>,
//   filterValues: { [FilterId]: Array<AttributeId> },
//   intervalValues: { [string]: number },
// |};

function ProductFilters(props: LocalProps) {
  const languageContainer = useLanguageContainer();
  const classes = useStyles();
  const isLogged = useLoginContainer().isLogged();

  const { filterInfo, defaultExpanded = false } = props;

  const mappedFilterValues = React.useMemo(() => {
    return mapFiltersFromQueryParams(filterInfo);
  }, [filterInfo]);

  const onFiltersChange = ({
    filterValues = mappedFilterValues.filterValues,
    intervalValues = mappedFilterValues.intervalValues,
  }: {|
    filterValues?: *,
    intervalValues?: *,
  |}) => {
    delete filterValues.filterParams;
    const attributeIds = Object.values(filterValues)
      .reduce((acc, val) => acc.concat(val), [])
      .map(String);
    let resCopy = {};
    for (const attrId of attributeIds) {
      let filterId = '';
      const foundFilter = filters.find((f) => {
        if (f.filterType === 'bool') {
          return f.boolId === attrId;
        }
        if (f.filterType === 'select' || f.filterType === 'multiselect') {
          return f.options.find((opt) => opt.value === attrId);
        }
        if (f.filterType === 'img-gallery') {
          return f.imgGalleryOptions.find((opt) => opt.value === attrId);
        }
        return false;
      });
      if (foundFilter) {
        filterId = foundFilter.id;
      }
      let label = '';
      filters.forEach((f) => {
        if (f.options)
          f.options.forEach((opt) => {
            if (opt.value === attrId) {
              label = opt.label;
            }
          });
        else if (f.boolId === attrId) label = f.name;
        else if (f.imgGalleryOptions) {
          f.imgGalleryOptions.forEach((opt) => {
            if (opt.value === attrId) {
              label = opt.label;
            }
          });
        }
      });
      if (label.length > 0) {
        resCopy[
          foundFilter?.filterType === 'bool' && foundFilter?.boolId === '9998'
            ? 'in-stock'
            : slugify(filterId, { lower: true, remove: /[*+~.()'"!:@]/g })
        ] = [
          ...(resCopy[slugify(filterId, { lower: true, remove: /[*+~.()'"!:@]/g })] || []),
          foundFilter?.filterType === 'bool' && foundFilter?.boolId === '9998'
            ? 'true'
            : foundFilter?.filterType === 'bool'
            ? 1
            : slugify(label, { lower: true, remove: /[*+~.()'"!:@]/g }),
        ];
      }
    }
    const change = { attributeIds, filterNames: resCopy, namedFilters: { ...intervalValues } };
    props.filterInfo.onChange(change); //toto je onFilter z CategoryPage.js
  };

  const onChange = (filterId: string) => (value: Array<string>) => {
    const filterValues = { ...mappedFilterValues.filterValues, [filterId]: value };
    onFiltersChange({ filterValues });
  };

  const onUnset = (filterId: string) => () => {
    let filterValues = { ...mappedFilterValues.filterValues };
    delete filterValues[filterId];
    onFiltersChange({ filterValues });
  };

  const onClearAll = () => {
    onFiltersChange({ filterValues: {}, intervalValues: {} });
  };

  const { filterValues, intervalValues, activeFilterIds } = mappedFilterValues;

  const hasActiveFilters = Boolean(activeFilterIds.size);

  useEffect(() => {
    const el = document.getElementById(filtersElementId);
    if (!el) {
      return;
    }
    if (hasActiveFilters) {
      scrollMiddleToElement(el);
    }
  }, []);

  const { filters } = props.filterInfo;

  return (
    <div id={filtersElementId}>
      <ExpansionPanel
        elevation={2}
        defaultExpanded={hasActiveFilters || defaultExpanded}
        className={classes.paper}
      >
        <ExpansionPanelSummary expandIcon={<ArrowDown />} className={classes.summary}>
          <Title>
            <Trans>Product filters (choose color / usage / price / material)</Trans>
          </Title>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Grid gridGap="2em" gridTemplateColumns={`repeat(auto-fill, minmax(250px, 1fr))`}>
            {filters.map((f) => {
              const filterId = f.id;
              if (f.authNeeded && !isLogged) return null;
              if (f.filterType === 'bool') {
                const checkedId = f.boolId;
                const checked = !!filterValues[filterId];

                return (
                  <FilterBool
                    key={filterId}
                    initiallyChecked={checked}
                    onChange={(checked) => {
                      checked ? onUnset(filterId)() : onChange(filterId)([checkedId]);
                    }}
                    label={f.name}
                  />
                );
              }
              if (f.filterType === 'select')
                return (
                  <FilterSelect
                    key={filterId}
                    value={filterValues[filterId] ? filterValues[filterId][0] : ''}
                    onChange={(value) => onChange(filterId)([value])}
                    onUnset={onUnset(filterId)}
                    formId={filterId}
                    inputLabel={f.name}
                    options={f.options}
                  />
                );
              if (f.filterType === 'multiselect')
                return (
                  <FilterMultipleSelect
                    key={filterId}
                    value={filterValues[filterId] || []}
                    onChange={onChange(filterId)}
                    onUnset={onUnset(filterId)}
                    formId={filterId}
                    inputLabel={f.name}
                    options={f.options}
                  />
                );
              if (f.filterType === 'interval') {
                const { min, max, step, format } = f.interval;

                const filterName = f.paramName;
                const filterNameMin = `${filterName}_min`;
                const filterNameMax = `${filterName}_max`;

                const onRangeChange = ([min, max]) => {
                  onFiltersChange({
                    intervalValues: {
                      ...intervalValues,
                      [filterNameMin]: min,
                      [filterNameMax]: max,
                    },
                  });
                };
                const minValue = intervalValues[filterNameMin];
                const maxValue = intervalValues[filterNameMax];

                const value = [
                  minValue !== undefined ? Number(minValue) : min,
                  maxValue !== undefined ? Number(maxValue) : max,
                ];

                return (
                  <FilterRange
                    key={filterId}
                    id={f.id}
                    min={min}
                    max={max}
                    step={step}
                    initialValue={value}
                    onChange={onRangeChange}
                    label={f.name}
                    format={format}
                    locale={languageContainer.selectActiveLanguage()}
                  />
                );
              }
              return null;
            })}

            <FlexRow>
              <Button
                color="primary"
                onClick={onClearAll}
                disabled={!hasActiveFilters}
                size="small"
              >
                <BlockIcon size="small" />
                <HorizontalSpace />
                <Trans>Clear all filters</Trans>
              </Button>
            </FlexRow>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default ProductFilters;
