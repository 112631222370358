// @flow

// import idx from 'idx';
// import type { ApiErrorsResponse } from '../appState/jsonTypes';
// import type { NormalizedApiErrors } from '../appState/types';

/**
 * check the tests
 */
export function getFirstApiError(data: Object): string {
  const finalToken = 'x';

  const getValue = (data) => {
    if (typeof data === 'string') {
      return data;
    }
    if (Array.isArray(value)) {
      return value.join(' ');
    }
    const hasKeys = data && Object.keys(data);
    if (!hasKeys) {
      return finalToken;
    }
    const keys = Object.keys(data);
    if (keys.includes('error') && data.error.user_message) {
      return data.error.user_message;
    }
    const firstKey = keys[0];
    if (!firstKey) {
      return finalToken;
    }
    return data[firstKey];
  };

  // Note: it can only be nested so far (see unit tests), no need for while loop
  let value = getValue(data);
  if (value !== finalToken) {
    value = getValue(value);
  }
  if (value !== finalToken) {
    value = getValue(value);
  }
  // digging deep enough, but no message found
  if (value === finalToken) {
    return '';
  }
  return value;
}
