// @flow

import * as Sentry from "@sentry/react";

// @todo: consider about moving sentryDSN variable to another place(smth like .env, or store in db)
const sentryDSN = "https://a64df4bdf370dd55cd84ea99a2ec6c26@o4507700621541376.ingest.de.sentry.io/4507700875034704";

function setupSentry(){
  Sentry.init({
    dsn: sentryDSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      // Production domains
      /^https:\/\/rudorfer\.pl\//,
      /^https:\/\/rudorfer\.be\//,
      /^https:\/\/rudorfer\.cz\//,
      /^https:\/\/rudorfer\.fr\//,
      /^https:\/\/rudorferpack\.com\//,
      /^https:\/\/rudorferpack\.eu\//,
      /^https:\/\/rudorfer\.hu\//,
      /^https:\/\/rudorfer\.com\.de\//,
      /^https:\/\/rudorfer\.sk\//,
      /^https:\/\/rudorfer\.co\.at\//,
      /^https:\/\/rudorfer\.nl\//,

      // Test domains
      /^(at|cz|de|dealer|eu|fr|hu|pl|sk)\.test\.rudorfer\.whys\.eu$/,
      "localhost",
    ],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    // This sets the sample rate at 10%.
    // You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,
    // If you're not already sampling the entire session,
    // change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0
  });
}

export default setupSentry;