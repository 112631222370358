// @flow

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Paper,
  Switch,
  Button,
} from '@material-ui/core';
import { styledTheme } from '../app/theme';
import styled from '@emotion/styled';
import { FlexRow } from '../baseUI';
import { Trans } from '@lingui/react';
import { useGlobalContainer } from '../app/use';
import { useLanguageContainer } from '../app/state';
import { useLingui } from '../app/lingui';
import { gtag } from '../AppRoot/gtm';

type Props = {|
  onSubmit: () => void,
  onClose: () => void,
  open: boolean,
  initialState: any,
|};

const Title = styled(DialogTitle)`
  color: ${styledTheme.color('primary')};
`;

const Subtitle = styled(Typography)`
  color: ${styledTheme.color('primary')};
`;

const Caption = styled(Typography)`
  color: ${styledTheme.color('neutralText')};
`;

const CookiesPopup = (props: Props) => {
  const globalContainer = useGlobalContainer();
  const languageContainer = useLanguageContainer();
  const i18n = useLingui();

  const [switchInput, setSwitchInput] = useState(props.initialState);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setSwitchInput({ ...switchInput, [name]: checked });
  };

  const handleSubmit = () => {
    globalContainer.storeCookiesConsent(switchInput);

    gtag('consent', 'update', {
      ad_user_data: switchInput.cookie_consent_remarketing ? 'granted' : 'denied',
      ad_personalization: switchInput.cookie_consent_remarketing ? 'granted' : 'denied',

      ad_storage: switchInput.cookie_consent_remarketing ? 'granted' : 'denied',
      analytics_storage: switchInput.cookie_consent_analytics ? 'granted' : 'denied',
      personalization_storage: switchInput.cookie_consent_remarketing ? 'granted' : 'denied',
      functionality_storage: switchInput.cookie_consent_essential ? 'granted' : 'denied',
      security_storage: switchInput.cookie_consent_essential ? 'granted' : 'denied',
    })

    gtag({
      event: 'update_consent',

      attributes: {
        ad_user_data: switchInput.cookie_consent_remarketing ? 'granted' : 'denied',
        ad_personalization: switchInput.cookie_consent_remarketing ? 'granted' : 'denied',

        ad_storage: switchInput.cookie_consent_remarketing ? 'granted' : 'denied',
        analytics_storage: switchInput.cookie_consent_analytics ? 'granted' : 'denied',
        personalization_storage: switchInput.cookie_consent_remarketing ? 'granted' : 'denied',
        functionality_storage: switchInput.cookie_consent_essential ? 'granted' : 'denied',
        security_storage: switchInput.cookie_consent_essential ? 'granted' : 'denied',
      }
    });

    window.dataLayer.push({
      event: 'setCookie',
      attributes: {
        cookie_consent_essential: 1,
        cookie_consent_analytics: switchInput.cookie_consent_analytics ? 1 : 0,
        cookie_consent_remarketing: switchInput.cookie_consent_remarketing ? 1 : 0,
      },
    });

    if (window.clarity) {
      if (typeof window.clarity === 'function' && switchInput.cookie_consent_remarketing) {
        window.clarity('consent');
      }
    }

    props.onSubmit();
  };

  const handleCancel = () => {
    setSwitchInput(props.initialState);
    props.onClose();
  };

  const handleAcceptAll = () => {
    const allAccepted = {
      cookie_consent_essential: true,
      cookie_consent_analytics: true,
      cookie_consent_remarketing: true,
    };
    setSwitchInput(allAccepted);
    globalContainer.storeCookiesConsent(allAccepted);

    gtag({
      event: 'update_consent',

      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
      personalization_storage: 'granted',
      functionality_storage: 'granted',
      security_storage: 'granted',
    })

    gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',

      ad_storage: 'granted',
      analytics_storage: 'granted',
      personalization_storage: 'granted',
      functionality_storage: 'granted',
      security_storage: 'granted',
    })

    window.dataLayer.push({
      event: 'setCookie',
      attributes: {
        cookie_consent_essential: 1,
        cookie_consent_analytics: 1,
        cookie_consent_remarketing: 1,
      },
    });

    if (window.clarity) {
      if (typeof window.clarity === 'function') {
        window.clarity('consent');
      }
    }

    props.onSubmit();
  };

  const resolveURLbyLang = () => {
    const language = languageContainer.selectActiveLanguage();
    if (language === 'pl') return '/zasady-korzystania-z-plikow-cookie/';
    else if (language === 'cs') return '/zasady-pouzivani-souboru-cookie/';
    else if (language === 'de') return '/richtlinien-fur-die-cookie-verwendung/';
    else if (language === 'hu') return '/a-sutik-hasznalatanak-alapelvei/';
    else if (language === 'sk') return '/zasady-pouzivania-suborov-cookies/';
    else if (language === 'en') return '/principles-of-cookie-use/';
    else if (language === 'fr') return '/politique-de-cookies/';
    else return '';
  };

  return (
    // <div
    //   style={{
    //     position: 'fixed',
    //     bottom: '0',
    //     right: '0',
    //     boxShadow: '0 1px 5px 0 rgb(0 0 0 / 30%)',
    //     height: '150px',
    //     width: '600px',
    //     display: 'block',
    //     backgroundColor: '#fff',
    //   }}
    // >
    //   <div style={{ padding: '0 25px' }}>
    //     <h4>Privacy settings</h4>
    //     <p>
    //       We would like your permission to use your data for Functional, Marketing and Essential
    //       purposes. <Link style={{ paddingLeft: '10px', color: 'blue' }}>Read more</Link>
    //     </p>
    //     <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    //       <SecondaryButton onClick={props.onClick} color="primary">
    //         Deny{' '}
    //       </SecondaryButton>{' '}
    //       <SecondaryButton onClick={props.onClick} style={{ marginLeft: '20px' }} color="primary">
    //         Accept{' '}
    //       </SecondaryButton>
    //     </div>
    //   </div>
    // </div>
    <Dialog open={props.open}>
      <FlexRow>
        <Title onClose={props.onClose}>
          <Trans>Cookies</Trans>
        </Title>
      </FlexRow>
      <DialogContent /* style={{ width: '520px', height: '480px' }}*/ dividers>
        <FlexRow>
          <Caption variant="subtitle2" gutterBottom>
            <Trans>
              Cookies are small files that websites (including ours) store in your website browsers.
              The contents of these files are exchanged between your browser and ours servers, or
              with the servers of our partners. Some cookies we need to make a website The site
              could work properly, some we need for marketing and statistics analytics. You will
              learn more about the principles of using cookies{' '}
              <a title={i18n.t`Cookies policy`} href={resolveURLbyLang()}>
                <span>HERE</span>
              </a>
              . On this page you can set which cookies we will be able to use.
            </Trans>
          </Caption>
        </FlexRow>
        <FlexRow>
          <Paper elevation={3} style={{ padding: '12px', marginTop: '15px' }}>
            <Subtitle variant="subtitle1" gutterBottom>
              <Trans>Essential cookies</Trans>
            </Subtitle>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Caption variant="subtitle2">
                <Trans>
                  These are technical files that are necessary for the proper behavior of ours
                  websites and all their functions. They are used, among other things, to store
                  products in shopping cart, displaying products on request, filter control,
                  personal settings and also the setting of consent to the use of cookies. There is
                  no such cookie Your consent is required and cannot be removed
                </Trans>
              </Caption>
              <Switch checked disabled color="primary" name="cookie_consent_essential" />
            </div>
          </Paper>
        </FlexRow>
        <FlexRow>
          <Paper elevation={3} style={{ padding: '12px', marginTop: '15px' }}>
            <Subtitle variant="subtitle1" gutterBottom>
              <Trans>Analytical cookies</Trans>
            </Subtitle>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Caption variant="subtitle2">
                <Trans>
                  They help improve and streamline website functionality by tracking activity
                  visitors to them. Thanks to analytical cookies, the website can e.g. remember past
                  visitor preferences or speed up visitor processing requirement. The website
                  operator needs consent to use this type of cookie data subject. Analytical cookies
                  cannot be used without such consent
                </Trans>
              </Caption>
              <Switch
                id="consent_analytics"
                color="primary"
                name="cookie_consent_analytics"
                onChange={handleChange}
                value={switchInput.cookie_consent_analytics}
                defaultChecked={switchInput.cookie_consent_analytics}
              />
            </div>
          </Paper>
        </FlexRow>
        <FlexRow>
          <Paper elevation={3} style={{ padding: '12px', marginTop: '15px' }}>
            <Subtitle variant="subtitle1" gutterBottom>
              <Trans>Remarketing cookies</Trans>
            </Subtitle>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Caption variant="subtitle2">
                <Trans>
                  They collect personal information about the user from a marketing point of view.
                  E.g. gather information in order to adapt the offered advertising to the interests
                  of the customer, interconnection social networks, etc. To use this type of cookie,
                  the website operator needs it consent of the data subject. Remarketing cookies
                  cannot be made without such consent to use
                </Trans>
              </Caption>
              <Switch
                id="consent_remarketing"
                color="primary"
                name="cookie_consent_remarketing"
                onChange={handleChange}
                value={switchInput.cookie_consent_remarketing}
                defaultChecked={switchInput.cookie_consent_remarketing}
              />
            </div>
          </Paper>
        </FlexRow>
      </DialogContent>
      <DialogActions style={{ marginRight: '5%' }}>
        <Button id="consent_save" variant="outlined" onClick={() => handleSubmit()} color="primary">
          <Trans>Save settings</Trans>
        </Button>
        <Button
          variant="contained"
          onClick={handleAcceptAll}
          color="primary"
          data-cy="cookies-submit"
        >
          <Trans>Accept all cookies</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookiesPopup;
