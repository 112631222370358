// @flow

import { coerceNum } from '../mapping';

import type { ProductDetail } from '../models/product';
import type { BillingAddressType, PaymentOption } from '../../appState/types';
import type { PaymentOptionPayload } from '../../appState/jsonTypes';
import { cart } from '../../exampleHelpers/factories';

// type FrontendID = string;

//
// Models
//

// export type CartProductItem = interface {
//   id: FrontendID,
// };

export type BackendID = number;

export type CartStats = {|
  total: number,
  itemsCount: number,
|};

// NoteAPIRefactor: values are quite unclear
type CartItemStatusType = 'OK' | 'PARTIALLY_OK' | 'INFO' | 'INACTIVE' | 'ALERT';

export type CartItem = {|
  productItem: ProductDetail,
  status: {| text: string, type: CartItemStatusType |},
  quantity: number,
  prices: {|
    baseNet: number,
    discountNet: number,
    totalNet: number,
    totalDiscountNet: number,
    couponsPriceNet: number,
    couponsPriceGross: number,
    couponsDiscount: number,
  |},
|};

export type CartDetail = {|
  id: BackendID,
  total: number,
  prices: {
    cartDiscountGross: number,
    cartDiscountNet: number,
    baseGross: number,
    baseNet: number,
    discountGross: number,
    discountNet: number,
    discountPercentage: number,
    discountPriceGross: number,
    discountPriceNet: number,
    discountEshop: number,
    discountQuantity: number,
    discountLimitOffer: number,
    couponsPriceNet: number,
    couponsPriceGross: number,
    couponsDiscount: number,
  },
  itemsCount: number,
  freeShippingFrom: number,
  freeShippingPrice: number,
  freeShippingType: 'always_paid' | 'never_paid' | 'free_from' | 'individual' | '',
  minOrderPrice: number,
  billingAddress: ?BillingAddressType,
  shippingAddress: ?BillingAddressType,
  paymentMethod: ?PaymentOption,
  shippingMethod: ?PaymentOption,
  note: string,
  token: string,
  attachments: Array<string>,
  quantityDiscount: Array<{| price_threshold: number, discount_percentage: number |}>,
  individualDiscountMessage: string,
|};

//
// API
//

export const resources = {
  addItem: { url: `/api/cart/item/`, method: 'POST' },
  addManyItems: { url: `/api/cart/items/`, method: 'POST' },
  setItemQuantity: (id: string) => ({ url: `/api/cart/item/${id}/`, method: 'PUT' }),
  removeItem: (id: string) => ({ url: `/api/cart/item/${id}/`, method: 'DELETE' }),
  cartDetail: { url: `/api/cart/` },
  cartStats: { url: `/api/cart/less/` },
  createOrder: { url: `/api/order/`, method: 'POST' },
  createShippingAddress: { url: `/api/business/address-shipping/`, method: 'POST' },
  clearCart: { url: `/api/cart/`, method: 'DELETE' },
  paymentOptions: { url: '/api/checkout/payment-method/' },
  shippingOptions: {
    url: (country: string) => `/api/checkout/shipping-method/?country_code=${country}`,
  },
  updateCart: { url: '/api/cart/', method: 'PATCH' },
  addCoupon: { url: `/api/cart/coupons/`, method: 'POST' },
  removeCoupon: { url: `/api/cart/coupons/`, method: 'DELETE' },
  getCoupons: { url: '/api/cart/coupons/', method: 'GET' },
};

type CartItemSeverityEnum = 'OK' | 'PARTIALLY_OK' | 'INFO' | 'INACTIVE' | 'ALERT';

export type CartStateItemPayload = {|
  // we don't need this
  id: BackendID,
  variant: BackendID,
  quantity: number,
  status?: {| text: string, severity_type: CartItemSeverityEnum |},
  prices: {|
    base_price_net: number,
    discount_price_net: number,
    total_discount_price_net: number,
    total_base_price_net: number,
    total_cart_coupons_price_net: number,
    total_cart_coupons_price_gross: number,
    total_coupons_discount: number,
  |},
|};

export type CartDetailPayload = {|
  id: BackendID,
  // open, payment, saved, ordered, checkout, canceled
  // status: 'open',
  items: CartStateItemPayload[],
  items_count: number,
  missing_items: CartStateItemPayload[],
  error?: {|
    detail: string,
    user_message: string,
    code: string,
  |},
  prices: {
    total_cart_base_price_gross: number,
    total_cart_base_price_net: number,
    total_cart_discount_price_gross: number,
    total_cart_discount_price_net: number,
    total_base_price_gross: number,
    total_base_price_net: number,
    total_discount_price_gross: number,
    total_discount_price_net: number,
    total_discount_gross: number,
    total_discount_net: number,
    total_discount_percentage: number,
    total_eshop_discount: number,
    total_limit_offer_discount: number,
    total_quantity_discount: number,
    total_cart_coupons_price_net: number,
    total_cart_coupons_price_gross: number,
    total_coupons_discount: number,
  },
  free_shipping_from: number,
  free_shipping_price: number,
  free_shipping_type: 'always_paid' | 'never_paid' | 'free_from' | 'individual',
  min_order_price: number,
  note: string,
  billing_address: { ...BillingAddressType, id: number },
  shipping_address: { ...BillingAddressType, id: number },
  payment_method: PaymentOptionPayload,
  shipping_method: PaymentOptionPayload,
  token: string,
  attachments: Array<string>,
  quantity_discount: Array<{|
    price_threshold: number,
    discount_percentage: number,
  |}>,
  individual_discount_message: string,
|};

export type CartStatsPayload = {|
  items_count: number,
  total_discount_price: number,
|};

//
// Mapping
//

export function mapCartStats(payload: CartStatsPayload): CartStats {
  return {
    total: coerceNum(payload.total_discount_price, 0),
    itemsCount: coerceNum(payload.items_count, 0),
  };
}

export function mapCartDetail(payload: CartDetailPayload): CartDetail {
  const { prices } = payload;
  const res = {
    id: payload.id,
    total: coerceNum(prices.total_cart_discount_price_net, 0),
    prices: {
      cartDiscountGross: coerceNum(prices.total_cart_discount_price_gross, 0),
      cartDiscountNet: coerceNum(prices.total_cart_discount_price_net, 0),
      baseGross: coerceNum(prices.total_cart_base_price_gross, 0),
      baseNet: coerceNum(prices.total_cart_base_price_net, 0),
      discountGross: coerceNum(prices.total_discount_gross, 0),
      discountNet: coerceNum(prices.total_discount_net, 0),
      discountPercentage: coerceNum(prices.total_discount_percentage, 0),
      discountPriceGross: coerceNum(prices.total_discount_price_gross, 0),
      discountPriceNet: coerceNum(prices.total_discount_price_net, 0),
      discountEshop: coerceNum(prices.total_eshop_discount, 0),
      discountLimitOffer: coerceNum(prices.total_limit_offer_discount, 0),
      discountQuantity: coerceNum(prices.total_quantity_discount, 0),
      couponsPriceNet: coerceNum(prices.total_cart_coupons_price_net, 0),
      couponsPriceGross: coerceNum(prices.total_cart_coupons_price_gross, 0),
      couponsDiscount: coerceNum(prices.total_coupons_discount, 0),
    },
    itemsCount: coerceNum(payload.items_count, 0),
    freeShippingFrom: coerceNum(payload.free_shipping_from, 0),
    freeShippingType: payload.free_shipping_type || '',
    freeShippingPrice: coerceNum(payload.free_shipping_price, 0),
    minOrderPrice: coerceNum(payload.min_order_price, 0),
    billingAddress: mapIdToString(payload.billing_address),
    shippingAddress: mapIdToString(payload.shipping_address),
    paymentMethod: mapIdToString(payload.payment_method),
    shippingMethod: mapIdToString(payload.shipping_method),
    note: payload.note,
    token: payload.token,
    attachments: payload.attachments,
    quantityDiscount: payload.quantity_discount,
    individualDiscountMessage: payload.individual_discount_message,
  };

  return res;
}

export function mapIdToString(object?: { id: number }): any {
  return object ? { ...object, id: String(object.id) } : object;
}
