// @flow

import * as React from 'react';
import { useGlobalContainer } from '../app/use';

/**
 *  Returns Microsoft Clarity tracking code script.
 *  @see https://clarity.microsoft.com/
 * @returns {string}
 */
function useClarityTrackingCode() {
  const [clarityTrackingCode, setClarityTrackingCode] = React.useState<React.Element<*> | string>('');
  const [clarityTrackingId, setClarityTrackingId] = React.useState<string>('');
  const globalContainer = useGlobalContainer();

  React.useEffect(() => {
    if(globalContainer.isSpecificSite('rudorfer.cz')){
      setClarityTrackingId('hsyah250q0');
    } else if(globalContainer.isSpecificSite('rudorfer.pl')){
      setClarityTrackingId('hrgnrpzz06');
    } else if(globalContainer.isSpecificSite('rudorfer.sk')){
      setClarityTrackingId('hsybmp4yg3');
    } else if(globalContainer.isSpecificSite('rudorfer.hu')){
      setClarityTrackingId('hsyb67ni6y');
    } else if(globalContainer.isSpecificSite('rudorfer.de')){
      setClarityTrackingId('hsyc79jnd1');
    } else if(globalContainer.isSpecificSite('rudorfer.at')){
      setClarityTrackingId('hsycldgycu');
    } else if(globalContainer.isSpecificSite('rudorfer.fr')){
      setClarityTrackingId('hsyd1nru2b');
    } else if(globalContainer.isSpecificSite('rudorfer.be')){
      setClarityTrackingId('hsydhs8kih');
    } else if(globalContainer.isSpecificSite('rudorfer.eu')){
      setClarityTrackingId('hsyeyuuch9');
    } else if(globalContainer.isSpecificSite('rudorfer.dealer')){
      setClarityTrackingId('hsyef55bn9');
    }
  }, [])

  React.useEffect(() => {
    if(clarityTrackingId){
      setClarityTrackingCode(
        <script type="text/javascript">
          {`(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${clarityTrackingId}");`}
        </script>
      );
    }
  }, [clarityTrackingId])

  return clarityTrackingCode;
}

export default useClarityTrackingCode;