// @flow

import { Container } from '@whys/app/lib/state';

import type { FetchEnvType, AppCacheType } from '../types/app';
import { getJSON, fetchJSON } from '@whys/fetch/lib/json';
import { resources, mapOrder, mapInvoice, mapUsers, mapWorkerRolesToArray } from './models/profile';
import type { BusinessesPayload } from '../appState/jsonTypes';
import type {
  BillingAddressType,
  ProductDetail,
  OrderOverviewItem,
  OrderProductItem,
  RoleModel,
  InvoiceOverviewItem,
  ProfileModel,
  DashboardModel,
  UserModel,
  WorkerRoles,
} from '../appState/types';
import type { CartStateItemPayload } from './models/cart';
import { mapIdToString } from './models/cart';
import { coerceNum } from './mapping';
import type { ProductContainerType } from './types';
import type { CursorSuperCache } from '@whys/fetch/lib/pagination/CursorSuperCache';
import { create as createCursorSuperCache } from '@whys/fetch/lib/pagination/CursorSuperCache';
import { getNextUrl } from './requests';
import idx from 'idx';
import type { PermissionsEnum } from '../containers/GlobalContainer';

type LocalState = {|
  profile: $Shape<ProfileModel>,
  billingAddress: $Shape<BillingAddressType>,
  users: Array<UserModel>,
  businesses: Array<BusinessesPayload>,
  countries: Array<string>,
  roles: Array<RoleModel>,
  errors: Object,
  rolePermissions: { [number]: { [PermissionsEnum]: boolean } },
  notification: string,
|};

type LocalProps = {|
  fetchEnv: FetchEnvType,
  appCache: AppCacheType,
  productsContainer: ProductContainerType<$FlowFixMe, $FlowFixMe>,
  initialProfile: $Shape<ProfileModel>,
|};
type AsyncBinaryResult = Promise<boolean>;
type AsyncResult = Promise<{| errors: ?Object, hasError: boolean |}>;

// function nonNillOrDef<T>(value: ?T, defaultValue: T): T {
//   return value === null || value === undefined ? defaultValue : value;
// }

export class ProfileContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  ordersCache: CursorSuperCache<OrderOverviewItem>;
  invoicesCache: CursorSuperCache<InvoiceOverviewItem>;

  constructor(props: LocalProps) {
    super();
    const { fetchEnv, appCache } = props;
    this.props = props;
    this.state = {
      businesses: [],
      users: [],
      profile: this.props.initialProfile,
      billingAddress: {},
      countries: [],
      roles: [],
      errors: {},
      rolePermissions: {},
      notification: '',
    };

    this.ordersCache = createCursorSuperCache({
      cachePrefix: 'ProfileContainer.orders',
      appCache,
      fetchEnv,
      getNextUrl: ({ response }) => {
        return getNextUrl(response);
      },
      mapData: mapOrder,
    });

    this.invoicesCache = createCursorSuperCache({
      cachePrefix: 'ProfileContainer.invoices',
      appCache,
      fetchEnv,
      getNextUrl: ({ response }) => {
        return getNextUrl(response);
      },
      mapData: mapInvoice,
    });
  }

  //
  // Mutations
  //

  async updateProfile(data: any): AsyncResult {
    const { fetchEnv } = this.props;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.updateProfile,
      data,
    });
    await this.refetchDashboard();
    if (result.status === 'error') {
      return { errors: result.data, hasError: true };
    }
    return { errors: null, hasError: false };
  }

  async createShippingAddress(data: any): AsyncBinaryResult {
    const { fetchEnv } = this.props;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.createShippingAddress,
      data,
    });
    await this.loadBusinesses();
    return result.status === 'ok';
  }

  async changePassword(data: any): AsyncResult {
    const { fetchEnv } = this.props;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.changePassword,
      data,
    });
    await this.refetchDashboard();
    if (result.status === 'error') {
      return { errors: result.data, hasError: true };
    }
    return { errors: null, hasError: false };
  }

  async deleteBusiness(id: any): AsyncBinaryResult {
    const { fetchEnv } = this.props;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.deleteBusiness(id),
    });
    await this.loadBusinesses();
    return result.status === 'ok';
  }

  async editBusiness(id: any, data: *): AsyncBinaryResult {
    const { fetchEnv } = this.props;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.editBusiness(id),
      data,
    });
    await this.loadBusinesses();
    return result.status === 'ok';
  }

  async inviteUser(email: string, profile: *, workerRoles: WorkerRoles): AsyncResult {
    const { fetchEnv } = this.props;
    const worker_roles = mapWorkerRolesToArray(workerRoles);
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.inviteUser,
      data: {
        email,
        profile,
        worker_roles,
      },
    });
    await this.loadUsers();
    if (result.status === 'error') {
      return { errors: result.data, hasError: true };
    }
    return { errors: null, hasError: false };
  }

  async editUser(userId: string, profile: *, workerRoles: WorkerRoles): AsyncResult {
    const { fetchEnv } = this.props;
    const worker_roles = mapWorkerRolesToArray(workerRoles);

    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.editUser(userId),
      data: { profile, worker_roles },
    });
    await this.loadUsers();
    if (result.status === 'error') {
      return { errors: result.data, hasError: true };
    }
    return { errors: null, hasError: false };
  }

  async deleteUser(businessId: string, userId: string): AsyncBinaryResult {
    const { fetchEnv } = this.props;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.deleteUser(businessId, userId),
    });
    await this.loadUsers();
    return result.status === 'ok';
  }

  //
  // Queries
  //

  async loadBusinesses(): AsyncBinaryResult {
    const result = await getJSON(resources.businesses.url, this.props.fetchEnv);
    if (result.status === 'ok') {
      const businesses: Array<BusinessesPayload> = result.data.businesses.map(mapIdToString);

      this.setState({ businesses });
      return true;
    }
    return false;
  }

  async loadBillingAddress(): AsyncBinaryResult {
    const result = await getJSON(resources.billingAddress.url, this.props.fetchEnv);

    if (result.status === 'ok') {
      const billingAddress: BillingAddressType = mapIdToString(result.data);

      this.setState({ billingAddress });
      return true;
    }
    return false;
  }

  async fetchOrders(): Promise<OrderOverviewItem[]> {
    const result = await getJSON(resources.orders.url, this.props.fetchEnv);

    if (result.status === 'ok') {
      const response = result.data.map((res) => mapOrder(res));
      return await this._resolveOrders(response);
    }
    return [];
  }

  async loadOrders(): Promise<OrderOverviewItem[]> {
    const ordersCache = this.ordersCache.getCacheByUrl(resources.orders.url);
    const orders = await ordersCache.getAllItems();

    return await this._resolveOrders(orders);
  }

  async filterOrders(attrId: ?string): Promise<OrderOverviewItem[]> {
    const initialUrl = resources.orders.url;

    const ordersCache = this.ordersCache.getCacheByUrl(
      attrId ? `${initialUrl}?business_ids=${attrId}` : initialUrl
    );
    const orders = await ordersCache.getAllItems();
    return await this._resolveOrders(orders);
  }

  async loadInvoices(): Promise<InvoiceOverviewItem[]> {
    const invoicesCache = this.invoicesCache.getCacheByUrl(resources.invoices.url);
    return await invoicesCache.getAllItems();
  }

  async filterInvoices(attrId: ?string): Promise<InvoiceOverviewItem[]> {
    const initialUrl = resources.invoices.url;

    const invoicesCache = this.invoicesCache.getCacheByUrl(
      attrId ? `${initialUrl}?business_ids=${attrId}` : initialUrl
    );
    return await invoicesCache.getAllItems();
  }

  async loadUserData(): Promise<{ email: string }> {
    const result = await getJSON(resources.userData.url, this.props.fetchEnv);

    if (result.status === 'ok') {
      return result.data;
    }
    return {};
  }

  async loadUsers(): AsyncBinaryResult {
    if (this.state.roles.length === 0) {
      await this.loadRoles();
    }
    const result = await getJSON(resources.users.url, this.props.fetchEnv);

    if (result.status === 'ok') {
      this.setState({ users: mapUsers(result.data) });
      return true;
    }
    return false;
  }

  async loadCountries(): AsyncBinaryResult {
    const result = await getJSON(resources.countries.url, this.props.fetchEnv);

    if (result.status === 'ok') {
      let countries = result.data.allowed_countries;
      countries = countries ? countries.sort() : countries;
      this.setState({ countries });
      return true;
    }
    return false;
  }

  async loadRoles(): Promise<RoleModel[]> {
    const result = await getJSON(resources.roles.url, this.props.fetchEnv);

    if (result.status === 'ok') {
      const roles = result.data.map(({ can_be_edited, permission_list, ...role }) => ({
        ...role,
        id: String(role.id),
        canBeEdited: can_be_edited,
        permissions: permission_list,
      }));
      const rolePermissions = {};
      result.data.forEach(({ id, permission_list }) => {
        const permissions = {};
        permission_list.forEach((permission) => {
          permissions[permission.code] = permission.can;
        });
        rolePermissions[id] = permissions;
      });

      this.setState({ roles, rolePermissions });
      return roles;
    }
    return [];
  }

  async fetchDashboard(): AsyncBinaryResult {
    return await this.refetchDashboard();
  }

  async refetchDashboard(): AsyncBinaryResult {
    const { fetchEnv } = this.props;
    const result = await getJSON(resources.dashboard.url, fetchEnv);
    if (result.status === 'ok') {
      const { profile, billing_address: billingAddress, notification } = result.data;
      this.setState({ profile, billingAddress, notification });
      return true;
    }
    return false;
  }

  //
  // Selectors
  //

  selectBillingAddress(): BillingAddressType {
    return this.state.billingAddress;
  }

  selectBusinesses(): Array<BusinessesPayload> {
    return this.state.businesses;
  }

  selectCountries(): Array<string> {
    return this.state.countries;
  }

  selectUsers(businessId: ?string): Array<UserModel> {
    // if (!businessId) return [];
    return this.state.users;
  }

  selectProfile() {
    return this.state.profile;
  }

  selectDashboard(): DashboardModel {
    const { profile, billingAddress, notification } = this.state;
    return { profile, billingAddress, notification };
  }

  selectRolePermission(roleId: number, permission: PermissionsEnum): boolean {
    return idx(this.state, (_) => _.rolePermissions[roleId][permission]) || false;
  }

  async _resolveOrders(orders: Array<OrderOverviewItem>) {
    return await Promise.all(
      orders.map(async (order) => ({ ...order, items: await this._resolveProducts(order) }))
    );
  }

  async _resolveProducts(order: *): Promise<OrderProductItem[]> {
    const { productsContainer } = this.props;
    const productItemIDs = order.items.map((_) => String(_.variant));
    const products: ProductDetail[] = await productsContainer.resolveProductItemsByIds(
      productItemIDs
    );
    let productsByIDs = {};
    for (const product of products) {
      productsByIDs[product.id] = product;
    }
    const items = (() => {
      let result = [];
      for (const itemPayload: CartStateItemPayload of order.items) {
        const variantId = String(itemPayload.variant);
        const productItem = productsByIDs[variantId];
        if (!productItem) {
          continue;
        }
        const item = {
          productItem,
          quantity: coerceNum(itemPayload.quantity, 0),
        };
        result.push(item);
      }
      return result;
    })();

    return items;
  }
}
