// @flow

import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { SelectMultipleControl, SelectControl } from './index';

export class TextSelect extends React.Component<
  {|
    formId: string,
    options: Array<{| value: string, label: string |}>,

    //
    // defaults or optional
    //
    variant: 'nonnull' | 'nullable',
    nullLabel: React.Node,

    inputLabel?: string,
    initialValue?: string,

    // for "controlled component"
    value?: string,

    // overrides
    InputLabelProps?: Object,
    FormControlProps?: Object,

    // optional, no useful default
    onChange?: (value: string) => any,
    onUnset?: () => any,
  |},
  {| controlledValue: string |}
> {
  static defaultProps = { variant: 'nullable', nullLabel: '' };
  static NoneValue = '_';

  onChange: *;

  constructor(props: React.ElementProps<typeof TextSelect>) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = { controlledValue: props.initialValue || '' };
  }

  isControlled() {
    return this.props.value !== undefined;
  }

  onChange(e: any) {
    const value = e.target.value;
    if (value === TextSelect.NoneValue) {
      if (!this.isControlled()) {
        this.setState({ controlledValue: '' });
      }
      const { onUnset } = this.props;
      if (onUnset) {
        onUnset();
      }
      return;
    }

    if (!this.isControlled()) {
      this.setState({ controlledValue: value });
    }
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  }

  render() {
    const { formId } = this.props;
    const { inputLabel, FormControlProps, InputLabelProps } = this.props;
    const options = [];
    if (this.props.variant === 'nullable') {
      options.push({
        value: TextSelect.NoneValue,
        label: <div>{this.props.nullLabel}</div>,
      });
    }
    options.push(...this.props.options);

    return (
      <SelectControl
        label={inputLabel}
        value={this.props.value || this.state.controlledValue}
        onChange={this.onChange}
        inputProps={{
          id: formId,
        }}
        options={options}
        formControlProps={FormControlProps}
        inputLabelProps={InputLabelProps}
      />
    );
  }
}

export class TextMultipleSelect extends React.Component<
  {|
    formId: string,
    options: Array<{| value: string, label: string |}>,

    //
    // defaults or optional
    //
    variant: 'nonnull' | 'nullable',
    nullLabel: React.Node,

    inputLabel?: string,
    initialValue?: Array<string>,

    // for "controlled component"
    value?: Array<string>,

    // overrides
    InputLabelProps?: Object,
    FormControlProps?: Object,

    // optional, no useful default
    onChange?: (value: Array<string>) => void,
    onUnset?: () => void,
    onClose?: Function,
    onOpen?: Function,
  |},
  {| controlledValue: Array<string>, open: boolean |}
> {
  static defaultProps = { variant: 'nullable', nullLabel: '' };
  static NoneValue = '_';

  onChange: *;

  constructor(props: React.ElementProps<typeof TextMultipleSelect>) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = { controlledValue: props.initialValue || [], open: false };
  }

  isControlled() {
    return this.props.value !== undefined;
  }

  onChange(e: any) {
    const values = e.target.value;
    if (values.includes(TextSelect.NoneValue)) {
      if (!this.isControlled()) {
        this.setState({ controlledValue: [], open: false });
      } else {
        this.setState({ open: false });
      }
      const { onUnset } = this.props;
      if (onUnset) {
        onUnset();
      }
      return;
    }

    if (!this.isControlled()) {
      this.setState({ controlledValue: values });
    }
    const { onChange } = this.props;
    if (onChange) {
      onChange(values);
    }
  }

  render() {
    const { formId, value = [] } = this.props;
    const { inputLabel, FormControlProps, InputLabelProps } = this.props;
    const options = [];
    if (this.props.variant === 'nullable') {
      options.push({
        value: TextSelect.NoneValue,
        label: <div>{this.props.nullLabel}</div>,
      });
    }
    this.props.options.forEach((o, idx) => {
      const checked =
        (this.isControlled() && this.state.controlledValue.includes(o.value)) ||
        value.includes(o.value);
      const Item = (props) => (
        <div {...props}>
          <Checkbox style={{ paddingLeft: 0 }} checked={checked} color="primary" />
          {o.label}
        </div>
      );
      options.push({
        value: o.value,
        label: o.label,
        component: Item,
      });
    });

    const { onClose, onOpen } = this.props;

    return (
      <SelectMultipleControl
        label={inputLabel}
        value={value || this.state.controlledValue}
        onChange={this.onChange}
        inputProps={{
          id: formId,
        }}
        options={options}
        formControlProps={FormControlProps}
        inputLabelProps={InputLabelProps}
        selectProps={{
          open: this.state.open,
          onOpen: () => {
            if (onOpen) {
              onOpen();
            }
            this.setState({ open: true });
          },
          onClose: () => {
            if (onClose) {
              onClose();
            }
            this.setState({ open: false });
          },
        }}
      />
    );
  }
}
