// @flow

import * as materialUI from '@whys/app-plugin-material-ui-4';
import * as emotion from '@whys/app-plugin-emotion-10';
import * as gtm from '../AppRoot/gtm';

const plugins = {
  materialUI: materialUI.setup({ theme: {} }),
  emotion: emotion.setup(),
  gtm: gtm.setup({
    resolvePluginContext: (appPluginCtx) => {
      if (!appPluginCtx || !appPluginCtx.gtm) return null;
      const gtmId = appPluginCtx.gtm.gtmId || '';
      return {
        gtmId,
      };
    },
  }),
};

export default plugins;
