// @flow

import * as React from 'react';
import { Block, FlexColumn, Grid } from '../baseUI';
import { ProductTitleLink } from './product';
import { FormatPrice } from '../appUI';
import { AddToCartLine } from '../cart/addToCart';
import { BlockLink, SystemPageLink } from '../app/link';
import { DeleteButton } from '../materialUI/buttons';
import { useLingui } from '../app/state';
import type { CartItem } from '../whyshop/models/cart';
import { Trans } from '@lingui/react';
import { getImagePlaceholder } from './getImagePlaceholder';

type LocalProps = {|
  onToggleModal: (boolean) => void,
  cartItems: Array<CartItem>,
  onRemove: (CartItem) => any,
  onQuantityChange: (CartItem, number) => Promise<boolean>,
|};

function CartPopup(props: LocalProps) {
  const i18n = useLingui();
  const imgPlaceholder = getImagePlaceholder();

  const renderResults = () => {
    const { cartItems } = props;
    return cartItems.map((item) => {
      const product = item.productItem;

      const imageSrc = (() => {
        const firstImg = product.images[0];
        if (firstImg) {
          return firstImg.thumbnail || imgPlaceholder;
        }
        return imgPlaceholder;
      })();
      return (
        <React.Fragment key={product.id}>
          <Block maxWidth={100} height={100}>
            {product ? (
              <BlockLink to={product.productUrl}>
                <img height={100} src={imageSrc} alt={product.code} />
              </BlockLink>
            ) : (
              <img height={100} src={imageSrc} alt="" />
            )}
          </Block>

          <FlexColumn justifyContent="center">
            <ProductTitleLink title={product.code} productUrl={product ? product.productUrl : ''} />

            <Block marginTop={5}>
              <FormatPrice value={item.prices.discountNet} />
            </Block>
          </FlexColumn>

          <FlexColumn justifyContent="center">
            <AddToCartLine
              product={product}
              quantity={item.quantity}
              onQuantityChange={(val) => {
                return props.onQuantityChange(item, val);
              }}
              showCartButton={false}
              onToggleModal={(val) => setTimeout(() => props.onToggleModal(val), 200)}
            />
          </FlexColumn>

          <FlexColumn justifyContent="center" alignItems="flex-end">
            <FormatPrice value={item.prices.totalDiscountNet} />
          </FlexColumn>
          <FlexColumn width="fit-content" justifyContent="center">
            <DeleteButton
              onClick={() => {
                props.onRemove(item);
              }}
              color={'primary'}
              code={i18n.t`Remove from cart`}
            />
          </FlexColumn>
        </React.Fragment>
      );
    });
  };

  return (
    <FlexColumn alignItems="flex-end" paddingBottom="20px">
      <Grid width="100%" gridTemplateColumns="1fr 2fr 2fr 1fr 1fr" gridGap="1em 2em">
        {renderResults()}
      </Grid>
      <SystemPageLink name="cart">
        <Trans>Go to cart</Trans>
      </SystemPageLink>
    </FlexColumn>
  );
}

export default CartPopup;
