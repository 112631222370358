// @flow

import { useMemo } from 'react';

import { useContainer } from '@whys/app/lib/state';

import type { AppCacheType, CacheType, DjReactStateType, FetchEnvType } from '../types/app';

import { GlobalContainer } from '../app/state';

export function useGlobalContainer(): GlobalContainer {
  return useContainer(GlobalContainer);
}

export function useFetchEnv(): FetchEnvType {
  const globalContainer = useContainer(GlobalContainer);
  return globalContainer.props.fetchEnv;
}

// better name: useMemoCache (memory)
// other APIs: usePersistCache (localstorage or others)

export function useCache(cacheName: string): CacheType<any> {
  const globalContainer = useContainer(GlobalContainer);
  return globalContainer.selectCache(cacheName);
}

export function useAppCache(): AppCacheType {
  const globalContainer = useContainer(GlobalContainer);
  return globalContainer.props.appCache;
}

export function useAppContainer() {
  const globalContainer = useContainer(GlobalContainer);
  return globalContainer.props.appContainer;
}

export function useDjReactState(): DjReactStateType {
  const globalContainer = useContainer(GlobalContainer);
  return globalContainer.props.djreactState;
}

export function useIsBrowser() {
  return useMemo(() => {
    return typeof window !== 'undefined';
  }, []);
}

export function useEnvironment(): 'production' | 'development' | 'test' {
  const djreactState = useDjReactState();
  return djreactState.env;
}
