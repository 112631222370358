// @flow

import { Container } from '@whys/app/lib/state';

import type { PageNumberSuperCache } from '@whys/fetch/lib/pagination/PageNumberSuperCache';
import { create as createPageNumberSuperCache } from '@whys/fetch/lib/pagination/PageNumberSuperCache';

import type { FetchEnvType, AppCacheType } from './types';

import type { ProductItem } from './models/product';
import { resources, mapVariantToProductItem } from './models/product';
import { arrayObjectToQuery } from './utils/querystring';
import { getJSON } from '@whys/fetch/lib/json';
import { PaginatedProductsCount } from '../app/config';
import { localStorageAPI } from '../jsutils/localStorage';
import { ALL_PRODUCTS } from '../RudorferApp/CategoryPage';
//import { products } from '../exampleHelpers/factories';

// import { getNextUrl } from './requests';

// type SeqPaginatedResource<T> = {|
//   items: Array<T>,
//   hasMore: boolean,
//   loadMore: () => Promise<void>,
// |};

// function coerceInt(val, defaultVal) {
//   const asInt = parseInt(val, 10);
//   if (Number.isNaN(asInt)) {
//     return defaultVal;
//   }
//   return asInt;
// }

export type SortOptionType = {|
  name: string,
  sort: string,
  param: string,
  order: string,
  default: boolean,
  auth_needed: boolean,
|};

type CategoryResourceOptions = {|
  categoryId: string,
  attributeIds: Array<string>,
  filterNames: { [string]: Array<string> },
  namedFilters: { [string]: string },
|};

type LocalState = {|
  itemsCount: number,
  sortOptionType: string,
|};

type LocalProps<TParamsPayload, TParams> = {|
  fetchEnv: FetchEnvType,
  appCache: AppCacheType,

  mapParams: (?TParamsPayload) => TParams,
  mapLabels: ($FlowFixMe) => $FlowFixMe,
  mapLabelsTest: ($FlowFixMe) => $FlowFixMe,
|};

export class CategoryProductsContainer<TParamsPayload, TParams> extends Container<LocalState> {
  state: LocalState;
  props: LocalProps<TParamsPayload, TParams>;

  categoriesCache: PageNumberSuperCache<ProductItem>;

  constructor(props: LocalProps<TParamsPayload, TParams>) {
    super();
    const { fetchEnv, appCache, mapParams, mapLabels, mapLabelsTest } = props;
    this.state = {
      itemsCount: localStorageAPI.getValue('itemsCount') || PaginatedProductsCount,
      sortOptionType: localStorageAPI.getValue('sortOptionType') || '',
    };

    this.categoriesCache = createPageNumberSuperCache({
      cachePrefix: 'CategoryProductsContainer',
      appCache,
      fetchEnv,
      getUrl: ({ initialUrl, page }) => {
        return initialUrl.includes('?')
          ? `${initialUrl}&page=${page}`
          : `${initialUrl}?page=${page}`;
      },
      getCounts: ({ data, response }) => {
        // X-Total-Count: 10
        // X-Total-Pages: 1
        return {
          totalItems: Number(response.headers.get('X-Total-Count')) || 0,
          itemsPerPage: -1,
          totalPages: Number(response.headers.get('X-Total-Pages')) || 1,
        };
      },
      mapData: (data) => mapVariantToProductItem(data, { mapParams, mapLabels, mapLabelsTest }),
    });

    this.props = props;
  }

  _resolveUrl(resource: CategoryResourceOptions) {
    const namedFilters = (resource && resource.namedFilters) || {};
    //const attributeIds = (resource && resource.attributeIds) || [];
    const filterNames = (resource && resource.filterNames) || [];

    // delete utm parameters
    Object.keys(namedFilters).forEach((key) => {
      if (key.startsWith('utm_')) {
        delete namedFilters[key];
      }
    });

    const query = arrayObjectToQuery({
      filterNames,
      ...namedFilters,
      items_count:
        this.state.itemsCount === ALL_PRODUCTS ? PaginatedProductsCount : this.state.itemsCount,
    });
    const initialUrl = resources.listProductsInCategory(resource.categoryId).url;
    const finalUrl = query ? `${initialUrl}?${query}` : initialUrl;
    return finalUrl;
  }

  async getProducts(
    resource: CategoryResourceOptions,
    pagination: {| page: number |}
  ): Promise<ProductItem[]> {
    const initialUrl = this._resolveUrl(resource);
    const productsCache = this.categoriesCache.getCacheByUrl(initialUrl);
    return await productsCache.fetchPage(pagination.page);
  }

  selectCounts(
    resource: CategoryResourceOptions
  ): {| totalItems: number, itemsCount: number, totalPages: number |} {
    const initialUrl = this._resolveUrl(resource);
    const productsCache = this.categoriesCache.getCacheByUrl(initialUrl);
    return {
      totalItems: productsCache.selectCount('totalItems'),
      itemsCount: this.state.itemsCount,
      totalPages: productsCache.selectCount('totalPages'),
    };
  }

  hasPage(resource: CategoryResourceOptions, opts: {| page: number |}): boolean {
    const initialUrl = this._resolveUrl(resource);
    const productsCache = this.categoriesCache.getCacheByUrl(initialUrl);
    return productsCache.hasPage(opts.page);
  }

  async prefetchPage(resource: CategoryResourceOptions, page: number): Promise<void> {
    const initialUrl = this._resolveUrl(resource);
    const productsCache = this.categoriesCache.getCacheByUrl(initialUrl);
    return productsCache.prefetchPage(page);
  }

  async setItemsCount(itemsCount: number) {
    await this.setState({ itemsCount });
    localStorageAPI.setValue('itemsCount', itemsCount);
  }

  async setSortOptionType(sortOptionType: string) {
    await this.setState({ sortOptionType });
    localStorageAPI.setValue('sortOptionType', sortOptionType);
  }

  selectItemsCount() {
    return this.state.itemsCount;
  }

  selectSortOptionType() {
    return this.state.sortOptionType;
  }

  async loadSortOptions(): Promise<Array<SortOptionType>> {
    const result = await getJSON(resources.sortOptions.url, this.props.fetchEnv);
    if (result.status === 'ok') {
      return result.data;
    }
    return [];
  }
}

export type { CategoryProductsContainer as CategoryProductsContainerType };
