// @flow

import idx from 'idx';

// framework
import { Container } from '@whys/app/lib/state';
import { fetchJSON } from '@whys/fetch/lib/json';
import { create as createCursorCache } from '@whys/fetch/lib/pagination/CursorCache';
import type { CursorCache } from '@whys/fetch/lib/pagination/CursorCache';

// whyshop
import type { ProductItem, ProductShape } from '../whyshop/models/product';
import { getNextUrl } from '../whyshop/requests';

// app
import type { FetchEnvType, AppCacheType } from '../types/app';

async function fetchJSONAsBool(props): Promise<boolean> {
  const result = await fetchJSON(props);
  return result.status === 'ok';
}

const resources = {
  favoriteProduct: (id: string) => ({
    url: `/api/product/variant/${id}/favorite/`,
    method: 'POST',
  }),
  unfavoriteProduct: (id: string) => ({
    url: `/api/product/variant/${id}/favorite/`,
    method: 'DELETE',
  }),
  unfavoriteAll: {
    url: '/api/product/variant/favorites/',
    method: 'DELETE',
  },
  listFavoriteProducts: {
    // Note: for some reason without s in favorites it didn't work
    url: '/api/product/variant/favorites/',
  },
};

type LocalProps = {|
  fetchEnv: FetchEnvType,
  appCache: AppCacheType,

  mapPayload: (any) => ProductItem,

  initialState?: {|
    favoritesCount?: number,
  |},
|};

type LocalState = {|
  tmpFavorites: Array<ProductItem>,
  favoritesCount: number,
|};

// NotePrototype(simon): lot of impl details, should be in whyshop package

export class FavoritesContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  // see ImplFavoritedProductsTmpCache why __tmp
  __tmpFaved: Set<string> = new Set();
  __tmpUnfaved: Set<string> = new Set();

  favoriteProductList: CursorCache<ProductItem>;

  constructor(props: LocalProps) {
    super();

    const { fetchEnv, appCache, mapPayload } = props;

    this.favoriteProductList = createCursorCache({
      fetchEnv,
      // ImplFavoritesNotServerRendered: createCache instead of getOrCreateCache
      cache: appCache.createCache(),
      initialUrl: resources.listFavoriteProducts.url,
      getNextUrl: ({ response }) => {
        return getNextUrl(response);
      },
      mapData: mapPayload,
    });

    this.state = {
      tmpFavorites: [],
      favoritesCount: idx(props, (_) => _.initialState.favoritesCount) || 0,
    };
    this.props = props;
  }

  async alterIsFavorite(product: ProductShape, isFavorite: boolean): Promise<boolean> {
    const { fetchEnv } = this.props;
    const { favoritesCount } = this.state;
    const { id } = product;

    if (isFavorite) {
      this.__tmpFaved.add(id);
      this.__tmpUnfaved.delete(id);
      const result = fetchJSONAsBool({ ...resources.favoriteProduct(id), env: fetchEnv });
      // NoteReview(simon): we only need to reload favorites on "favorites" page, so its not necessary to
      // re-fetch?
      // On the other hand this container contains some data that are mutated and should be responsible
      // to have "fresh data".
      // Maybe add optimistic update (delete in the list)?
      // this.reloadFavoriteProducts();
      this.setState({ favoritesCount: favoritesCount + 1 });
      return result;
    } else {
      this.__tmpFaved.delete(id);
      this.__tmpUnfaved.add(id);
      const result = fetchJSONAsBool({ ...resources.unfavoriteProduct(id), env: fetchEnv });
      // this.reloadFavoriteProducts();
      this.setState({ favoritesCount: favoritesCount - 1 });
      return result;
    }
  }

  async unfavoriteAll(): Promise<boolean> {
    const { fetchEnv } = this.props;
    const result = await fetchJSONAsBool({ ...resources.unfavoriteAll, env: fetchEnv });
    await this.reloadFavoriteProducts();
    this.__tmpFaved.clear();
    this.__tmpUnfaved.clear();
    return result;
  }

  isFavorite(product: ProductShape): boolean {
    const tmpValue = (() => {
      const { id } = product;
      if (this.__tmpFaved.has(id)) {
        return true;
      }
      if (this.__tmpUnfaved.has(id)) {
        return false;
      }

      return null;
    })();

    if (typeof tmpValue === 'boolean') {
      return tmpValue;
    }

    return product.isFav;
  }

  selectFavoriteProducts(): Array<ProductItem> {
    return this.state.tmpFavorites;
  }

  selectFavoritesCount(): number {
    return this.state.favoritesCount;
  }

  async getFavoriteProducts(): Promise<ProductItem[]> {
    const items = await this.favoriteProductList.getAllItems();
    return items;
  }

  async loadFavoriteProducts(): Promise<boolean> {
    const items = await this.getFavoriteProducts();
    this.setState({ tmpFavorites: items });
    return true;
  }

  async reloadFavoriteProducts(): Promise<boolean> {
    this.favoriteProductList.clearAllItems();
    const items = await this.favoriteProductList.getAllItems();
    this.setState({ tmpFavorites: items, favoritesCount: items.length });
    return true;
  }
}
