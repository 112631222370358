// @flow

import styled from '@emotion/styled';
import React from 'react';
import { styledTheme } from '../app/theme';
import { BlockLink, ThemedText, FormatPriceBold, FormatPrice, HorizontalSpace } from '../appUI';
import { TextLink } from '../app/link';
import { Trans } from '@lingui/react';
import { getAvailabilityInfo } from '../cart/addToCart';
import type { SaleTypeEnum, ProductParams } from '../appState/types';
import { LazyImage } from '../reactUtils/LazyImage';
import { Block } from '../baseUI';
import { getImagePlaceholder } from './getImagePlaceholder';

// loading="lazy"
export const ImageLink = ({
  to,
  title,
  src,
  height = 'auto',
}: {|
  to: string,
  title: string,
  src: string,
  height?: number | string,
|}) => {
  const imgPlaceholder = getImagePlaceholder();
  return (
    <BlockLink to={to} title={title}>
      <LazyImage height={height} src={src} alt={title} fallbackSrc={imgPlaceholder} />
    </BlockLink>
  );
};

export const ProductTitle = styled.div`
  color: ${styledTheme.color('primary')};
  font-size: 20px;
  font-weight: bold;

  &:hover {
    color: ${styledTheme.color('secondary')};
  }
`;

const Price = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: center;
  color: ${(props) =>
    props.isAlfabox ? styledTheme.color('secondary') : styledTheme.color('errorText')};
  font-weight: bold;
`;

const OriginalPrice = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: center;
  color: ${styledTheme.color('neutralText')};
  text-decoration: line-through;
`;

export const ProductPrice = ({
  params,
  price,
  originalPrice,
  isAlfabox,
}: {|
  params: ProductParams,
  price: number,
  originalPrice: number,
  isAlfabox?: boolean,
|}) => (
  <React.Fragment>
    <Price isAlfabox={isAlfabox}>
      <FormatPriceBold value={price} />
      &nbsp;/ {params.measureUnit.name}
    </Price>
    <HorizontalSpace />
    {price !== originalPrice && (
      <OriginalPrice>
        <FormatPrice value={originalPrice} />
        &nbsp;/ {params.measureUnit.name}
      </OriginalPrice>
    )}
  </React.Fragment>
);

export const ProductTitleLink = ({
  productUrl,
  title,
}: {|
  productUrl: string,
  title: string,
|}) => {
  const titleEl = <ProductTitle>{title}</ProductTitle>;
  // productUrl can temporarily empty (search popup) => don't make it clickable
  return productUrl ? (
    <TextLink to={productUrl} style={{ textDecoration: 'none' }} data-cy="product-title">
      {titleEl}
    </TextLink>
  ) : (
    titleEl
  );
};

export const ProductSaleInfo = ({
  type,
  availableNum,
  size = 'bigger',
  textAlign = 'initial',
}: {|
  type: SaleTypeEnum,
  availableNum: number,
  size?: 'smaller' | 'bigger',
  textAlign?: string,
|}) => {
  let info;
  if (type === 'piece') {
    info = <Trans>Sale by pieces.</Trans>;
  } else if (type === 'package') {
    info = <Trans>Sale by packages.</Trans>;
  } else {
    info = <Trans>Sale by certain amount.</Trans>;
  }
  const availability = getAvailabilityInfo(availableNum);

  return availability.isAvailable ? (
    <ThemedText width="fit-content" size={size} textAlign={textAlign}>
      {info}
    </ThemedText>
  ) : null;
};
