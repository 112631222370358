// @flow

// import idx from 'idx';

import Cookies from 'js-cookie';
// framework
import { Container } from '@whys/app/lib/state';
import { create as createResourceCache } from '@whys/fetch/lib/resources/ResourceCache';
import type { ResourceCache } from '@whys/fetch';
// app
import type { FetchEnvType, AppCacheType, DjReactStateType, AppContainerType } from '../types/app';
import type {
  InitialState,
  StaticPageNames,
  SystemPageNames,
  UserPermissions,
  NotificationType,
  Notification,
  ProjectID,
  OrderInfo,
  SoftwareModule,
} from '../appState/types';
import type { I18nType } from '../types/npm';
import { getJSON } from '@whys/fetch/lib/json';
import type { FooterBlock } from '../types/api';

export type SiteNamesEnum =
  | 'rudorfer.cz'
  | 'rudorfer.pl'
  | 'rudorfer.sk'
  | 'rudorfer.de'
  | 'rudorfer.at'
  | 'rudorfer.lu'
  | 'rudorfer.be'
  | 'rudorfer.nl'
  | 'rudorfer.hu'
  | 'rudorfer.fr'
  | 'rudorfer.dealer'
  | 'rudorfer.eu'
  | 'alfabox.cz'
  | 'alfabox.pl'
  | 'alfabox.sk'
  | 'alfabox.de'
  | 'alfabox.at'
  | 'alfabox.lu'
  | 'alfabox.be'
  | 'alfabox.hu'
  | 'rudorfer.co.at'
  | 'rudorfer.com.de'
  | 'rudorferpack.eu'
  | 'rudorferpack.com';

type RuleNameEnum = $Keys<$PropertyType<InitialState, 'rules'>>;
export type PermissionsEnum = $Keys<UserPermissions>;

type MemoResource<TResource> = {|
  getResource: () => TResource,
|};

// other names: local | synchronous | memoized
function createMemoResource<TData, TResource>(opts: {|
  data: TData,
  mapData: (TData) => TResource,
|}): MemoResource<TResource> {
  let __cache = null;
  function getResource() {
    if (__cache !== null) {
      return __cache;
    }
    const res = opts.mapData(opts.data);
    __cache = res;
    return res;
  }
  return {
    getResource,
  };
}

const resources = {
  systemPages: {
    url: '/api/content/systempage/urls/',
  },
  staticPages: {
    url: '/api/content/staticpage/urls/',
  },
  notifications: {
    url: '/api/utils/notification-data/',
  },
};

type StaticPageUrls = { [StaticPageNames]: {| url: string, name: string |} };
type SystemPageUrls = { [SystemPageNames]: {| url: string, name: string |} };

type FooterInfo = {|
  footerBlocks: Array<FooterBlock>,
  contactBlock: {|
    contactTitle: string,
    contactLines: string[],
    namedContacts: {|
      workingHours: string,
      email: string,
      phoneNumbers: string[],
      fax: string,
      mobileNumber: string,
    |},
  |},
|};

type HeaderInfo = {|
  email: string,
  phoneNumber: string,
  watchedCount: number,
  favoritesCount: number,
|};

const mapInitialDataToFooterInfo = (i18n) => (data: InitialState): FooterInfo => {
  const { operator, footerData } = data;
  return {
    footerBlocks: footerData,
    contactBlock: {
      contactTitle: operator.name,
      contactLines: [
        // 'U podjezdu 523, 400 07 Ústí nad Labem',
        operator.address_street && operator.address_zipcode && operator.address_city
          ? `${operator.address_street}, ${operator.address_zipcode} ${operator.address_city}`
          : null,
        // 'IČ: 04472535',
        operator.identification_number
          ? `${i18n.t`identification num.`}: ${operator.identification_number}`
          : null,
        // 'DIČ: CZ04472535',
        operator.vat_number ? `${i18n.t`VAT num.`}: ${operator.vat_number}` : null,
        // ???
        operator.international_vat_id
          ? `${i18n.t`Intern. VAT num.`}: ${operator.international_vat_id}`
          : null,
        // 'Bankovní spojení:',
        // '115-1075320257/0100 KB',
        operator.bank_account_number
          ? `${i18n.t`Bank account number`}: ${operator.bank_account_number}`
          : null,
        // Ústí nad Labem
        operator.bank_name ? `${i18n.t`Name of the bank`}: ${operator.bank_name}` : null,

        operator.bank_iban ? `${i18n.t`IBAN`}: ${operator.bank_iban}` : null,
        operator.bank_swift ? `${i18n.t`SWIFT`}: ${operator.bank_swift}` : null,
      ].filter(Boolean),
      namedContacts: {
        workingHours: operator.opening_hours,
        email: operator.email,
        phoneNumbers: [operator.primary_phone_number, operator.alternative_phone_numbers[0]].filter(
          Boolean
        ),
        mobileNumber: operator.mobile_number,
        fax: operator.fax_number,
      },
    },
  };
};

const getCountryName = (countryCode: string, i18n: any) => {
  switch (countryCode) {
    case 'CZ':
      return i18n.t`Czech republic`;
    case 'PL':
      return i18n.t`Poland`;
    case 'HU':
      return i18n.t`Hungary`;
    case 'SK':
      return i18n.t`Slovakia`;
    case 'AT':
      return i18n.t`Austria`;
    case 'DE':
      return i18n.t`Germany`;
    case 'FR':
      return i18n.t`France`;
    default:
      return '';
  }
};

const mapInitialDataToFooterInfoAlfabox = (i18n) => (data: InitialState): FooterInfo => {
  const { operator, footerData } = data;
  return {
    footerBlocks: footerData,
    contactBlock: {
      contactTitle: operator.name,
      contactLines: [
        // 'U podjezdu 523, 400 07 Ústí nad Labem',
        operator.address_street && operator.address_zipcode && operator.address_city
          ? `${operator.address_street}, ${operator.address_zipcode} ${operator.address_city}`
          : null,
        operator.country ? `${getCountryName(operator.country, i18n)}` : null,
        ' ', // empty spaces for separation
        ' ', // empty spaces for separation
        // 'IČ: 04472535',
        operator.identification_number
          ? `${i18n.t`identification num.`}: ${operator.identification_number}`
          : null,
        // 'DIČ: CZ04472535',
        operator.vat_number ? `${i18n.t`VAT num.`}: ${operator.vat_number}` : null,
        // ???
        operator.international_vat_id
          ? `${i18n.t`Intern. VAT num.`}: ${operator.international_vat_id}`
          : null,
        // 'Bankovní spojení:',
        // '115-1075320257/0100 KB Ústí nad Labem',
        ...(operator.bank_account_number && operator.bank_name
          ? [
              ' ', // empty spaces for separation
              ' ', // empty spaces for separation
              `${i18n.t`Bank account number`}: `,
              `${operator.bank_name}`,
              `${operator.bank_account_number}`,
            ]
          : []),
      ].filter(Boolean),
      namedContacts: {
        workingHours: operator.opening_hours,
        email: operator.email,
        phoneNumbers: [operator.primary_phone_number, operator.alternative_phone_numbers[0]].filter(
          Boolean
        ),
        mobileNumber: operator.mobile_number,
        fax: operator.fax_number,
      },
    },
  };
};

const mapInitialDataToHeaderInfo = ({
  operator,
  favorites,
  watched,
}: InitialState): HeaderInfo => ({
  email: operator.email,
  phoneNumber: operator.primary_phone_number,
  watchedCount: watched,
  favoritesCount: favorites,
});

type LocalProps = {|
  fetchEnv: FetchEnvType,
  i18n: I18nType,
  appCache: AppCacheType,
  initialState: InitialState,
  djreactState: DjReactStateType,
  appContainer: AppContainerType,
|};

type LocalState = {|
  // NotePrototype(simon): prototype UI code only (but to keep the value between 2 header components)
  showNotification: {|
    notification_cookies: boolean,
    notification_coupon: boolean,
    notification_newsletter: boolean,
    notification_address: boolean,
  |},
  notifications: Array<Notification>,
  order: OrderInfo,
  didPopupShow: boolean,
  showCookiesPopup: boolean,
|};

const mapFooterInfo = (i18n, initState) => {
  const isAlfabox = initState?.site?.projectId === 'alfabox';
  return isAlfabox ? mapInitialDataToFooterInfoAlfabox(i18n) : mapInitialDataToFooterInfo(i18n);
};

/**

This container is a generic container which contains global information used on every page.

*/

export class GlobalContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  staticPagesCache: ResourceCache<StaticPageUrls>;
  systemPagesCache: ResourceCache<SystemPageUrls>;
  footerInfoCache: MemoResource<FooterInfo>;
  headerInfoCache: MemoResource<HeaderInfo>;

  constructor(props: LocalProps) {
    super();

    const { fetchEnv, appCache, i18n } = props;

    const { initialState } = props;
    this.footerInfoCache = createMemoResource<InitialState, FooterInfo>({
      mapData: mapFooterInfo(i18n, initialState),
      data: initialState,
    });

    this.headerInfoCache = createMemoResource<InitialState, HeaderInfo>({
      mapData: mapInitialDataToHeaderInfo,
      data: initialState,
    });

    this.systemPagesCache = createResourceCache({
      fetchEnv,
      cache: appCache.getOrCreateCache('GlobalContainer.systemPages'),
      initialValue: initialState.systemPages,
      url: resources.systemPages.url,
      mapData: (data) => data,
      getEmptyResource: () => ({}),
    });

    this.staticPagesCache = createResourceCache({
      fetchEnv,
      cache: appCache.getOrCreateCache('GlobalContainer.staticPages'),
      initialValue: initialState.staticPages,
      url: resources.staticPages.url,
      mapData: (data) => data,
      getEmptyResource: () => ({}),
    });

    this.props = props;
    this.state = {
      showNotification: {
        notification_address: false,
        notification_cookies: false,
        notification_coupon: false,
        notification_newsletter: false,
      },
      notifications: [],
      order: initialState.order,
      didPopupShow: false,
      showCookiesPopup: false,
    };
  }

  //
  // Static pages
  //

  async fetchStaticPageUrls(): Promise<StaticPageUrls> {
    return await this.staticPagesCache.getResource();
  }

  async loadStaticPageUrls(): Promise<void> {
    await this.fetchStaticPageUrls();
  }

  selectStaticPageInfo(pageName: StaticPageNames): {| name: string, url: string |} {
    const staticPageInfos = this.staticPagesCache.getResourceOrEmpty();
    const pageInfo = staticPageInfos[pageName];
    if (pageInfo) {
      return pageInfo;
    }
    return { name: '-', url: '' };
  }

  selectStaticPageUrl(pageName: StaticPageNames): string {
    return this.selectStaticPageInfo(pageName).url;
  }

  //
  // System pages
  //

  async fetchSystemPageUrls(): Promise<SystemPageUrls> {
    return await this.systemPagesCache.getResource();
  }

  async loadSystemPageUrls(): Promise<void> {
    await this.fetchSystemPageUrls();
  }

  selectSystemPageInfo(pageName: SystemPageNames): {| name: string, url: string |} {
    const infos = this.systemPagesCache.getResourceOrEmpty();
    const pageInfo = infos[pageName];
    if (pageInfo) {
      return pageInfo;
    }
    return { name: '-', url: '' };
  }

  selectSystemPageUrl(pageName: SystemPageNames): string {
    // simple helper because system pages needs only urls
    return this.selectSystemPageInfo(pageName).url;
  }

  selectHomeUrl(): string {
    return this.selectSystemPageUrl('homepage') || '/';
  }

  isUrlForSystemPage(url: string, pageName: SystemPageNames): boolean {
    const infos = this.systemPagesCache.getResourceOrEmpty();
    const page = infos[pageName];
    if (page) {
      return page.url === url;
    }
    return false;
  }

  //
  // footer info
  //

  selectFooterInfo(): FooterInfo {
    return this.footerInfoCache.getResource();
  }

  selectHeaderInfo(): HeaderInfo {
    return this.headerInfoCache.getResource();
  }

  selectOrderInfo(): OrderInfo {
    return this.state.order;
  }

  //
  // Cookie consent
  //

  // Note(cookies_confirmed): privacy policy mentions this key, keep it
  cookieConfirmKey = 'cookies_confirmed';
  cookieConfirmValue = '1';

  acceptCookies() {
    Cookies.set(this.cookieConfirmKey, this.cookieConfirmValue, { expires: 365 });
  }

  acceptCookiesPopup() {
    Cookies.set(this.cookieConfirmKey, this.cookieConfirmValue, { expires: 365 });
  }

  storeCookiesConsent(consent: any) {
    Cookies.set('cookie_consent_essential', 'true');
    Cookies.set('cookie_consent_analytics', `${consent.cookie_consent_analytics}`);
    Cookies.set('cookie_consent_remarketing', `${consent.cookie_consent_remarketing}`);
  }

  getCookiesConsent() {
    return {
      cookie_consent_essential: true,
      cookie_consent_analytics: Cookies.get('cookie_consent_analytics'),
      cookie_consent_remarketing: Cookies.get('cookie_consent_remarketing'),
    };
  }

  showCookiesPopup(): boolean {
    return Cookies.get(this.cookieConfirmKey) === this.cookieConfirmValue;
  }

  selectCookiesAccepted(): boolean {
    return Cookies.get(this.cookieConfirmKey) === this.cookieConfirmValue;
  }

  //
  // Site info
  //

  selectSiteInfo(): {| name: string, domain: string |} {
    const { site } = this.props.initialState;
    return {
      name: site.name,
      domain: site.domain,
    };
  }

  selectOperator() {
    const { operator } = this.props.initialState;
    return operator;
  }

  selectAddressProvider() {
    const { addressProvider } = this.props.initialState;
    return addressProvider;
  }

  selectCompanyAddress() {
    const { companyAddress } = this.props.initialState;
    return companyAddress;
  }

  selectCountryCode(): string {
    const { operator } = this.props.initialState;
    if (operator) return operator.country || '';
    return '';
  }

  selectSiteNameId(): string {
    const { site } = this.props.initialState;
    return site.name;
  }

  getCompanyName(): string {
    const { operator } = this.props.initialState;
    if (operator) return operator.name || '';
    return '';
  }

  selectPriceInfo(): $PropertyType<InitialState, 'price'> {
    return this.props.initialState.price;
  }

  showVatField(): boolean {
    // Note: disabled on RUD-CZ, RUD-AT, RUD-DE
    const show = !(
      this.isSpecificSite('rudorfer.cz') ||
      this.isSpecificSite('rudorfer.at') ||
      this.isSpecificSite('rudorfer.de')
    );
    return show;
  }

  selectDefaultPageTitle(): string {
    const { site } = this.props.initialState;
    return site.defaultPageTitle;
  }

  isRuleActive(ruleName: RuleNameEnum): boolean {
    const { rules } = this.props.initialState;
    return rules[ruleName];
  }

  isSpecificSite(siteName: SiteNamesEnum): boolean {
    // if (siteName === 'rudorfer.dealer') return true;
    const { site } = this.props.initialState;
    return site.name === siteName;
  }

  getSpecificSite(): string {
    const { site } = this.props.initialState;
    return site.name;
  }

  isSpecificProject(projectId: ProjectID): boolean {
    const { site } = this.props.initialState;
    // if (projectId === 'alfabox') return true;
    return site.projectId === projectId;
  }

  selectCache<TValue>(cacheName: string) {
    const { appCache } = this.props;
    return appCache.getOrCreateCache<TValue>(cacheName);
  }

  selectPermissions(): UserPermissions {
    const { permissions } = this.props.initialState;
    return permissions;
  }

  allowedTo(...perms: Array<PermissionsEnum>): boolean {
    const { permissions } = this.props.initialState;
    return perms.map((p) => permissions[p]).every((isAllowed) => isAllowed === true);
  }

  // Notifications
  async loadNotifications() {
    const { fetchEnv } = this.props;
    const result = await getJSON(resources.notifications.url, fetchEnv);

    if (result.status === 'ok') {
      this.setState({ notifications: result.data });
      return true;
    }
    return false;
  }

  showNotification(type: NotificationType): boolean {
    return this.state.showNotification[type];
  }

  setShowNotification(type: NotificationType, showNotification: boolean) {
    this.setState((prevState) => ({
      showNotification: { ...prevState.showNotification, [type]: showNotification },
    }));
  }

  selectNotifications(): Array<Notification> {
    return this.state.notifications;
  }

  getDidPopupShow(): boolean {
    return this.state.didPopupShow;
  }

  setDidPopupShow(did: boolean) {
    return this.setState({ didPopupShow: did });
  }

  getOpenCookiesPopup(): boolean {
    return this.state.showCookiesPopup;
  }

  setOpenCookiesPopup(show: boolean) {
    return this.setState({ showCookiesPopup: show });
  }

  isSoftwaremoduleActive(code: string): boolean {
    if (!this.props.initialState.softwaremodules) return false;
    return this.props.initialState.softwaremodules.some(
      (softwaremodule) => softwaremodule.code === code
    );
  }

  getSoftwaremodule(code: string): SoftwareModule | null {
    if (!this.props.initialState.softwaremodules) return null;
    return this.props.initialState.softwaremodules.find(
      (softwaremodule) => softwaremodule.code === code
    ) || null;
  }

  getSoftwaremodules(): Array<SoftwareModule> | null {
    return this.props.initialState.softwaremodules || [];
  }
}
