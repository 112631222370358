// @flow

import React from 'react';
// import idx from 'idx';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import ErrorIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';

import { Block } from '../baseUI';
import { smoothScrollTopToElement } from '../jsutils/browser';

const onFocus = ({ target }) => {
  // Handle if input is hidden within sticky header
  // Before form is submitted, its validated on browsers focuses invalid inputs (e.g. required or maxlength)
  const top = target.getBoundingClientRect().top;
  if (top < 150) {
    smoothScrollTopToElement(target);
  }
};

export function ValidatedTextField({
  helperText,
  showSuccess,
  showError,
  ...passProps
}: {
  showSuccess: boolean,
  showError: boolean,
  helperText: string,
  error: boolean,
}) {
  // Note: the space avoids content height jumping if helperText is missing
  const helperTextOrSpace = helperText || ' ';
  return (
    <TextField
      InputProps={{
        endAdornment:
          showError || showSuccess ? (
            <InputAdornment position="end">
              {(() => {
                if (showError) {
                  return <ErrorIcon color="error" />;
                }
                if (showSuccess) {
                  return (
                    <Block color="#6ac76a">
                      <CheckIcon />
                    </Block>
                  );
                }
                return <div />;
              })()}
            </InputAdornment>
          ) : (
            <div />
          ),
      }}
      margin="normal"
      onFocus={onFocus}
      helperText={helperTextOrSpace}
      InputLabelProps={{ shrink: true }}
      {...passProps}
    />
  );
}
