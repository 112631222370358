// @flow

// NoteReview(simon): the getAvailabilityInfo should be in this file
import { getAvailabilityInfo } from '../cart/addToCart';

import type { ProductItem } from '../appState/types';

export function isAvailable(product: ProductItem): boolean {
  return getAvailabilityInfo(product.availableNum).isAvailable;
}
